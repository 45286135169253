<template>
  <Spinner v-if="loading" :isLoading="loading" />
  <div v-else style="max-width:1000px; min-width: 650px; margin-top:1em" class="item-view container-fluid dark">
    <div class="row">
      <div class="col-lg-6">
        <div class="dark" style="width: 400px;">
          <div class="item-information-table">
            <tbody>
              <td>
                <div :class="`${checkExclusivity(item.item_id) ? 'exclusive' : ''} gf-tooltip`">
                  <div class="media mb-2 mt-2 pl-4 row">
                    <div class="col-1 item-icon2">
                      <div class="icon2 align-self-start">
                        <img style="width:32px"
                          :src="`https://gfmemories.com/images/itemicon/${item.ui_id_1?.toUpperCase()}.png`" alt="">
                      </div>
                    </div>
                    <div class="col-8 item-name2 media-body text-left  ml-2 mr-4">
                      <h6 :class="`quality-${item.item_quality}`">{{ item.item_name }} <span
                          v-if="item.rebirth_count === '1'">{{ $t('reencarnadoText') }}</span></h6>
                    </div>
                  </div>
                  <div class="item-general">
                    <div class="ride-combo">
                    </div>
                  </div>
                  <div class="media mb-2 mt-2 pl-4 row item-general">
                    <div class="stat" v-if="item.p_atk !== ''">
                      ATK <span>+{{ item.p_atk }}</span>
                    </div>
                    <div class="stat" v-if="item.r_atk !== ''">
                      R-ATK <span>+{{ item.r_atk }}</span>
                    </div>
                    <div class="stat" v-if="item.p_def !== ''">
                      DEF <span>+{{ item.p_def }}</span>
                    </div>
                    <div class="stat" v-if="item.m_def !== ''">
                      M-DEF <span>+{{ item.m_def }}</span>
                    </div>
                    <div class="stat" v-if="item.atk_spd !== ''">
                      SPD
                      <span>+{{ item.atk_spd }}</span>
                    </div>
                    <div class="stat" v-if="item.str_bonus !== ''">
                      STR
                      <span>+{{ item.str_bonus }}</span>
                    </div>
                    <div class="stat" v-if="item.vit_bonus !== ''">
                      VIT
                      <span>+{{ item.vit_bonus }}</span>
                    </div>
                    <div class="stat" v-if="item.int_bonus !== ''">
                      INT
                      <span>+{{ item.int_bonus }}</span>
                    </div>
                    <div class="stat" v-if="item.agi_bonus !== ''">
                      AGI
                      <span>+{{ item.agi_bonus }}</span>
                    </div>
                    <div class="stat" v-if="item.wil_bonus !== ''">
                      WILL
                      <span>+{{ item.wil_bonus }}</span>
                    </div>
                    <div class="stat" v-if="item.bonus_hp !== ''">
                      <span class="color-59">{{ `+${item.bonus_hp} HP` }} </span>
                    </div>
                    <div class="stat" v-if="item.bonus_mp !== ''">
                      <span class="color-59">+{{ `${item.bonus_mp} MP` }}</span>
                    </div>
                    <div class="stat" v-if="item.accuracy !== ''">
                      <span class="color-59">+{{ `${item.accuracy} ${$t('opporunityHitText')}` }} </span>
                    </div>
                    <div class="stat" v-if="item.eva !== ''">
                      <span class="color-59">+{{ `${item.eva} ${$t('opporunityEvaText')}` }}</span>
                    </div>
                    <div class="item-general" v-if="item.p_critical_dmg !== ''">
                      <span class="color-59">
                        +{{ `${item.p_critical_dmg} ${$t('opporunityPhysicCriticalDMGText')}` }}
                      </span>
                    </div>
                    <div class="item-general" v-if="item.m_critical_dmg !== ''">
                      <span class="color-59">
                        +{{ `${item.m_critical_dmg} ${$t('opporunityMagicCriticalDMGText')}` }}
                      </span>
                    </div>
                    <div class="item-general" v-if="item.p_critical_rate !== ''">
                      <span class="color-59">
                        +{{ `${item.p_critical_rate} ${$t('opporunityPhysicCriticalRateText')}` }}
                      </span>
                    </div>
                    <div class="item-general" v-if="item.m_critical_rate !== ''">
                      <span class="color-59">
                        +{{ `${item.m_critical_rate} ${$t('opporunityMagicCriticalRateText')}` }}
                      </span>
                    </div>
                    <div class="stat"
                      v-if="item.attribute_dmg_text !== null && item.attribute_dmg_text !== '' && item.attribute_dmg_text">
                      <span class="color-59">{{ `${item.attribute_dmg_text}` }}</span>
                    </div>
                    <div class="stat"
                      v-if="item.attribute_resistence_text !== null && item.attribute_resistence_text !== '' && item.attribute_resistence_text">
                      <span class="color-59">{{ `${item.attribute_resistence_text}` }}</span>
                    </div>
                    <div class="item-description color-15"
                      v-if="item.item_description !== '' || Object.keys(item.item_description).length > 0">
                      <span v-html="item.item_description"></span>
                    </div>
                    <div class="stat" v-if="item.durability !== ''">
                      {{ $t('durabilityText') }}
                      <span>{{ `${item.durability}/${item.durability} (${item.durability})` }}
                      </span>
                    </div>
                  </div>
                  <div class="media mb-2 mt-2 pl-4 row">
                    <div class="item-general-rebirth-restriction"
                      v-if="item.rebirth_restriction_text !== '' || Object.keys(item.rebirth_restriction_text).length > 1">
                      <span>{{ item.rebirth_restriction_text }}</span>
                    </div>
                    <div class="item-general" v-if="item.class_group !== ''">
                      <span v-for="(id_class, index) in item.class_group" v-bind:key="index">{{
                        asignar_classFigther(parseInt(id_class)) }}<span
                          v-if="index !== item.class_group.length - 1">/</span>
                        <br v-if="index === 2 || index === 5 || index === 8"></span>
                    </div>
                    <div class="item-general" v-if="item.sprite_skill_name">
                      <span style="color:#bbe477 !important;">
                        {{ `${$t('skill_spriteText')} ${item.sprite_skill_name}` }}
                      </span>
                    </div>
                    <div class="secondary-description" v-if="item.secondary_description !== ''">
                      <span v-html="item.secondary_description"></span>
                    </div>
                    <div class="item-general">
                    </div>
                    <div class="item-general-restrictions" v-if="item.restrictions">
                      <span class="badge mt-2" v-for="(restriccion, index) in item.restrictions"
                        v-bind:key="restriccion" style="font-size:small;"> {{ restriccion }} <br
                          v-if="index !== item.restrictions.length - 1">
                      </span>
                      <span class="badge mt-2"
                        v-if="item.unique_slot && item.unique_slot !== '0' && item.unique_slot !== ''"
                        style="font-size:small;"> {{ item.unique_slot }} </span>
                    </div>
                    <div class="item-general">
                      <div id="ride-synthesis" class="ride-synthesis">
                        <span class="" id="stats"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tbody>
          </div>
        </div>
      </div>

      <!-- Tabla de Crafting a la derecha -->
      <div class="col-lg-6">
        <div v-if="item.crafting" class="crafting-table dark">
          <h5 class="text-center">Craft</h5>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr v-for="itemCraft in item.crafting" :key="itemCraft.item_id">
                <td :class="`${checkExclusivity(itemCraft.item_id) ? 'exclusive' : ''}`">
                  <a :href="getItemUrl(itemCraft.item_id)">
                    <img :src="`https://gfmemories.com/images/itemicon/${itemCraft.ui_id_1?.toUpperCase()}.png`"
                      alt="Crafting Icon" class="img-fluid" style="width:32px">
                  </a>
                </td>
                <td
                  :class="`${checkExclusivity(itemCraft.item_id) ? 'exclusive' : ''} quality-${itemCraft.item_quality}`">
                  <a :href="getItemUrl(itemCraft.item_id)">{{ itemCraft.item_name?.[lang] }}</a>
                </td>
                <td :class="`${checkExclusivity(itemCraft.item_id) ? 'exclusive' : ''}`">{{ itemCraft.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="item.item_set && item.item_set.length > 0" class="crafting-table dark">
          <h5 class="text-center">Set</h5>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr v-for="itemSet in item.item_set" :key="itemSet.item_id">
                <td :class="`${checkExclusivity(itemSet.item_id) ? 'exclusive' : ''}`">
                  <a :href="getItemUrl(itemSet.item_id)">
                    <img :src="`https://gfmemories.com/images/itemicon/${itemSet.ui_id_1?.toUpperCase()}.png`"
                      alt="Set Icon" class="img-fluid" style="width:32px">
                  </a>
                </td>
                <td :class="`${checkExclusivity(itemSet.item_id) ? 'exclusive' : ''} quality-${itemSet.item_quality}`">
                  <a :href="getItemUrl(itemSet.item_id)">{{ itemSet.item_name?.[lang] }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="item.set_enchants && Object.keys(item.set_enchants).length > 0" class="crafting-table enchants dark">
          <h5 class="text-center">Bonus</h5>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr v-for="(value, key) in item.set_enchants" :key="key">
                <td><span class="color-13" v-html="value[lang]"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="item.can_be_found && item.can_be_found.length > 0" class="content-in">
      <h5 class="text-center text-white mb-3">{{ $t('contentIn') }}</h5>
      <div v-if="item.can_be_found.items && item.can_be_found.items.length > 0"
        class="row container bg-dark p-3 rounded">
        <div v-for="itemFound in item.can_be_found.items" :key="itemFound.item_id" class="col-4 text-center mb-4">
          <a :href="getItemUrl(itemFound.item_id)">
            <img :src="`https://gfmemories.com/images/itemicon/${itemFound.ui_id_1?.toUpperCase()}.png`" alt="Item Icon"
              class="img-fluid mb-2" style="width: 32px;">
            <br>
            {{ itemFound.item_name?.[lang] }}
          </a>
        </div>
      </div>
      <br>
      <div v-if="item.can_be_found.monsters && item.can_be_found.monsters.length > 0" class="content-in">
        <h5 class="text-center text-white mb-3">Drops</h5>
        <div class="row container bg-dark p-3 rounded">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th class="text-center col-4">NPC</th>
                <th class="text-center col-8">MAP</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="monster in item.can_be_found.monsters" :key="monster.id">
                <td class="text-center">
                  <a :href="getMonsterUrl(monster.id)" v-html="sanitizeHtml(monster.monster_name[lang])"></a>
                </td>
                <td v-if="monster.found_in_maps && monster.found_in_maps.length > 0" class="text-center">
                  <a :href="getMonsterUrl(monster.id)">
                    {{ monster.found_in_maps[0]?.scene_names[lang] }} (X: {{
                      monster.found_in_maps[0].x_coord }}, Y: {{
                      monster.found_in_maps[0].y_coord }})
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import imgCard from '@/assets/images/1x/card-update.png'
import service from '@/mixins/service.js';
import Spinner from "@/components/spinner/Spinner.vue";

export default {
  name: 'item-data-main',
  components: {
    Spinner,
  },
  mixins: [service],
  props: {
    item_id: String,
    msg: String,
    title: String,
    description: String,
  },
  watch: {
    "$route.params.id_item": "goToItemSingle",
    "$route.params.id_monster": "goToMonster",
  },
  methods: {
    checkExclusivity(id) {
      return (id >= 5200 && id <= 7000 || id >= 54336 && id <= 60000);
    },
    sanitizeHtml(text) {
      // Reemplazar las etiquetas <br> escapadas por saltos de línea reales
      const sanitizedText = text.replace('</br>', '').replace(/&lt;br&gt;|&lt;\/br&gt;/g, '<br>');

      // Luego escapamos cualquier otro contenido HTML para evitar problemas de inyección
      const element = document.createElement('div');
      element.innerText = sanitizedText;

      // Retornamos el contenido escapado con los <br> interpretados correctamente
      return element.innerHTML.replace(/&lt;br&gt;/g, '<br>');
    },
    async goToItemSingle(id) {
      if (id && this.$route.params.id_item) {
        await this.$router.push({ name: 'itemSingleView', params: { id_item: id || this.$route.params.id_item } });
        await this.$router.go();
      }
    },
    async goToMonster(id) {
      if (id && this.$route.params.id_monster) {
        await this.$router.push({ name: 'monsterView', params: { id_monster: id || this.$route.params.id_monster } });
        await this.$router.go();
      }
    },
    getItemUrl(id) {
      return `#/database/item-single/${id}`;
    },
    getMonsterUrl(id) {
      return `#/database/monster/${id}`;
    },
    asignar_classFigther(player_class) {
      if (player_class === 0) return this.$t('titleNovato');
      if (player_class === 13) return this.$t('titleHechicero');
      if (player_class === 5) return this.$t('titleCazador');
      if (player_class === 52) return this.$t('titleViajero');
      if (player_class === 25) return this.$t('titleMecanico');
      if (player_class === 2) return this.$t('titleLuchador');
      if (player_class === 9) return this.$t('titleAcolito');
      if (player_class === 14) return this.$t('titleMago');
      if (player_class === 6) return this.$t('titleArquero');
      if (player_class === 53) return this.$t('titleNomada');
      if (player_class === 26) return this.$t('titleIngeniero');
      if (player_class === 2) return this.$t('titleGuerrero');
      if (player_class === 10) return this.$t('titleSacerdote');
      if (player_class === 3) return this.$t('titleBerserker');
      if (player_class === 4) return this.$t('titlePaladin');
      if (player_class === 7) return this.$t('titleRanger');
      if (player_class === 8) return this.$t('titleAsesino');
      if (player_class === 11) return this.$t('titleClerigo');
      if (player_class === 12) return this.$t('titleSabio');
      if (player_class === 15) return this.$t('titleMagoBrujo');
      if (player_class === 16) return this.$t('titleNigromante');
      if (player_class === 27) return this.$t('titleArtificiero');
      if (player_class === 28) return this.$t('titleArtillero');
      if (player_class === 54) return this.$t('titleDuelista');
      if (player_class === 55) return this.$t('titleRelojero');
      if (player_class === 17) return this.$t('titleDW');
      if (player_class === 18) return this.$t('titleHk');
      if (player_class === 19) return this.$t('titleTh');
      if (player_class === 20) return this.$t('titleAo');
      if (player_class === 21) return this.$t('titleProfeta');
      if (player_class === 22) return this.$t('titleMistico');
      if (player_class === 23) return this.$t('titleAM');
      if (player_class === 24) return this.$t('titleDM');
      if (player_class === 29) return this.$t('titlePrime');
      if (player_class === 30) return this.$t('titleOptimus');
      if (player_class === 56) return this.$t('titleSamurai');
      if (player_class === 57) return this.$t('titleMaestroTiempo');
      if (player_class === 32) return this.$t('titleDK');
      if (player_class === 33) return this.$t('titleCaballeroSagrado');
      if (player_class === 34) return this.$t('titleBallestero');
      if (player_class === 35) return this.$t('titleNinja');
      if (player_class === 36) return this.$t('titleSanto');
      if (player_class === 37) return this.$t('titleChaman');
      if (player_class === 38) return this.$t('titleAvatar');
      if (player_class === 39) return this.$t('titleAmoAlmas');
      if (player_class === 48) return this.$t('titleMegatron');
      if (player_class === 49) return this.$t('titleGalvatron');
      if (player_class === 58) return this.$t('titleDimensionalista');
      if (player_class === 59) return this.$t('titleOraculo');
      if (player_class === 60) return this.$t('titleEspectroMortal');
      if (player_class === 61) return this.$t('titleAlteradorCosmico');
      if (player_class === 40) return this.$t('titleDestructor');
      if (player_class === 41) return this.$t('titleCruzado');
      if (player_class === 42) return this.$t('titleDepredador');
      if (player_class === 43) return this.$t('titleShinobi');
      if (player_class === 44) return this.$t('titleArcangel');
      if (player_class === 45) return this.$t('titleDruida');
      if (player_class === 46) return this.$t('titleBrujo');
      if (player_class === 47) return this.$t('titleShinigami');
      if (player_class === 50) return this.$t('titleOmega');
      if (player_class === 51) return this.$t('titleDc');
    },

  },
  setup(props) {
    let style = ref('null')
    let lang = ref('')
    let item = ref('')
    let loading = ref(true);
    let validRequestDisable = ref(false)
    let cardImg = ref(imgCard)
    lang.value = localStorage.getItem('language')

    if (lang.value === 'br') {
      lang.value = 'pt'
    }
    const getItemById = async () => {
      validRequestDisable.value = true
      const data = {
        path: `/items/${props.item_id}`,
        method: 'GET',
      }

      try {
        const itemData = await service.methods.callService(data)
        item.value = await itemData
        if (itemData.crafting && Object.keys(itemData.crafting).length > 0) {
          const parseIds = Object.keys(itemData.crafting).map(id => parseInt(id))
          const dataCraft = {
            path: `/items/findByIds`,
            method: 'POST',
            data: { ids: parseIds }
          }

          const itemCraft = await service.methods.callService(dataCraft)
          item.value.crafting = itemCraft.map((craft) => ({ ...craft, quantity: itemData.crafting[craft.item_id] }))

        }

        if (itemData.item_set && itemData.item_set.length > 0) {
          const dataSet = {
            path: `/items/findByIds`,
            method: 'POST',
            data: { ids: itemData.item_set }
          }

          const itemSet = await service.methods.callService(dataSet)
          item.value.item_set = itemSet
        }

        if (itemData.can_be_found && itemData.can_be_found.length > 0) {
          const itemIds = itemData.can_be_found.filter(id => id <= 49999)
          const monsterIds = itemData.can_be_found.filter(id => id > 50000)

          const dataItems = {
            path: `/items/findByIds`,
            method: 'POST',
            data: { ids: itemIds }
          }

          const dataMonsters = {
            path: `/monsters/findByIds`,
            method: 'POST',
            data: { ids: monsterIds }
          }

          const items = await service.methods.callService(dataItems)
          const monsters = await service.methods.callService(dataMonsters)

          item.value.can_be_found.items = items;
          item.value.can_be_found.monsters = monsters;
        }

        validRequestDisable.value = false

        let item_nameJSON = item.value.item_name
        item.value.item_name = item_nameJSON[lang.value]
        if (item.value.class_group !== '') {
          let classCombinationArray = item.value.class_group;
          item.value.class_group = classCombinationArray
        }
        if (item.value.sprite_skill !== '') {
          let sprite_skill_nameJSON = item.value.sprite_skill_name

          item.value.sprite_skill_name = sprite_skill_nameJSON[lang.value]
        }
        if (item.value.secondary_description !== '') {
          let secondary_descriptionJSON = item.value.secondary_description
          item.value.secondary_description = secondary_descriptionJSON[lang.value]
        }
        if (item.value.item_description !== '') {
          let item_descriptionJSON = item.value.item_description
          item.value.item_description = item_descriptionJSON[lang.value]
        }
        if (item.value.rebirth_count !== '') {
          let item_drebirthRestriccionJSON = await item.value.rebirth_restriction_text
          item.value.rebirth_restriction_text = item_drebirthRestriccionJSON[lang.value]
        }
        if (item.value.restrictions && item.value.restrictions !== null && item.value.restrictions !== '') {
          let RestriccionJSON = item.value.restrictions
          item.value.restrictions = RestriccionJSON[lang.value]
        }
        if (item.value.unique_slot !== null && item.value.unique_slot !== '' && item.value.unique_slot !== '0' && item.value.unique_slot) {
          let restriccionUniqueJSON = item.value.unique_slot
          item.value.unique_slot = restriccionUniqueJSON[lang.value]
        }
        if (item.value.attribute_dmg_text && item.value.attribute_dmg_text !== '' && item.value.attribute_dmg_text !== null) {
          let atributteJSON = item.value.attribute_dmg_text
          item.value.attribute_dmg_text = atributteJSON[lang.value]
        }
        if (item.value.attribute_resistence_text && item.value.attribute_resistence_text !== '' && item.value.attribute_resistence_text !== null) {
          let atributteJSON = item.value.attribute_resistence_text
          item.value.attribute_resistence_text = atributteJSON[lang.value]
        }
      } catch (error) {
        console.log("Ha ocurrido un fallo al cargar el item");
        validRequestDisable.value = false;
      } finally {
        loading.value = false;
      }
    };
    getItemById()
    //style = `background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `background : linear-gradient(to right, #212121, #141414);boder: 3px solid #212121`
    return {
      style,
      lang,
      cardImg,
      loading,
      item,
      getItemById,
    };
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.row>* {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-name2 {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.crafting-table {
  padding: 0em 2em !important;
}

.crafting-table.enchants span {
  font-weight: lighter;
  font-size: 12px;
}

.content-in {
  font-size: 12px;
}

.media {
  display: flex;
  align-items: flex-start;
}

.item-information-table .item-description span,
.item-information-table .secondary-description span {
  text-align: justify;
  display: block;
  padding-right: 2em !important;
}


.table th,
.table td {
  vertical-align: middle;
  background-color: #464646;
  color: #FFFFFF;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline
}

.dark {
  padding: 2em;
}

.item-general.row div {
  padding-left: 0;
}


.exclusive {
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: inset 0 0 5px yellow;
  }

  50% {
    box-shadow: inset 0 0 10px yellow;
  }

  100% {
    box-shadow: inset 0 0 5px yellow;
  }
}
</style>
