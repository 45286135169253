const subtractHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
}

export const calculateElapsedTime = (date) => {
  const currentDate = new Date();
  const publicationDate = subtractHours(new Date(date), 5);
  const elapsedMillis = currentDate - publicationDate;
  const elapsedSeconds = Math.floor(elapsedMillis / 1000);
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const elapsedDays = Math.floor(elapsedHours / 24);
  const elapsedWeeks = Math.floor(elapsedDays / 7);
  if (elapsedDays >= 30) {
    const elapsedMonths = Math.floor(elapsedDays / 30);
    return [elapsedMonths, 'monthAgo'];
  } else if (elapsedWeeks >= 1) {
    return [elapsedWeeks, 'weeksAgo'];
  } else if (elapsedDays >= 1) {
    return [elapsedDays, 'daysAgo'];
  } else if (elapsedHours >= 1) {
    return [elapsedHours, 'hoursAgo'];
  } else if (elapsedMinutes >= 1) {
    return [elapsedMinutes, 'minutesAgo'];
  } else {
    return ['', 'nowAgo'];
  }
}
