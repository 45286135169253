<template>
  <BannerTitle :msg="$t('terms')"></BannerTitle>
  <div class="termsPage flex justify-content-center">
    <div v-if="$t('lang') == 'es'" class="w-7 pt-7">
      <p><b>Propósito y Naturaleza del Servidor</b></p>
      <p>
        <strong>GFMemories es un servidor privado independiente y no oficial de Grand Fantasia.
          Queremos aclarar que no estamos afiliados, respaldados ni asociados de ninguna
          manera con la empresa oficial que desarrolla o gestiona Grand Fantasia/X-Legends.</strong>
      </p>
      <p>
        Nuestro servidor se ha creado con el propósito de ofrecer una experiencia de servicio
        única y personalizada para nuestros usuarios. Entendemos que somos una entidad separada
        y privada, y no pretendemos hacernos pasar por ni usurpar la identidad de la empresa
        creadora del servicio.
      </p>
      <p>
        Estos términos de servicio (en adelante, los “<strong>Términos</strong>”)
        establecen los términos y condiciones según los cuales GFMemories le ofrece
        el acceso al uso y disfrute de nuestro juego, sitios web y otros servicios
        (en adelante, los “<strong>Servicios de</strong><strong> GFMemories</strong>”).
        Estos Términos son un contrato entre usted y esta entidad.
      </p>
      <p>
        Al acceder y utilizar los servicios de GFMemories, aceptas y reconoces que es
        un servidor privado no oficial.Te animamos a revisar detenidamente estos términos
        y condiciones antes de continuar utilizando nuestros servicios:
      </p>
      <ul>
        <li>
          <strong>Contenido virtual</strong>. Al hacer clic para comprar, ganar o
          recibir de obsequio Contenido virtual, solo obtiene una licencia para
          acceder al Contenido virtual. No tiene titularidad ninguna sobre el
          Contenido virtual que desbloquea, y no puede transferirlo a nadie más.
          El Contenido virtual no tiene valor monetario, en general es específico
          del juego y no puede canjearse por ningún tipo de dinero del “mundo
          real”.
        </li>
        <li>
          <strong>Política de reembolso</strong>. Pierde el derecho a cambiar de
          parecer, cancelar el pedido y obtener un reembolso si obtiene acceso
          inmediato al contenido digital o lo descarga.
        </li>
      </ul>
      <ul type="disc">
        <li>
          <strong>Reglas del usuario</strong>. Acuerda seguir nuestras Reglas del
          usuario.
          Estas reglas rigen tanto el comportamiento en el juego como ciertas
          conductas fuera del juego al usar los Servicios de GFMemories
        </li>
      </ul>
      <h4>1. SU CUENTA</h4>
      <p>
        <strong>1.1. ¿<u>Puedo crear una cuenta y usar los Servicios de</u></strong>
        GFMemories <strong><u>?</u></strong>
        <strong>
          (Tiene que ser mayor de edad o tener el permiso de sus padres o tutor
          legal).
        </strong>
      </p>
      <p>
        Necesitará una cuenta de GFMemories para acceder a muchos de nuestros
        servicios. Para crear una cuenta y usar los Servicios de GFMemories tiene
        que (i) ser mayor de edad; (ii) ser menor emancipado; o (iii) tener el
        consentimiento válido de su padre, madre o tutor legal para obligarse según
        estos Términos. Si no es mayor de edad, menor emancipado o no entiende esta
        cláusula, hable con sus padres o tutor legal y pida ayuda. Si es el padre,
        madre o tutor legal de un menor que crea una cuenta con nosotros, usted y el
        menor aceptan y acuerdan vincularse por estos Términos. También es
        responsable de todo el uso de la cuenta y del cumplimiento de estos Términos
        por parte del menor, incluidas todas las compras hechas en la cuenta. No
        podrá crear ni usar una cuenta, ni usar ninguno de los Servicios de
        GFMemories en nombre de ninguna otra persona, ni de ninguna persona
        jurídica, ni para fines comerciales.
      </p>
      <p>
        <strong>
          1.2. <u>¿Cómo puedo crear una cuenta?</u> (En nuestro sitio web).
        </strong>
      </p>
      <p>
        Puede crear una cuenta con credenciales de inicio de sesión únicas en
        nuestros sitios web.
      </p>
      <p>
        <strong>
          1.3. ¿ <u>Tengo que facilitar mi nombre y demás datos reales?</u> (Sí.
          Por favor, no incluya nombres por diversión).
        </strong>
      </p>
      <p>
        Deberá aportar siempre la información precisa y completa, incluido su nombre
        real.
      </p>
      <p>
        <strong>
          1.4. ¿
          <u>
            Puedo compartir o vender mi cuenta o mi información de inicio de
            sesión?
          </u>
          (No).
        </strong>
      </p>
      <p>
        Al crear una cuenta, se le pedirá que seleccione un nombre de usuario y una
        contraseña únicos (en conjunto, la “<strong>Información de inicio de sesión</strong>”).
        Acuerda que:
      </p>
      <ul>
        <li>
          No puede compartir su cuenta ni su Información de inicio de sesión con
          nadie.
        </li>
        <li>
          No puede vender ni transferir su cuenta ni su Información de inicio de
          sesión, ni permitir que otra persona acceda a estas. Tampoco puede
          ofrecerse a ello.
        </li>
        <li>
          Tiene que mantener secreta su Información de inicio de sesión.
        </li>
      </ul>
      <p>
        Deberá notificarnos inmediatamente en caso de que llegue a su conocimiento
        cualquier vulneración de la seguridad, incluido el acceso no autorizado a la
        cuenta, o la pérdida, el robo o la revelación no autorizada de su
        Información de inicio de sesión o de la información de pago, a fin de que
        podamos adoptar las medidas apropiadas. Usted es responsable de todas las
        pérdidas (incluida la pérdida o el uso del Contenido virtual) en su cuenta
        cuando haya compartido su Información de inicio de sesión o no haya
        mantenido protegida la cuenta o la Información de inicio de sesión.
      </p>
      <h4>
        2. CANCELACIÓN DE LA CUENTA
      </h4>
      <p>
        <strong>
          2.1. ¿ <u>Cómo puede mi cuenta ser suspendida o cancelada?</u>
        </strong>
      </p>
      <p>
        2.1.1. <strong>Usted</strong>. Puede cancelar o suspender su cuenta en
        cualquier momento. Para hacerlo, póngase en contacto con nosotros a través
        de la dirección de correo electrónico gfmemorieswork@gmail.com.
      </p>
      <p>
        2.1.2. <strong>Nosotros</strong>. Podemos cancelar o suspender su cuenta sin
        previo aviso si, actuando razonablemente, concluimos que:
      </p>
      <ol>
        <li>
          Ha incumplido cualquier parte de estos Términos (incluidas las Reglas
          del usuario).
        </li>
        <li>
          Actuamos en interés de nuestra comunidad, de los GFMemories o es
          necesario a los efectos de proteger los derechos de terceros.
        </li>
        <li>
          Hemos dejado de ofrecer los GFMemories en su región (aunque normalmente
          publicamos un aviso por anticipado en nuestro sitio web, aplicación o
          juego si pensamos dejar de ofrecer una característica fundamental de un
          juego o de todos los GFMemories en su región).
        </li>
        <li>
          No paga alguna comisión que nos deba (excepto en caso de negligencia
          grave o conducta inapropiada premeditada) o tenemos que reembolsar a
          alguien debido al uso no autorizado de un pago hecho desde su cuenta.
        </li>
        <li>
          Ha hecho (o tenemos fundamentos razonables para sospechar que ha hecho)
          un uso no autorizado de la información de pago de alguien más.
        </li>
      </ol>
      <p>
        Podemos llegar a tales conclusiones con la ayuda de sistemas automatizados o
        herramientas de aprendizaje automático, o mediante otros métodos que
        consideremos apropiados. Si cree que hemos cometido un error, póngase en
        contacto con nosotros con los detalles y revisaremos su caso, aunque podemos
        suspender su cuenta durante la revisión. Puede refutar nuestras
        conclusiones.
      </p>
      <h4>
        3. BIENES VIRTUALES, MONEDA DEL JUEGO Y COMPRAS
      </h4>
      <p>
        <strong>
          3.1. ¿ <u>Qué es el Contenido virtual?</u> (El Contenido virtual incluye
          cosas como la moneda del juego y bienes virtuales, como campeones,
          aspectos, emoticonos, accesorios, etc.).
        </strong>
      </p>
      <p>
        Al usar los GFMemories, podremos brindarle la oportunidad de adquirir una
        licencia limitada para acceder a bienes virtuales, como campeones, aspectos,
        emoticonos, etc., (“<strong>Bienes virtuales</strong>”) y moneda en el juego
        (“<strong>Moneda del juego</strong>”), asociados con su cuenta (en su
        conjunto, el “<strong>Contenido virtual</strong>”).
      </p>
      <p>
        La Moneda del juego solo podrá usarse para el juego específico para el que
        se ha comprado, a menos que indiquemos lo contrario en el momento de la
        compra.
      </p>
      <p>
        <strong>
          3.2. ¿
          <u>
            Cómo consigo una “licencia” para acceder al Contenido virtual?
          </u>
          (Cómprala, gánala o recíbela).
        </strong>
      </p>
      <p>
        Podemos brindarle distintas oportunidades para adquirir una licencia
        limitada para acceder a la Moneda del juego o Contenido virtual, entre
        otras:
      </p>
      <ol>
        <li>
          comprarla (p. ej.: con una tarjeta de crédito);
        </li>
        <li>
          ganarla (p. ej.: al completar una misión o tareas del juego);
        </li>
        <li>
          recibirla (p. ej.: de otros jugadores como regalo).
        </li>
      </ol>
      <p>
        <strong>
          3.3. ¿ <u>Me “pertenece” el Contenido virtual que he desbloqueado?</u> .
        </strong>
      </p>
      <p>
        No es el propietario ni tiene ningún otro derecho de titularidad sobre el
        Contenido virtual que desbloquea, independientemente de si ha adquirido el
        acceso a este. El Contenido virtual no tiene valor monetario. No puede
        transferir ni canjear Contenido virtual por ningún tipo de dinero del “mundo
        real”.
        <strong>
          No puede obtener ningún reembolso por la compra de una licencia para
          acceder al Contenido virtual, salvo que así lo hayamos indicado
          expresamente
        </strong>
        .
      </p>
      <p>
        <strong>
          3.4. ¿ <u>Entonces no soy el propietario de mi Contenido virtual?</u> ).
        </strong>
      </p>
      <p>
        Cuando obtiene Contenido virtual, lo que le damos en realidad es un derecho
        y licencia personal, no exclusivo, no transferible, no sublicenciable,
        revocable y limitado para usar ese Contenido virtual únicamente en relación
        con su uso de GFMemories correspondiente.
      </p>
      <p>
        <strong>
          A pesar de cualquier disposición contraria a estos Términos, usted
          reconoce y acepta que no tendrá ningún derecho de propiedad ni ningún
          otro derecho de titularidad sobre su cuenta, y que todos los derechos de
          su cuenta son y siempre serán de titularidad y en beneficio de
        </strong>
        <strong>GFMemories</strong><strong>. Además, usted reconoce y acepta que no
          tiene ningún título, propiedad u otro derecho de titularidad sobre el
          Contenido virtual, independientemente de cualquier contraprestación ofrecida
          o pagada a cambio. Además, salvo en caso de conducta inapropiada premeditada
          o negligencia grave, o en la medida en que los presentes Términos indiquen
          lo contrario,</strong><strong>GFMemories</strong><strong>no será responsable
          de ningún modo por la destrucción, la eliminación, la modificación, el
          deterioro, la piratería o cualquier otro daño o pérdida de cualquier tipo
          causados al Contenido virtual, incluida eliminación del Contenido virtual
          tras la cancelación o el vencimiento de su cuenta o de nuestros cambios
          justificados a los</strong><strong>GFMemories</strong> <strong>.</strong>
      </p>
      <p>
        <strong>
          3.5. ¿ <u>Estará siempre disponible mi Contenido virtual?</u> (No
          necesariamente).
        </strong>
      </p>
      <p>
        En un esfuerzo por mejorar constantemente los GFMemories y hacer evolucionar
        nuestros juegos y mantener seguros, divertidos y protegidos los GFMemories,
        tenemos el derecho de suprimir, alterar, mover, eliminar, reagrupar,
        revalorar o transferir cualquier Contenido del juego, incluido el Contenido
        virtual, total o parcialmente, en cualquier momento, con o sin motivo o
        previo aviso, y sin ningún tipo de responsabilidad frente a usted. Por
        ejemplo, sus Bienes virtuales pueden evolucionar (y es probable que lo
        hagan) con el tiempo para mejorar los GFMemories o por motivos normativos o
        jurídicos. Si decidimos retirar por completo ciertos Bienes virtuales que
        haya comprado anteriormente, le entregaremos un sustituto. No eliminaremos
        su Moneda del juego sin previo aviso (ya sea mediante publicaciones en
        nuestro sitio web, aplicación o juego), a menos que cancelemos su cuenta por
        un motivo válido o lo haga usted en virtud de la Cláusula 2.1.1
        . En ocasiones, podemos cambiar la capacidad de compra de la Moneda del
        juego (por ejemplo, podemos aumentar la cantidad de la Moneda del juego
        necesaria para comprar Bienes virtuales, por ejemplo, aspectos). En general,
        solo hacemos esto de manera gradual, pero le avisaremos (ya sea mediante
        publicaciones en nuestro sitio web, aplicación o juego) si tenemos pensado
        realizar cambios que afecten significativamente su Moneda del juego de
        manera negativa. No otorgamos ninguna garantía, y excluimos cualquier clase
        de responsabilidad respecto a cualquier valor, dinero en efectivo u otro,
        atribuido a la información contenida en los servidores que utilizamos o
        controlamos, incluido todo Contenido del juego o Contenido virtual
        atribuidos a su cuenta. Si permitimos la venta o transferencia de su derecho
        a acceder a cierto Contenido del juego o Contenido virtual, solo se podrá
        realizar a través de los servicios aprobados o proporcionados por nosotros,
        si los hubiere.
      </p>
      <h4>
        4. TASAS E IMPUESTOS
      </h4>
      <p>
        4.1. ¿<u>Es gratuito todo lo relativo a los Servicios de</u>GFMemories?
      </p>
      <p>
        Algunas cuestiones de los Servicios de GFMemories pueden exigir el pago de
        una tarifa, y usted acuerda proporcionarnos o a los terceros proveedores de
        servicios de pago que utilicemos la información de pago precisa y completa.
        Además, acuerda abonar todas las tasas e impuestos aplicables en que incurra
        su cuenta. Podemos revisar los precios de cualquier parte de los GFMemories
        en cualquier momento. Esto puede afectar la capacidad de compra de la Moneda
        del juego, aunque, en general, solo hacemos esto de manera gradual. Todas
        las tasas e impuestos aplicables deberán abonarse de conformidad con los
        términos de pago vigentes en el momento del vencimiento. Nos reservamos,
        oportunamente, el derecho de modificar, corregir o complementar las tasas y
        métodos de facturación. Tales cambios tendrán efecto inmediato tras su
        publicación en estos Términos o en otro lugar de nuestros sitios web,
        aplicaciones o juegos. Salvo en casos de negligencia grave o conducta
        inapropiada premeditada, si no paga alguna tasa debida o si tenemos que
        reembolsar a alguien debido al uso no autorizado de un pago hecho desde su
        cuenta, podremos suspender o cancelar su cuenta. Para obtener más
        información, consulte la Cláusula 2
        (Cancelación de la cuenta).
      </p>
      <p>
        <strong>
          <u>Sujetos a la legislación vigente, las tasas aplicables y otros cargos
            por servicios tarifados (incluida la Moneda del juego) se pagan por
            adelantado y no son reembolsables en su totalidad ni parcialmente,
            excepto según lo indicado expresamente en los presentes Términos. Salvo
            en casos de negligencia grave o conducta inapropiada premeditada de
            parte de</u>
        </strong>
        GFMemories
        <strong>
          <u>, o en la medida en que estos Términos indiquen lo contrario, usted
            es responsable de todos los cargos en su cuenta, incluidos los cargos no
            autorizados.</u>
        </strong>
      </p>
      <p>
        4.2. ¿
        <u>
          Cuáles son mis responsabilidades en relación con la Moneda del juego?
        </u>
        (Pagar los impuestos correspondientes; verificar que las transacciones
        realizadas con nosotros sean correctas, etc.).
      </p>
      <p>
        Usted es el único responsable de pagar los impuestos correspondientes a la
        adquisición de la Moneda del juego, su uso o acceso. Podemos vender o emitir
        la Moneda del juego en paquetes, y el precio puede variar en función de la
        cantidad que se compre y el lugar donde se realice la adquisición. A nuestra
        discreción y si lo creemos necesario (o si la legislación así lo exige),
        podremos limitar la cantidad total de Moneda del juego que pueda comprarse a
        la vez para cualquier juego individual o que pueda acumularse en total en su
        cuenta. Además, el precio y la disponibilidad de la Moneda del juego y los
        Bienes virtuales están sujetos a cambios sin previo aviso. Podemos
        restringir la cantidad de Moneda del juego que puede comprar o usar en su
        ubicación, ya que existen distintos enfoques según las distintas
        ubicaciones.
      </p>
      <p>
        Debe verificar que se haya añadido o deducido la cantidad apropiada de
        Moneda del juego en su cuenta durante cualquier transacción. Infórmenos
        inmediatamente si cree que hay algún error en su saldo de Moneda del juego.
        GFMemories investigará su reclamación y, al hacerlo, podrá solicitarle
        información adicional para verificarla.
      </p>
      <h4>
        5. REGLAS DEL USUARIO
      </h4>
      <p>
        5.1. ¿
        <u>
          Puedo provocar, atacar, amenazar o acosar a las personas mientras uso
          los Servicios de GFMemories?
        </u>
        (No. Si lo hace, podremos adoptar medidas, como la suspensión de su cuenta).
      </p>
      <p>
        Al usar los Servicios de GFMemories, tiene que cumplir todas las leyes,
        normas y reglamentos aplicables en su lugar de residencia. Además, debe
        cumplir con las políticas sobre usos y comportamientos aceptables que
        publicamos oportunamente en nuestros sitios web, aplicaciones y juegos, y
        las reglas de comportamiento que se enumeran más adelante (en su conjunto,
        las “<strong>Reglas del usuario</strong>”). Las Reglas del usuario
        publicadas en nuestros sitios web, o declaradas en esta Cláusula no
        pretenden ser exhaustivas, y nos reservamos el derecho de modificarlas en
        cualquier momento, así como de tomar las medidas disciplinarias pertinentes,
        que incluyen el bloqueo temporal, la suspensión o la cancelación y
        eliminación de la cuenta, para proteger la integridad y el espíritu de los
        GFMemories, independientemente de si un comportamiento concreto se incluye o
        no en las Reglas del usuario como inapropiado.
      </p>
      <p>
        Los siguientes son ejemplos de comportamientos que provocarán la adopción de
        medidas disciplinarias: <br />
        <br />
        - Sera motivo de sanción insultar al Staff del servidor
      </p>
      <p>
        - Sera motivo de sanción hacerse pasar por un miembro del Staff
      </p>
      <p>
        - Está prohibido la compra y venta de Artículos del juego y oro por dinero
        real
      </p>
      <p>
        - Sera motivo de sanción la compra y venta de cuentas sin importar el medio de
        pago (No se recuperarán cuentas que fueran robadas por ventas)
      </p>
      <p>
        - Sera motivo de sanción el uso de lenguaje inapropiado tanto dentro del juego
        como en las redes del servidor
      </p>
      <p>
        - Sera motivo de sanción utilizar nombres de jugadores o gremios que sean
        ofensivos
      </p>
      <p>
        - Sera motivo de sanción la utilización de cualquier lenguaje inapropiado, en
        este servidor no se aceptará ningún tipo de discriminación por motivos de
        creencias religiosas, edad, género, orientación sexual, discapacidad, raza u
        origen étnico
      </p>
      <p>
        - Sera motivo de sanción el acoso a los demás jugadores sin importar cual sea
        la forma ni el motivo que se realice
      </p>
      <p>
        - Sera motivo de sanción interrumpir con un evento programado por el Staff
      </p>
      <p>
        - Sera motivo de sanción abusar de bugs o provocarlos intencionalmente para
        sacar un beneficio
      </p>
      <p>
        - Sera motivo de sanción la manipulación de archivos dentro del juego que
        sacar una ventaja en la jugabilidad
      </p>
      <p>
        - Sera motivo de sanción y/o baneo permanente a las personas que usen
        multicuentas para farmeo de misiones, dungs y oro.
      </p>
      <p>
        - Sera motivo de sanción el antigame en las arenas (El no ayudar a tu propio
        equipo por encontrarse tu gremio, tu amigo o tu pareja del otro lado)
      </p>
      <p>
        - Sera motivo de sanción el farmeo de curación, muertes o la utilización de
        cualquier método para acceder a mejores puestos en la clasificación o para
        beneficiarse en obtener antes los sets PVP/ GW
      </p>
      <p>
        - Sera motivo de sanción abandonar de forma voluntaria en reiteradas veces las
        arenas
      </p>
      <p>
        - Sera motivo de sanción la utilización de hacks o cheats que busquen sacar
        una ventaja dentro del juego contra la comunidad
      </p>
      <p>
        Se permite matar cuantas veces quieran a una persona que se encuentre dentro
        del canal PVP, pero será motivo de sanción si se demuestra que existe una
        persecución posterior en los diferentes canales NO PVP
      </p>
      <h4>
        6. SEGUIMIENTO Y SISTEMAS ANTITRAMPAS
      </h4>
      <p>
        6.1. ¿<u>Seré controlado por</u>GFMemories<u>mientras use los Servicios de</u>GFMemories
        <u>?</u> (Sí. Lea el Aviso de privacidad para más información).
      </p>
      <p>
        Podemos hacer un seguimiento activo de uso de los Servicios de GFMemories
        (aunque no tenemos obligación de hacerlo), tanto en nuestros propios
        servidores como en su ordenador, con varios propósitos, incluida la
        prevención de las trampas, la eliminación o la reducción del comportamiento
        disruptivo de los jugadores y la mejora de los Servicios de GFMemories.
      </p>
      <p>
        6.2.
        <u>
          ¿GFMemories ejecuta programas en mi dispositivo cuando no utilizo los
          Servicios de GFMemories?
        </u>
        (Podemos hacerlo con fines antitrampas limitados).
      </p>
      <p>
        Para evitar las trampas y la piratería, podemos exigirle que instale
        software antitrampas. Este software puede ejecutarse en segundo plano en su
        dispositivo.
      </p>
      <h4>
        7. ACTUALIZACIONES Y MODIFICACIONES
      </h4>
      <p>
        7.1. ¿ <u>Cambiarán estos Términos en el futuro?</u> (Sí).
      </p>
      <p>
        Podemos crear (y probablemente crearemos) tantas versiones actualizadas de
        estos Términos en el futuro como los Servicios de GFMemories y la
        legislación vigente lo requieran. Cuando lo hagamos, le informaremos del
        nuevo acuerdo que sustituirá y reemplazará estos Términos. Se le dará la
        oportunidad de revisar cualquier nuevo acuerdo que le presentemos, y usted
        podrá decidir si desea aceptar los términos revisados. Si acepta el nuevo
        acuerdo, podrá seguir utilizando los Servicios de GFMemories Si rechaza el
        nuevo acuerdo, ya no podrá seguir utilizando los Servicios de GFMemories
      </p>
      <p>
        7.2. ¿<u>Se mantendrán los Servicios de</u>GFMemories
        <u>
          sin modificarse?
        </u>
        (No. Las clases, van a evolucionar).
      </p>
      <p>
        Con el fin de mejorar constantemente los Servicios de GFMemories hacer
        evolucionar nuestros juegos y mantener seguros, divertidos y protegidos los
        Servicios de GFMemories, acepta que podemos cambiar, modificar, actualizar,
        suspender, inutilizar o restringir el acceso a cualquiera de las funciones o
        partes de los Servicios de GFMemories, incluidos los Bienes virtuales (por
        ejemplo, podemos cambiar algunas funciones de los Bienes virtuales por
        motivos normativos o legales, o bien para mejorar la experiencia de juego),
        y que puede ser necesario que usted descargue e instale software y
        actualizaciones de cualquier software requerido por los Servicios de
        GFMemories, en cualquier momento, sin responsabilidad ante usted. También
        entiende y acepta que tales cambios o actualizaciones de los Servicios de
        GFMemories podrán modificar las especificaciones del sistema necesarias para
        jugar a nuestros juegos y, en tal caso, será usted, y no GFMemories, quien
        tendrá la responsabilidad de adquirir cualquier software adicional o
        hardware con el fin de acceder y jugar a nuestro juego. También entiende y
        acepta que podemos utilizar parches en segundo plano para actualizar
        automáticamente juego y el software con o sin previo aviso.
      </p>
      <h4>
        8. AUSENCIA DE GARANTÍAS
      </h4>
      <p>
        8.1. <u>¿Otorga</u>GFMemories<u>alguna garantía en relación con los
          Servicios de</u>GFMemories?
      </p>
      <p>
        <strong>
          <u>Asume toda la responsabilidad con respecto a la instalación y el uso
            de los Servicios de</u>
        </strong>
        GFMemories
        <strong>
          <u>, así como de los resultados obtenidos (independientemente de dónde
            se encuentre).</u>
        </strong>
      </p>
      <p>
        Además, si se encuentra en
        <strong>
          América del Norte, Central o del Sur
        </strong>
        :
      </p>
      <p>
        <strong>
          <u>Los Servicios de GFMemories se proporcionan "tal cual" y “sujetos a
            disponibilidad” sin garantías ni declaraciones de ningún tipo, sean
            expresas o implícitas. Asume toda la responsabilidad con respecto al uso
            de los Servicios de</u>
        </strong>
        <strong><u>GFMemories</u></strong><strong><u>. Hasta donde lo permita la
            legislación
            vigente,</u></strong><strong><u>GFMemories</u></strong><strong><u></u></strong><strong><u>GFMemories</u></strong><strong><u>no
            asume ninguna garantía, expresa o implícita, que pudiera aplicarse a los
            Servicios de</u></strong><strong><u>GFMemories</u></strong><strong><u>incluidas,
            entre otras, las garantías implícitas de título, no infracción, idoneidad
            para el comercio, adecuación a un fin determinado y cualesquiera otras
            garantías que puedan derivarse del tráfico, la ejecución o el comercio, así
            como cualesquiera garantías de precisión, fiabilidad o calidad de cualquier
            contenido o información incluidos en los Servicios de</u></strong>
        <strong><u>GFMemories</u></strong>
      </p>
      <h4>
        9. MISCELÁNEA
      </h4>
      <p>
        9.1. <u>¿Qué puedo hacer si tengo preguntas acerca de los Servicios
          de</u>GFMemories? .
      </p>
      <p>
        Si tiene alguna pregunta acerca de los Servicios de GFMemories, o si desea ponerse
        en contacto con nosotros por cualquier otro motivo, escríbanos a
        gfmemorieswork@gmail.com.
      </p>
      <p>
        9.2. <u>¿Puede cualquiera de las partes ceder estos Términos</u>? (No. Solo
        nosotros).
      </p>
      <p>
        GFMemories podrá ceder los derechos en virtud de los presentes Términos, en
        su totalidad o en parte, a cualquier persona física o jurídica, en cualquier
        momento, tanto con su consentimiento como sin él. Usted no podrá ceder sus
        derechos en virtud de estos Términos sin el consentimiento previo y por
        escrito de GFMemories. Cualquier cesión no autorizada será nula.
      </p>
      <p>
        9.3. <u>¿Es este documento el acuerdo completo entre las partes</u>? (Sí,
        incluidas aquellas otras políticas importantes que mencionamos
        anteriormente).
      </p>
      <p>
        Estos Términos representan el acuerdo completo entre usted y GFMemories con
        respecto a los Servicios de GFMemories, y reemplazan cualesquiera otros
        acuerdos anteriores o concurrentes entre usted y GFMemories. Estos Términos
        coexistirán con toda otra política de GFMemories a la que se haga
        referencia en los presentes Términos, y no la reemplazarán. Usted y
        GFMemories acuerdan que no se han basado y no tienen ningún recurso con
        respecto a ninguno de los términos, condiciones, declaraciones, garantías o
        similares que no se hayan establecido expresamente en estos Términos.
      </p>
      <p>
        9.4. <u>¿Qué sucede si un desastre natural interfiere con el juego</u>?
        (GFMemories no será responsable).
      </p>
      <p>
        GFMemories no tendrá responsabilidad alguna por ningún retraso ni fallo de
        ejecución, incluidos los fallos de ejecución en virtud de estos Términos
        debido a circunstancias imprevisibles o fuera del control de GFMemories,
        como: (a) piratería, ciberataques, daño o pérdida de datos (cuando hayamos
        adoptado las medidas que adoptarían razonablemente las empresas como la
        nuestra para evitar que ocurriese un suceso tal); o (b) hechos naturales,
        guerra, terrorismo, amenazas de bomba, disturbios, embargos, actos de
        autoridades civiles o militares, incendios, inundaciones, accidentes,
        huelgas, problemas de transporte, escasez de combustible, energía, mano de
        obra o materiales.
      </p>
      <p>
        9.5. <u>¿Significan estos Términos que somos socios</u>? (No, jurídicamente
        no).
      </p>
      <p>
        Usted acepta que no hay una relación de joint venture, asociación, relación
        de empleo o representación entre usted y GFMemories como resultado de estos
        Términos o su uso de los Servicios de GFMemories
      </p>
      <p>
        9.6. <u>Si incumplo estos Términos y</u>GFMemories<u>no hace nada por un
          largo periodo de tiempo, ¿significa que</u>GFMemories
        <u>
          ya no me reclamará?
        </u>
        (No. Nos reservamos todos los derechos).
      </p>
      <p>
        El hecho de que GFMemories no haga cumplir cualquier estipulación de los
        presentes Términos en ningún modo debe interpretarse como una renuncia
        presente o futura de tal estipulación, ni afectará en modo alguno al derecho
        de cualquiera de las partes de hacer cumplir todas y cada una de tales
        disposiciones a partir de entonces. La renuncia por parte de GFMemories de
        cualquier disposición, condición o requisito de los presentes Términos no
        constituirá una renuncia a una futura obligación de cumplir con tal
        disposición, condición o requisito.
      </p>
      <p>
        9.7. ¿<u>Cuáles son los recursos de que dispone</u>GFMemories
        <u>
          en caso de incumplimiento de estos Términos?
        </u>
      </p>
      <p>
        Usted acuerda que GFMemories sufrirá un daño irreparable si no se cumplen
        específicamente algunos de los Términos (como la Cláusula 3.2), y que, como consecuencia, los daños y perjuicios
        no serían un recurso
        adecuado para GFMemories Podemos basarnos en esta Cláusula para pedir a un
        juzgado un desagravio por mandato judicial o un cumplimiento específico.
      </p>
      <p>
        9.8. ¿ <u>Cuál es la duración de este acuerdo?</u> (Durante el tiempo que
        usted use los Servicios de GFMemories o hasta que su cuenta se cancele.).
      </p>
      <p>
        Estos Términos entran en vigor en la fecha de su primera aceptación y serán
        válidos durante el tiempo que usted use los Servicios de GFMemories o que
        mantenga una cuenta en estos. <strong>Sin</strong>
        <strong>
          embargo, las cláusulas correspondientes a los Bienes Recibidos y los
          Pagos Realizados por la obtención los correspondientes artículos
          digitales y su política de anti-rembolso serán permanentes
        </strong>
      </p>
      <p>
        <strong>
          RECONOCE Y ACEPTA QUE HA LEÍDO Y ENTENDIDO ESTOS TÉRMINOS, Y QUE AL
          SELECCIONAR EL BOTÓN "ACEPTAR" A CONTINUACIÓN, O AL USAR O ACCEDER A LOS
          SERVICIOS DE GFMemories, SE COMPROMETE A VINCULARSE POR LOS TÉRMINOS Y
          CONDICIONES ESTABLECIDOS EN LOS PRESENTES TÉRMINOS.
        </strong>
      </p>
    </div>
    <div v-if="$t('lang') == 'en'" class="w-7 pt-7">
      <p><b>Purpose and Nature of the Server</b></p>
      <p><strong>GFMemories is an independent and unofficial Grand Fantasia private server.
          We want to clarify that we are not affiliated, endorsed or associated in any way
          with the official company that develops or manages Grand Fantasia/X-Legends.</strong></p>
      <p>
        Our server has been created with the purpose of offering a unique and
        personalized service experience for our users. We understand that we are
        a separate and private entity, and we do not intend to impersonate or usurp
        the identity of the company that created the service.
      </p>
      <p>
        These terms of service (hereinafter referred to as the
        "<strong>Terms</strong>").
        set out the terms and conditions under which GFMemories offers
        you
        access to the use and enjoyment of our game, websites and other
        services
        (hereinafter, the "<strong>Services of
        </strong><strong>GFMemories</strong>").
        These Terms are a contract between you and this entity.
      </p>
      <p>

        By accessing and using the GFMemories services, you agree and acknowledge that it is an unofficial private
        server. We encourage you to carefully review these terms and conditions before continuing to use our services:
      </p>
      <ul>
        <li>
          <strong>Virtual Content</strong>. By clicking to buy, earn or
          to receive free Virtual Content, you only get a license for
          access to the Virtual Content. You do not have any ownership of
          the
          Virtual content that you unlock, and you cannot transfer it to
          anyone else.
          Virtual Content does not have a monetary value, it is generally
          specific
          and cannot be redeemed for any type of "world" money.
          real".
        </li>
        <li>
          <strong>Refund policy</strong>. You lose the right to change
          your
          order, cancel the order and get a refund if you gain access to
          content immediately to digital content or download it.
        </li>
      </ul>
      <ul type="disc">
        <li>
          <strong>User Rules</strong>. You agree to follow our User Rules
          user.
          These rules govern both the behavior in the game and certain
          out-of-game behaviors when using GFMemories Services
        </li>
      </ul>
      <h4>1 . YOUR ACCOUNT</h4>
      <p>
        <strong>1.1. Can I create an account and use the
          <u>Services</u></strong>?
        GFMemories <strong><u>?</u></strong>.
        <strong>
          (You must be of legal age or have the permission of your
          parents or guardian.
          legal).
        </strong>
      </p>
      <p>
        You will need a GFMemories account to access many of our
        services. To create an account and use the GFMemories Services
        you need to
        (i) be of legal age; (ii) be an emancipated minor; or (iii) have
        the
        valid consent of his or her parent or legal guardian to be bound
        according to
        these Terms. If you are not of legal age, an emancipated minor or
        do not understand these Terms.
        If you are a parent or legal guardian, talk to your parents or
        legal guardian and ask for help. If you are the parent,
        parent or legal guardian of a minor who creates an account with
        us, you and the minor's
        minor accept and agree to be bound by these Terms. It is also
        responsible for all account usage and compliance with these
        Terms.
        by the minor, including all purchases made on the account. No
        you may not create or use an account, or use any of the Services,
        or use any of the
        GFMemories on behalf of any other person, or any other person, or
        any person
        legal, nor for commercial purposes.
      </p>
      <p>
        <strong>
          1.2. <u>How can I create an account?</u> (On our website).
        </strong>
      </p>
      <p>
        You can create an account with unique login credentials at
        our websites.
      </p>
      <p>
        <strong>
          1.3. <u>DoI have to provide my name and other real
            information?</u> (Yes.
          Please do not include names for fun).
        </strong>
      </p>
      <p>
        You must always provide accurate and complete information,
        including your name.
        real.
      </p>
      <p>
        <strong>
          1.4. ¿
          <u>
            Can I share or sell my account or my login information?
            session?
          </u>
          (No).
        </strong>
      </p>
      <p>
        When creating an account, you will be prompted to select a
        username and password.
        unique password (collectively, the
        "<strong>LoginInformation</strong>").
        Agrees that:
      </p>
      <ul>
        <li>
          You may not share your account or login information with
          no one.
        </li>
        <li>
          You may not sell or transfer your account or your Account Login
          Information to another person.
          session, nor allow anyone else to access them. Nor can you
          to offer to do so.
        </li>
        <li>
          You have to keep your login information secret.
        </li>
      </ul>
      <p>
        You should notify us immediately if you become aware of any of
        the following
        any breach of security, including unauthorized access to the
        loss, theft or unauthorized disclosure of your account, or the
        loss, theft or unauthorized disclosure of your
        Login information or payment information, in order for you to
        we may take appropriate action. You are responsible for all
        losses (including loss or use of virtual Content) in your account
        when you have shared your Login Information or have not shared
        your
        kept the account or Login Information protected.
      </p>
      <h4>
        2. ACCOUNT CANCELLATION
      </h4>
      <p>
        <strong>
          2.1. How can my account be suspended or terminated?
        </strong>
      </p>
      <p>
        2.1.1. <strong>You</strong>. You may cancel or suspend your
        account at
        at any time. To do so, please contact us at
        from the e-mail address gfmemorieswork@gmail.com.
      </p>
      <p>
        2.1.2. <strong>We</strong>. We may terminate or suspend your
        account without
        notice if, acting reasonably, we conclude that:
      </p>
      <ol>
        <li>
          You have breached any part of these Terms (including the
          Rules).
          of the user).
        </li>
        <li>
          We act in the best interest of our community, the GFMemories or
          is it
          necessary to protect the rights of third parties.
        </li>
        <li>
          We no longer offer GFMemories in your region (although usually
          we publish an advance notice on our website, application or
          if we plan to stop offering a fundamental feature of a game if
          we plan to stop offering a fundamental feature of a game if we plan to
          stop offering a fundamental feature of a
          game or all GFMemories in your region).
        </li>
        <li>
          You do not pay any commission due to us (except in case of
          negligence).
          serious or premeditated misconduct) or we must reimburse the
          someone due to unauthorized use of a payment made from your
          account.
        </li>
        <li>
          Has done (or we have reasonable grounds to suspect that he has
          done)
          unauthorized use of someone else's payment information.
        </li>
      </ol>
      <p>
        We can arrive at such conclusions with the help of automated
        systems or
        machine-learning tools, or by other methods that can be used to
        we deem appropriate. If you believe we have made a mistake,
        please contact us at
        contact us with the details and we will review your case,
        although we can
        suspend your account during the review. You can refute our
        conclusions.
      </p>
      <h4>
        3. VIRTUAL GOODS, GAME CURRENCY AND SHOPPING
      </h4>
      <p>
        <strong>
          3.1. <u>What is Virtual Content?</u> (Virtual Content includes
          things like in-game currency and virtual goods, such as
          champions,
          aspects, emoticons, accessories, etc.).
        </strong>
      </p>
      <p>
        By using GFMemories, we will be able to provide you with the
        opportunity to purchase a
        limited license to access virtual goods, such as champions,
        aspects,
        emoticons, etc., ("<strong>virtual goods</strong>") and in-game
        currency
        ("<strong>Game currency</strong>"), associated with your account
        (on your
        set, the "<strong>Virtual Content</strong>").
      </p>
      <p>
        The Game Currency may only be used for the specific game for
        which it is used.
        has been purchased, unless otherwise indicated by us at the time
        of purchase, unless otherwise indicated by us at the time of purchase.
        purchase.
      </p>
      <p>
        <strong>
          3.2. ¿
          <u>
            How do I get a "license" to access the Virtual Content?
          </u>
          (Buy it, win it or receive it).
        </strong>
      </p>
      <p>
        We can provide you with several opportunities to acquire a
        license
        limited access to in-game currency or virtual content, among
        other things.
        others:
      </p>
      <ol>
        <li>
          purchase it (e.g., with a credit card);
        </li>
        <li>
          earning it (e.g. by completing a mission or tasks in the game);
        </li>
        <li>
          receive it (e.g. from other players as a gift).
        </li>
      </ol>
      <p>
        <strong>
          3.3. <u>DoI "own" the virtual Content I have unlocked?</u> .
        </strong>
      </p>
      <p>
        It is not the owner nor does it have any other ownership rights
        over the
        Virtual content that unlocks, regardless of whether you have
        acquired the
        access to it. Virtual Content has no monetary value. It cannot
        transfer or exchange Virtual Content for any type of "world"
        money.
        real".
        <strong>
          You cannot obtain any refund for the purchase of a license for
          access the Virtual Content, unless we have instructed you to do
          so.
          specifically
        </strong>
        .
      </p>
      <p>
        <strong>
          3.4. <u>So I am not the owner of my virtual Content?</u> ).
        </strong>
      </p>
      <p>
        When you get Virtual Content, what we actually give you is an
        entitlement.
        and personal, non-exclusive, non-transferable, non-sublicensable
        license,
        revocable and limited to use such virtual Content solely in
        connection with
        with its corresponding use of GFMemories.
      </p>
      <p>
        <strong>
          Notwithstanding anything to the contrary in these Terms, you
          shall
          acknowledges and agrees that it shall not have any ownership
          rights or any other rights
          other ownership rights in your account, and that all rights of
          ownership in your
          your account are and will always be owned by and for the
          benefit of
        </strong>
        <strong>GFMemories</strong>. You further
        acknowledge and agree that you do not
        has any title, ownership or other proprietary rights in the
        Virtual content, irrespective of any consideration provided
        or paid in return. In addition, except in the case of
        premeditated misconduct
        or gross negligence, or to the extent that these Terms indicate
        otherwise,<strong>GFMemories</strong>s
        hallnot be held responsible for
        in any way by the destruction, elimination, modification,
        alteration, modification, modification or
        deterioration, piracy or any other damage or loss of any kind
        caused to the Virtual Content, including removal of the Virtual
        Content
        upon cancellation or expiration of your account or our changes
        to it
        justified to the<strong>GFMemories</strong>.
      </p>
      <p>
        <strong>
          3.5. Will my Virtual Content always be available (No)?
          necessarily).
        </strong>
      </p>
      <p>
        In an effort to constantly improve GFMemories and evolve the
        GFMemories
        our games and keep GFMemories safe, fun and secure,
        we have the right to delete, alter, move, eliminate, regroup,
        revalue or transfer any Game Content, including Game Content, or
        at any time, in whole or in part, with or without cause, or
        without
        prior notice, and without any liability whatsoever to you. By
        For example, your virtual goods may evolve (and are likely to do
        so).
        to improve GFMemories over time or for regulatory or regulatory
        reasons.
        legal. If we decide to completely remove certain virtual Assets
        that
        If you have previously purchased, we will provide you with a
        replacement. We will not dispose of
        your Game Currency without prior notice (either through
        publications in
        our website, application, or game), unless we terminate your
        account by
        a valid reason or you do so pursuant to Clause 2.1.1
        . Occasionally, we can change the purchasing power of the
        game (e.g., we can increase the amount of the game currency).
        necessary to purchase virtual goods (e.g., aspects). In general,
        we only do this gradually, but we will let you know (either
        through
        publications on our web site, application or game) if we plan to
        changes that significantly affect your game's Currency of the
        negative way. We make no warranties, and exclude any and all
        warranties of any kind.
        liability in respect of any securities, cash or otherwise,
        attributed to the information contained on the servers that we
        use or
        we control, including all Game Content or Virtual Content
        attributed to your account. If we permit the sale or transfer of
        your rights
        to access certain Game Content or Virtual Content, it will only
        be possible to
        through services approved or provided by us,
        if any.
      </p>
      <h4>
        4. FEES AND TAXES
      </h4>
      <p>
        Is everything related to the GFMemories Services free of charge?
      </p>
      <p>
        Some issues of the GFMemories Services may require the payment of
        a fee, and you agree to provide us or the third-party providers
        with
        payment services that we use accurate and complete payment
        information.
        In addition, you agree to pay all applicable fees and taxes
        incurred by
        your account. We can check the prices of any part of GFMemories.
        at any time. This may affect the purchasing power of the
        Currency.
        of the game, although, in general, we only do this gradually. All
        applicable fees and taxes shall be payable in accordance with the
        applicable
        payment terms in effect at the time of maturity. We reserve,
        the right to modify, amend or supplement the rates and fees of
        the
        billing methods. Such changes will take effect immediately upon
        their
        publication in these Terms or elsewhere on our websites,
        applications or games. Except in cases of gross negligence or
        misconduct
        inappropriate premeditated, if you do not pay any fees due or if
        we have to
        reimburse someone due to the unauthorized use of a payment made
        from his or her
        account, we may suspend or terminate your account. For more
        information
        information, see Clause 2
        (Cancellation of the account).
      </p>
      <p>
        <strong>
          <u>Subject to applicable law, applicable fees and other
            charges.
            for tariffed services (including the game currency) are paid
            for by
            are not refundable in full or in part,
            except as expressly stated in these Terms. Except
            in cases of gross negligence or premeditated misconduct of
            part of</u>
        </strong>
        GFMemories
        <strong>
          <u>, or to the extent these Terms indicate otherwise, you shall
            you are responsible for all charges to your account,
            including charges that are not
            authorized.</u>
        </strong>
      </p>
      <p>
        4.2. ¿
        <u>
          What are my responsibilities in relation to the game currency?
        </u>
        (Pay the corresponding taxes; verify that the transactions
        with us are correct, etc.).
      </p>
      <p>
        You are solely responsible for the payment of the taxes due on
        the
        acquisition of the Game Currency, its use or access. We may sell
        or issue
        the game currency in packages, and the price may vary depending
        on the
        quantity to be purchased and the place where the purchase is
        made. To our
        discretion and if we believe it is necessary (or if required by
        law),
        we may limit the total amount of game currency that can be
        purchased to
        at the same time for any individual game or that may accumulate
        in total in its
        account. In addition, the price and availability of the Game
        Currency and the
        Virtual goods are subject to change without notice. We can
        restrict the amount of Game Currency you can buy or use in your
        game.
        location, since there are different approaches according to the
        different
        locations.
      </p>
      <p>
        You should verify that the proper amount of
        Game currency in your account during any transaction. Let us know
        immediately if you believe there is an error in your game
        currency balance.
        GFMemories will investigate your complaint and, in doing so, may
        ask you to
        additional information for verification.
      </p>
      <h4>
        5. USER RULES
      </h4>
      <p>
        5.1. ¿
        <u>
          I can provoke, attack, threaten, or harass people while using
          GFMemories? Services
        </u>
        (No. If you do, we may take action, such as suspending your
        account).
      </p>
      <p>
        When using the GFMemories Services, you must comply with all
        laws,
        rules and regulations applicable in your place of residence. In
        addition, you must
        comply with the policies on acceptable uses and behaviors that
        we publish on a timely basis on our websites, applications and
        games, and
        the rules of behavior listed below (as a whole),
        the "<strong>User Rules</strong>"). User Rules
        published on our web sites, or stated in this Clause, are not
        are intended to be exhaustive, and we reserve the right to modify
        them at any time.
        The Company may at any time, as well as take the appropriate
        disciplinary measures,
        which include temporary blocking, suspension or cancellation, and
        account deletion, in order to protect the integrity and spirit of
        the
        GFMemories, regardless of whether a particular behavior is
        included or not.
        not in the User Rules as inappropriate.
      </p>
      <p>
        The following are examples of behaviors that will lead to the
        adoption of
        disciplinary measures: <br />
        <br />
        - Insulting the server staff will be sanctioned.
      </p>
      <p>
        - Impersonating a member of the Staff will be sanctioned.
      </p>
      <p>
        - It is forbidden to buy and sell gambling items and gold for
        money.
        real
      </p>
      <p>
        - The purchase and sale of accounts regardless of the means of
        payment will be sanctioned.
        payment (accounts that were stolen for sales will not be
        recovered)
      </p>
      <p>
        - The use of inappropriate language in the game will be
        sanctioned.
        as in the server networks
      </p>
      <p>
        - The use of names of players or guilds which are
        offensive
      </p>
      <p>
        - The use of any inappropriate language will be sanctioned, in
        the following cases
        this server will not accept any type of discrimination on the
        basis of gender.
        religious beliefs, age, gender, sexual orientation, disability,
        race or ethnicity.
        ethnicity
      </p>
      <p>
        - Harassment of other players will be sanctioned regardless of
        the type of player.
        the form and the reason for the
      </p>
      <p>
        - Interrupting an event scheduled by the Staff will be
        sanctioned.
      </p>
      <p>
        - Abusing bugs or intentionally provoking bugs in order to
        make a profit
      </p>
      <p>
        - The manipulation of files within the game will be sanctioned if
        gain an advantage in gameplay
      </p>
      <p>
        - It will be a reason for sanction and/or permanent banning for
        people who use
        multi-accounts for quests, dungs and gold gathering.
      </p>
      <p>
        - Anti-gaming in the arenas (Failure to help your own
        team to meet your guild, your friend or your partner on the other
        side)
      </p>
      <p>
        - It will be sanctioned the use of healing, deaths or the use of
        any method to gain access to better positions in the ranking or
        to
        benefit from obtaining the PVP/ GW sets first.
      </p>
      <p>
        - It will be cause for sanction to voluntarily leave the
        sands
      </p>
      <p>
        - The use of hacks or cheats that seek to take advantage of the
        following will be penalized
        an in-game advantage against the community
      </p>
      <p>
        It is allowed to kill as many times as they want to a person who
        is inside
        of the PVP channel, but will be sanctioned if it is demonstrated
        that there is a
        subsequent pursuit in the different NON PVP channels
      </p>
      <h4>
        6. TRACKING AND ANTI-CHEATING SYSTEMS
      </h4>
      <p>
        6.1. WillI be monitored by GFMemories while using the GFMemories
        Services?
        <u>?</u> (Yes. See the Privacy Notice for more information).
      </p>
      <p>
        We may actively track usage of the GFMemories Services.
        (although we are under no obligation to do so), both in our own
        servers as well as on your computer, for various purposes,
        including the
        prevention of cheating, elimination or reduction of behavior
        disruptive players and the enhancement of GFMemories Services.
      </p>
      <p>
        6.2.
        <u>
          Does GFMemories run programs on my device when I'm not using
          the
          GFMemories? services
        </u>
        (We can do this for limited anti-cheating purposes).
      </p>
      <p>
        To avoid cheating and piracy, we can require you to install
        anti-cheating software. This software can run in the background
        in your
        device.
      </p>
      <h4>
        7. UPDATES AND MODIFICATIONS
      </h4>
      <p>
        7.1. Will these Terms change in the future? (Yes).
      </p>
      <p>
        We can (and probably will) create as many upgraded versions of
        these Terms in the future as well as the GFMemories Services and
        the
        legislation in force require it. When we do so, we will inform
        you of the
        new agreement that will supersede and replace these Terms. You
        will be given the
        opportunity to review any new agreement that we present to you,
        and you will
        you will be able to decide whether you wish to accept the revised
        terms. If you accept the new
        agreement, you may continue to use the GFMemories services.
        new agreement, you will no longer be able to use the GFMemories
        Services.
      </p>
      <p>
        7.2. Will the GFMemories Services be maintained?
        <u>
          unchanged?
        </u>
        (No. The classes will evolve).
      </p>
      <p>
        In order to constantly improve the GFMemories Services,
        GFMemories will do the following
        evolve our games and keep the games safe, fun and secure.
        GFMemories services, you agree that we may change, modify,
        update,
        suspend, disable, or restrict access to any of the functions or
        features of the
        parts of the GFMemories Services, including the Virtual Goods
        (for example, by
        For example, we can change some functions of the virtual goods by
        regulatory or legal reasons, or to improve the gaming
        experience),
        and that it may be necessary for you to download and install
        software and
        upgrades of any software required by the Services.
        GFMemories, at any time, without liability to you. Also
        understand and agree that any such changes or updates to the
        Services may be made to the
        GFMemories may modify the system specifications necessary to
        play our games and, in such a case, it will be you, and not
        GFMemories, who will play our games.
        shall be responsible for acquiring any additional software or
        hardware in order to access and play our game. It also
        understands and
        accepts that we can use patches in the background to upgrade
        automatically game and software with or without notice.
      </p>
      <h4>
        8. ABSENCE OF GUARANTEES
      </h4>
      <p>
        8.1. Does GFMemories GFMemories give any warranty in
        connection with the
        GFMemories Services?
      </p>
      <p>
        <strong>
          <u>Assumes all responsibility with respect to the installation
            and use
            of the Services</u>.
        </strong>
        GFMemories
        <strong>
          <u>, as well as the results obtained (independently of where
            is found)</u>.
        </strong>
      </p>
      <p>
        In addition, if you are in
        <strong>
          North, Central or South America
        </strong>
        :
      </p>
      <p>
        <strong>
          <u>The GFMemories Services are provided on an "as is" and
            "subject to
            availability" without warranties or representations of any
            kind, whether or not they are
            express or implied. Assumes all liability with respect to the
            use
            of the Services</u>.
        </strong>
        <strong><u>GFMemories</u></strong><strong><u>. To the extent
            permitted by the
            legislation
            in
            force,</u></strong><strong><u>GFMemories</u></strong><strong><strong>
          </strong><strong><u>GFMemories</u></strong></strong><strong><u>do
            notu></u></strong><strong><u>GFMemories</u></strong><strong><u>no.
            assumes no warranties, express or implied, that may apply to
            the products.

            Services</u></strong><strong><u>GFMemories</u></strong><strong><u>include
            ,
            including, but not limited to, the implied warranties of
            title, non-infringement, fitness
            for commerce, fitness for a particular purpose and any other
            guarantees that may arise in the course of trade, execution
            or commerce, as well as
            as any warranties as to the accuracy, reliability, or quality
            of any of the
            content or information included in the Services</u></strong>.
        <strong><u>GFMemories</u></strong> .
      </p>
      <h4>
        9. MISCELLANEOUS
      </h4>
      <p>
        9.1. <u>What can I do if I have questions about the Services?
          de</u>GFMemories? .
      </p>
      <p>
        If you have any questions about GFMemories Services, or if you
        would like to contact GFMemories, please contact us.
        for any other reason, please write to us at
        gfmemorieswork@gmail.com.
      </p>
      <p>
        9.2. <u>Can either party assign these Terms</u>? (No. Only
        us).
      </p>
      <p>
        GFMemories may assign the rights under these Terms, at
        in whole or in part, to any natural or juridical person, in any
        form, in
        time, either with or without your consent. You may not transfer
        your
        rights under these Terms without the prior written consent of the
        in writing from GFMemories. Any unauthorized transfer shall be
        null and void.
      </p>
      <p>
        9.3. <u>Is this document the entire agreement between the
          parties</u> (Yes,
        including those other important policies mentioned above
        previously).
      </p>
      <p>
        These Terms represent the entire agreement between you and
        GFMemories with
        with respect to the GFMemories Services, and supersede any other
        prior or concurrent agreements between you and GFMemories. These
        Terms
        coexist with any other GFMemories policy to which it is made.
        reference in these Terms, and shall not supersede it. You and
        GFMemories agree that they have not relied and have no recourse
        with
        with respect to any of the terms, conditions, representations,
        warranties, representations, warranties or
        similar that are not expressly set forth in these Terms.
      </p>
      <p>
        9.4. <u>What happens if a natural disaster interferes with the
          game</u>?
        (GFMemories shall not be liable).
      </p>
      <p>
        GFMemories shall have no liability whatsoever for any delay or
        failure of
        enforcement, including judgments of enforcement under these Terms
        and Conditions.
        due to unforeseeable circumstances or circumstances beyond the
        control of GFMemories,
        (a) hacking, cyber-attacks, damage to or loss of data (where we
        have
        the measures that would reasonably be adopted by companies such
        as the
        (a) our ability to prevent such an event from occurring); or (b)
        natural occurrences,
        war, terrorism, bomb threats, riots, embargoes, acts of
        terrorism, acts of
        civil or military authorities, fires, floods, accidents,
        strikes, transportation problems, fuel shortages, energy, labor
        shortages, and
        work or materials.
      </p>
      <p>
        9.5. <u>Do these Terms mean that we are partners</u>? (No,
        legally
        no).
      </p>
      <p>
        You agree that there is no joint venture, partnership, joint
        venture relationship, joint venture relationship, joint venture
        relationship, joint venture relationship, joint venture relationship,
        joint venture relationship
        of employment or representation between you and GFMemories as a
        result of these
        Terms or your use of GFMemories Services
      </p>
      <p>
        9.6. IfI breach these Terms and GFMemories does nothing
        by a
        long period of time, does it mean that GFMemories
        <u>
          will no longer claim me?
        </u>
        (No. We reserve all rights).
      </p>
      <p>
        GFMemories' failure to enforce any provision of the terms of this
        Agreement shall be deemed to be a breach of this Agreement.
        these Terms shall in no way be construed to be a waiver of any of
        the
        present or future of such a stipulation, nor shall it affect in
        any way the right
        of either party to enforce any and all such obligations.
        provisions thereafter. The waiver by GFMemories of
        any provision, condition, or requirement of these Terms shall not
        apply to the
        constitute a waiver of a future obligation to comply with such a
        waiver.
        provision, condition or requirement.
      </p>
      <p>
        What are the resources available to GFMemories?
        <u>
          in the event of a breach of these Terms?
        </u>
      </p>
      <p>
        You agree that GFMemories will suffer irreparable harm if you
        fail to comply with
        specifically some of the Terms (such as Clause 3.2), and that, as
        a consequence, the damages
        would not be a resource
        suitable for GFMemories We may rely on this Clause to require a
        judged injunctive relief or specific performance.
      </p>
      <p>
        9.8. What is the duration of this agreement?
        you use the GFMemories Services or until your account is
        terminated.
      </p>
      <p>
        These Terms are effective as of the date of first acceptance and
        shall be
        valid for as long as you use the GFMemories Services or as long
        as you use the GFMemories Services.
        keep an account on these. <strong>Without</strong>.
        <strong>
          However, the clauses corresponding to the Received Assets and
          the
          Payments made for obtaining the corresponding items
          and its anti-refunding policy will be permanent.
        </strong>
      </p>
      <p>
        <strong>
          ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTOOD THESE
          TERMS, AND THAT BY
          SELECT THE "ACCEPT" BUTTON BELOW, OR WHEN USING OR ACCESSING
          THE
          GFMemories SERVICES, UNDERTAKES TO BIND ITSELF BY THE TERMS AND
          CONDITIONS OF
          CONDITIONS SET FORTH IN THESE TERMS.
        </strong>
      </p>
    </div>
    <div v-if="$t('lang') == 'fr'" class="w-7 pt-7">
      <p><b>Objectif et nature du serveur</b></p>
      <p><strong>GFMemories est un serveur privé indépendant et non officiel
          de Grand Fantasia. Nous tenons à préciser que nous ne sommes en aucun
          cas affiliés, approuvés ou associés de quelque manière que ce soit à
          la société officielle qui développe ou gère Grand Fantasia/X-Legends.</strong></p>
      
      <p>Notre serveur a été créé dans le but d'offrir une expérience de
        service unique et personnalisée à nos utilisateurs. Nous comprenons
        que nous sommes une entité distincte et privée et nous n'avons
        pas l'intention de usurper l'identité ou d'usurper l'identité
        de la société qui a créé le service.</p>
        <p>
      Les présentes conditions de service (ci-après, les
      "<strong>Conditions</strong>")
      énoncer les termes et conditions selon lesquels GFMemories vous
      offre
      l'accès à l'utilisation et à la jouissance de notre jeu, de nos
      sites web et de nos autres services
      (ci-après dénommés les "<strong>Services
        GFMemories</strong>").
      Les présentes conditions constituent un contrat entre vous et
      cette entité.
      </p>
      <p>
        En accédant et en utilisant les services GFMemories, vous acceptez
        et reconnaissez qu'il s'agit d'un serveur privé non officiel. Nous
        vous encourageons à lire attentivement ces termes et conditions
        avant de continuer à utiliser nos services:
      </p>
      <ul>
        <li>
          <strong>Contenu virtuel</strong>. En cliquant pour acheter,
          gagner ou
          recevoir du contenu virtuel en cadeau, vous n'obtenez qu'une
          licence pour le contenu virtuel.
          d'accéder au contenu virtuel. Il n'est pas propriétaire des
          Contenu virtuel que vous déverrouillez et que vous ne pouvez
          pas transférer à quelqu'un d'autre.
          Le contenu virtuel n'a pas de valeur monétaire, il est
          généralement spécifique.
          et n'est pas échangeable contre de l'argent "dans le jeu".
          réel".
        </li>
        <li>
          <strong>Politique de remboursement</strong>. Vous perdez le
          droit de modifier votre
          annuler la commande et se faire rembourser si l'accès est
          possible.
          immédiatement en contenu numérique ou le télécharger.
        </li>
      </ul>
      <ul type="disc">
        <li>
          <strong>Règles d'utilisation</strong>. Vous acceptez de suivre
          nos règles d'utilisation
          l'utilisateur.
          Ces règles régissent à la fois le comportement dans le jeu et
          certains aspects de la vie quotidienne.
          comportement hors jeu lors de l'utilisation des services
          GFMemories
        </li>
      </ul>
      <h4>1 . votre compte</h4>
      <p>
        1.1 Puis-je créer un compte et utiliser les <strong>Services</strong> ?
        GFMemories <strong><u>?</u></strong>.
        <strong>L'Union européenne
          (Vous devez être majeur ou avoir l'autorisation de vos parents
          ou de votre tuteur.
          juridique).
        </strong>
      </p>
      <p>
        Vous aurez besoin d'un compte GFMemories pour accéder à un grand
        nombre de nos services.
        services. Pour créer un compte et utiliser les services
        GFMemories, vous devez
        (i) être majeur ; (ii) être un mineur émancipé ; ou (iii) être un
        mineur majeur.
        le consentement valable de ses parents ou de son tuteur légal à
        être lié conformément à la loi sur les droits de l'homme et à la loi sur
        les droits de l'homme.
        les présentes conditions. Si vous n'avez pas l'âge légal, si vous
        êtes un mineur émancipé ou si vous ne comprenez pas les présentes
        conditions.
        Si vous êtes un parent ou un tuteur légal, parlez à vos parents
        ou à votre tuteur légal et demandez de l'aide. Si vous êtes le parent,
        parent ou tuteur légal d'un mineur qui crée un compte auprès de
        nous, vous et le parent ou tuteur légal de l'enfant
        Les mineurs acceptent et conviennent d'être liés par ces
        conditions. Il est également
        responsable de toute utilisation du compte et du respect des
        présentes conditions
        par l'enfant, y compris tous les achats effectués sur le compte.
        Non
        vous ne pouvez pas créer ou utiliser un compte, ni utiliser l'un
        quelconque des services ou l'un quelconque des éléments suivants
        GFMemories au nom de toute autre personne, ou de toute autre
        personne
        juridique, ni à des fins commerciales.
      </p>
      <p>
        <strong>L'Union européenne
          1.2. Comment puis-je créer un compte (sur notre site web) ?
        </strong>
      </p>
      <p>
        Vous pouvez créer un compte avec des identifiants de connexion
        uniques à l'adresse suivante
        nos sites web.
      </p>
      <p>
        <strong>L'Union européenne
          1.3 <u>Dois-je fournir mon vrai nom et d'autres informations ?
          </u> (Oui, je dois le faire).
          N'incluez pas de noms pour le plaisir).
        </strong>
      </p>
      <p>
        Vous devez toujours fournir des informations exactes et
        complètes, y compris votre nom
        réel.
      </p>
      <p>
        <strong>L'Union européenne
          1.4. ¿
          <u>
            Puis-je partager ou vendre mon compte ou mes informations de
            connexion ?
            session ?
          </u>
          (Non).
        </strong>
      </p>
      <p>
        Lorsque vous créez un compte, il vous est demandé de choisir un
        nom d'utilisateur et un mot de passe.
        un mot de passe unique (collectivement, les "<strong>informations
          de connexion</strong>").
        Est d'accord sur ce point :
      </p>
      <ul>
        <li>
          Vous ne pouvez pas partager votre compte et vos informations de
          connexion avec
          personne.
        </li>
        <li>
          Vous ne pouvez pas vendre ou transférer votre compte ou vos
          informations de connexion au compte à une autre personne.
          et n'autoriser personne d'autre à y accéder. Vous ne pouvez pas
          non plus
          proposer de le faire.
        </li>
        <li>
          Vous devez garder vos informations de connexion secrètes.
        </li>
      </ul>
      <p>
        Vous devez nous informer immédiatement si vous avez connaissance
        de l'un des éléments suivants
        toute violation de la sécurité, y compris l'accès non autorisé
        aux
        compte, ou la perte, le vol ou la divulgation non autorisée de
        votre
        Informations de connexion ou de paiement, afin que vous puissiez
        nous pourrons prendre les mesures qui s'imposent. Vous êtes
        responsable de toutes les
        les pertes (y compris la perte ou l'utilisation du contenu
        virtuel) sur votre compte
        lorsque vous avez partagé vos informations de connexion ou que
        vous n'avez pas partagé vos informations de connexion.
        maintenir la protection du compte ou des informations de
        connexion.
      </p>
      <h4>
        2. L'ANNULATION DU COMPTE
      </h4>
      <p>
        <strong>L'Union européenne
          2.1 Comment mon compte peut-il être suspendu ou résilié ?
        </strong>
      </p>
      <p>
        2.1.1 <strong>Vous</strong>. Vous pouvez annuler ou suspendre
        votre compte à l'adresse suivante
        à tout moment. Pour ce faire, veuillez nous contacter à l'adresse
        suivante
        à l'adresse électronique gfmemorieswork@gmail.com.
      </p>
      <p>
        2.1.2. <strong>Nous</strong>. Nous pouvons résilier ou suspendre
        votre compte sans
        un avis préalable si, agissant raisonnablement, nous concluons
        que
      </p>
      <ol>
        <li>
          Vous avez enfreint une partie des présentes conditions (y
          compris le règlement) en
          de l'utilisateur).
        </li>
        <li>
          Nous agissons dans l'intérêt de notre communauté, du GFMemories
          ou du GFMemories ou encore du GFMemories.
          nécessaires à la protection des droits des tiers.
        </li>
        <li>
          Nous avons cessé de proposer GFMemories dans votre région (bien
          que nous ayons l'habitude de le faire).
          nous publions un avis préalable sur notre site internet, notre
          application ou notre site web.
          jeu si nous envisageons d'arrêter d'offrir une caractéristique
          fondamentale d'une
          ou toutes les mémoires GFM de votre région).
        </li>
        <li>
          ne paie pas les commissions qui nous sont dues (sauf en cas de
          négligence)
          faute grave ou préméditée) ou nous devons rembourser
          quelqu'un en raison de l'utilisation non autorisée d'un
          paiement effectué à partir de votre compte.
        </li>
        <li>
          a fait (ou nous avons des motifs raisonnables de soupçonner
          qu'il a fait)
          l'utilisation non autorisée des informations de paiement d'une
          autre personne.
        </li>
      </ol>
      <p>
        Nous pouvons parvenir à de telles conclusions à l'aide de
        systèmes automatisés ou d'outils d'aide à la décision.
        des outils d'apprentissage automatique ou d'autres méthodes qui
        nous le jugeons approprié. Si vous pensez que nous avons commis
        une erreur, veuillez nous contacter à l'adresse suivante
        contactez-nous avec les détails et nous examinerons votre cas.
        suspendre votre compte pendant l'examen. Vous pouvez réfuter
        notre
        conclusions.
      </p>
      <h4>
        3. LES BIENS VIRTUELS, LA MONNAIE DE JEU ET LES ACHATS
      </h4>
      <p>
        <strong>L'Union européenne
          3.1 <u>Qu'est-ce que le contenu virtuel?</u> (Le contenu
          virtuel comprend
          des choses comme la monnaie du jeu et les biens virtuels, tels
          que les champions,
          aspects, émoticônes, accessoires, etc.)
        </strong>
      </p>
      <p>
        En utilisant GFMemories, nous pourrons vous donner la possibilité
        d'acheter une
        une licence limitée pour accéder aux biens virtuels, tels que les
        champions, les aspects,
        émoticônes, etc. ("<strong>Biens virtuels</strong>") et monnaie
        de jeu
        ("<strong>Monnaie du jeu</strong>"), associée à votre compte
        (dans sa forme actuelle).
        ensemble, le "<strong>Contenu virtuel</strong>").
      </p>
      <p>
        La monnaie de jeu ne peut être utilisée que pour le jeu
        spécifique pour lequel elle est utilisée.
        a été achetée, sauf indication contraire au moment de l'achat.
        l'achat.
      </p>
      <p>
        <strong>L'Union européenne
          3.2. ¿
          <u>
            Comment obtenir une "licence" pour accéder au contenu virtuel
            ?
          </u>
          (Achetez-le, gagnez-le ou recevez-le).
        </strong>
      </p>
      <p>
        Nous vous proposons différentes possibilités d'acquérir une
        licence
        un accès limité à la monnaie du jeu ou au contenu virtuel, entre
        autres.
        d'autres :
      </p>
      <ol>
        <li>
          l'acheter (par exemple avec une carte de crédit) ;
        </li>
        <li>
          le gagner (par exemple en accomplissant une mission ou des
          tâches dans le jeu) ;
        </li>
        <li>
          le recevoir (par exemple, d'autres joueurs en guise de cadeau).
        </li>
      </ol>
      <p>
        <strong>L'Union européenne
          3.3 Suis-je "propriétaire" du contenu virtuel que j'ai
          déverrouillé ?
        </strong>
      </p>
      <p>
        Elle n'est pas propriétaire ni n'a d'autres droits de propriété
        sur les
        Contenu virtuel qui se déverrouille, que vous ayez ou non acheté
        l'application
        l'accès à ce contenu. Le contenu virtuel n'a pas de valeur
        monétaire. Il ne peut
        transférer ou échanger du contenu virtuel contre tout type de
        monnaie "mondiale".
        réel".
        <strong>L'Union européenne
          Vous ne pouvez pas obtenir de remboursement pour l'achat d'une
          licence pour
          l'accès au contenu virtuel, à moins que nous n'ayons indiqué
          que nous l'avons fait.
          en particulier
        </strong>
        .
      </p>
      <p>
        <strong>L'Union européenne
          3.4. <u>Je ne suis donc pas propriétaire de mon contenu
            virtuel?</u> ).
        </strong>
      </p>
      <p>
        Lorsque vous obtenez un contenu virtuel, nous vous donnons en
        fait un droit.
        et une licence personnelle, non exclusive, non transférable et ne
        pouvant faire l'objet d'une sous-licence,
        révocable et limité à l'utilisation de ce contenu virtuel
        uniquement dans le cadre de
        avec l'utilisation correspondante de GFMemories.
      </p>
      <p>
        <strong>L'Union européenne
          Nonobstant toute disposition contraire des présentes
          conditions, vous vous engagez à
          reconnaît et accepte qu'il ne dispose d'aucun droit de
          propriété ni d'aucun autre droit sur les
          Vous n'avez aucun droit de propriété sur votre compte et vous
          conservez tous les droits relatifs à votre compte.
          votre compte sont et seront toujours la propriété et au
          bénéfice de
        </strong>
        <strong>GFMemories</strong>. Vous reconnaissez
        et acceptez en outre que vous ne
        possède un titre, une propriété ou un autre droit de propriété
        sur les
        Contenu virtuel, indépendamment de toute contrepartie offerte
        ou payé en contrepartie. En outre, sauf en cas de faute
        intentionnelle
        ou de négligence grave, ou dans la mesure où les présentes
        conditions indiquent
        dans le cas contraire,
        <strong>GFMemories</strong>ne sera pas tenu
        pour responsable.
        de quelque manière que ce soit, par destruction, enlèvement,
        modification, altération, destruction, altération, altération,
        altération, altération, altération, altération, altération, altération,
        altération ou
        la détérioration, le piratage ou tout autre dommage ou perte de
        quelque nature que ce soit
        les dommages causés au contenu virtuel, y compris la
        suppression du contenu virtuel
        après l'annulation ou l'expiration de votre compte ou de nos
        modifications
        justifié à la<strong>GFMememories</strong>.
      </p>
      <p>
        <strong>L'Union européenne
          3.5 Mon contenu virtuel sera-t-il toujours disponible ?
          nécessairement).
        </strong>
      </p>
      <p>
        Dans le but d'améliorer constamment GFMemories et d'évoluer
        nos jeux et de garder GFMemories sûr, amusant et sécurisé,
        nous avons le droit d'effacer, de modifier, de déplacer, de
        supprimer, de regrouper,
        réévaluer ou transférer tout contenu du jeu, y compris le contenu
        du jeu
        à tout moment, en tout ou en partie, avec ou sans motif, ou
        sans préavis et sans aucune responsabilité à votre égard. Par
        Par exemple, leurs biens virtuels peuvent évoluer (et sont
        susceptibles de le faire).
        au fil du temps afin d'améliorer GFMemories ou pour des raisons
        réglementaires ou de sécurité.
        juridique. Si nous décidons de supprimer complètement certains
        biens virtuels qui
        Si vous avez déjà acheté un produit, nous vous le remplacerons.
        Nous n'éliminerons pas
        votre monnaie de jeu sans préavis (soit par le biais de
        publications dans
        notre site web, notre application ou notre jeu), à moins que nous
        ne résilions votre compte en
        une raison valable ou que vous le fassiez conformément à la
        clause 2.1.1
        . Occasionnellement, nous pouvons modifier le pouvoir d'achat de
        l'euro.
        jeu (par exemple, nous pouvons augmenter le montant de la monnaie
        du jeu).
        nécessaires à l'achat de biens virtuels, par exemple des
        aspects). En général,
        nous ne le faisons que progressivement, mais nous vous en
        informerons (soit par
        publications sur notre site web, notre application ou notre jeu)
        si nous prévoyons de
        apporter des changements qui affectent de manière significative
        la devise de votre jeu.
        de manière négative. Nous ne donnons aucune garantie et excluons
        toute forme de responsabilité.
        responsabilité en ce qui concerne les titres, les espèces ou
        autres,
        attribuées aux informations contenues dans les serveurs que nous
        utilisons ou
        que nous contrôlons, y compris tout contenu en jeu ou contenu
        virtuel
        attribués à votre compte. Si nous autorisons la vente ou le
        transfert de votre droit
        pour accéder à certains contenus de jeu ou contenus virtuels, il
        ne sera possible que de
        par le biais de services approuvés ou fournis par nous,
        le cas échéant.
      </p>
      <h4>
        4. DROITS ET TAXES
      </h4>
      <p>
        4.1 Tout ce qui concerne les services du GFMemories est gratuit ?
      </p>
      <p>
        Certains numéros des services GFMemories peuvent nécessiter le
        paiement de
        et vous acceptez de nous fournir, à nous ou aux fournisseurs
        tiers, les informations suivantes
        des services de paiement que nous utilisons des informations de
        paiement exactes et complètes.
        En outre, il s'engage à payer tous les frais et taxes applicables
        qu'il encourt
        votre compte. Nous pouvons vérifier les prix de n'importe quelle
        partie de GFMemories
        à tout moment. Cela peut affecter le pouvoir d'achat de la
        monnaie.
        du jeu, même si, en général, nous ne le faisons que
        progressivement. Tous les
        les redevances et taxes applicables sont payables conformément
        aux dispositions de la
        les conditions de paiement en vigueur au moment de l'échéance.
        Nous nous réservons,
        en temps utile, le droit de modifier, de corriger ou de compléter
        les taux et les
        les méthodes de facturation. Ces modifications prennent effet
        immédiatement après leur
        publication dans les présentes conditions ou ailleurs sur nos
        sites web,
        des applications ou des jeux. Sauf en cas de négligence grave ou
        de mauvaise conduite
        Si vous ne payez pas une redevance due ou si nous sommes obligés
        de payer une redevance, vous ne serez pas en mesure de payer la
        redevance.
        rembourser quelqu'un en raison de l'utilisation non autorisée
        d'un paiement effectué à partir de son
        nous pouvons suspendre ou résilier votre compte. Pour plus
        d'informations
        veuillez vous référer à la clause 2
        (Annulation du compte).
      </p>
      <p>
        <strong>L'Union européenne
          Sous réserve de la législation en vigueur, des droits
          applicables et d'autres frais
          pour les services tarifés (y compris Gaming Currency) sont
          payées par
          ne sont pas remboursables en tout ou en partie,
          à l'exception de ce qui est expressément indiqué dans les
          présentes conditions. A l'exception de
          en cas de négligence grave ou de faute intentionnelle de la
          part de
          partie de
        </strong>
        GFMemories
        <strong>L'Union européenne
          <u>, ou dans la mesure où les présentes conditions indiquent le
            contraire, vous vous engagez à
            est responsable de tous les frais portés à votre compte, y
            compris les frais qui ne sont pas portés à votre compte.
            autorisé.</u>
        </strong>
      </p>
      <p>
        4.2. ¿
        <u>
          Quelles sont mes responsabilités en ce qui concerne la monnaie
          du jeu ?
        </u>
        (payer les taxes correspondantes ; vérifier que les transactions
        avec nous sont corrects, etc.)
      </p>
      <p>
        Vous êtes seul responsable du paiement des taxes dues pour le
        l'acquisition, l'utilisation ou l'accès à la monnaie de jeu. Nous
        pouvons vendre ou émettre des
        la monnaie du jeu en paquets, et le prix peut varier en fonction
        de l'offre du jeu.
        la quantité à acheter et le lieu où l'achat est effectué. À notre
        à notre discrétion et si nous le jugeons nécessaire (ou si la loi
        l'exige),
        nous pouvons limiter le montant total de la monnaie de jeu qui
        peut être achetée à
        en même temps pour un jeu individuel ou qui peut être accumulé au
        total dans son système d'information.
        compte. En outre, le prix et la disponibilité de la Monnaie du
        Jeu et de la
        Les biens virtuels peuvent être modifiés sans préavis. Nous
        pouvons
        limiter la quantité de monnaie de jeu que vous pouvez acheter ou
        utiliser dans votre jeu.
        Il existe en effet différentes approches en fonction des
        différents lieux de travail de l'entreprise.
        des lieux.
      </p>
      <p>
        Vous devez vérifier que la quantité appropriée de
        Monnaie de jeu sur votre compte lors de n'importe quelle
        transaction. Faites-le nous savoir
        immédiatement si vous pensez qu'il y a une erreur dans votre
        solde de monnaie de jeu.
        Le GFMemories examinera votre plainte et, ce faisant, pourra vous
        demander de lui fournir les informations suivantes
        des informations complémentaires pour vérification.
      </p>
      <h4>
        5. RÈGLEMENT DE L'UTILISATEUR
      </h4>
      <p>
        5.1. ¿
        <u>
          Je peux provoquer, attaquer, menacer ou harceler des personnes
          pendant que j'utilise des téléphones portables.
          les services GFMemories ?
        </u>
        (Non. Si vous le faites, nous pouvons prendre des mesures, telles
        que la suspension de votre compte).
      </p>
      <p>
        En utilisant les services GFMemories, vous devez vous conformer à
        toutes les lois,
        les règles et réglementations applicables dans votre lieu de
        résidence. En outre, vous devez
        se conformer aux politiques relatives aux utilisations et
        comportements acceptables qui
        que nous publions en temps utile sur nos sites web, applications
        et jeux, et
        les règles de comportement énumérées ci-dessous (dans leur
        ensemble),
        les "<strong>Règles d'utilisation</strong>"). Les règles
        d'utilisation
        publiés sur nos sites web, ou énoncés dans la présente clause, ne
        sont pas
        se veulent exhaustives et nous nous réservons le droit de les
        modifier à tout moment.
        à tout moment, ainsi que de prendre les mesures disciplinaires
        appropriées,
        qui comprennent le blocage temporaire, la suspension ou
        l'annulation, et
        la suppression du compte, afin de protéger l'intégrité et
        l'esprit du programme.
        GFMemories, qu'un comportement particulier soit inclus ou non.
        qui ne sont pas considérées comme inappropriées dans les règles
        d'utilisation.
      </p>
      <p>
        Voici des exemples de comportements qui déclencheront l'adoption
        de
        mesures disciplinaires : <br /> <br />
        <br />
        - L'insulte au personnel du serveur sera sanctionnée.
      </p>
      <p>
        - L'usurpation de l'identité d'un membre du personnel sera
        sanctionnée.
      </p>
      <p>
        - L'achat et la vente d'articles de jeu et d'or contre de
        l'argent sont interdits.
        réel
      </p>
      <p>
        - L'achat et la vente de comptes, quel que soit le moyen de
        paiement, seront sanctionnés.
        le paiement (les comptes qui ont été volés par les ventes ne
        seront pas récupérés)
      </p>
      <p>
        - L'utilisation d'un langage inapproprié à la fois dans le jeu et
        dans le jeu sera sanctionnée.
        comme dans les réseaux de serveurs
      </p>
      <p>
        - L'utilisation de noms de joueurs ou de guildes qui sont
        offensif
      </p>
      <p>
        - L'utilisation d'un langage inapproprié sera sanctionnée dans
        les cas suivants
        ce serveur n'acceptera aucune discrimination fondée sur la race,
        la couleur, le sexe, la religion, la nationalité, l'âge ou l'orientation
        sexuelle.
        les croyances religieuses, l'âge, le sexe, l'orientation
        sexuelle, le handicap, la race ou l'appartenance ethnique.
        origine ethnique
      </p>
      <p>
        - Le harcèlement des autres joueurs sera sanctionné, quelle que
        soit leur identité.
        la forme et la raison pour laquelle elle est effectuée
      </p>
      <p>
        - La perturbation d'un événement programmé par le personnel sera
        sanctionnée.
      </p>
      <p>
        - Abuser des bogues ou les provoquer intentionnellement afin de
        faire des bénéfices
      </p>
      <p>
        - La manipulation de fichiers qui ne se trouvent pas dans le jeu
        sera sanctionnée.
        obtenir un avantage dans le jeu
      </p>
      <p>
        - Les personnes qui utilisent les technologies de l'information
        et de la communication (TIC) seront sanctionnées et/ou bannies de façon
        permanente.
        multi-comptes pour les quêtes, les bouses et l'or.
      </p>
      <p>
        - Anti-jeu dans les arènes (Ne pas aider les siens)
        pour retrouver votre guilde, votre ami ou votre partenaire de
        l'autre côté)
      </p>
      <p>
        - Il s'agira d'une sanction pour parier sur la guérison, la mort
        ou l'utilisation de l'argent.
        toute méthode permettant d'accéder à des positions plus élevées
        dans les classements ou de
        il est préférable d'obtenir d'abord les ensembles PVP/GW
      </p>
      <p>
        - Le fait de quitter volontairement les lieux à plusieurs
        reprises sera sanctionné.
        sables
      </p>
      <p>
        - L'utilisation de hacks ou de tricheurs destinés à éliminer les
        un avantage dans le jeu par rapport à la communauté
      </p>
      <p>
        Vous avez le droit de tuer autant de fois que vous le souhaitez
        une personne à l'intérieur.
        du canal PVP, mais sera sanctionné s'il est démontré qu'il y a un
        problème de sécurité.
        poursuite de la recherche dans les différents canaux NOT PVP
      </p>
      <h4>
        6. SYSTÈMES DE SURVEILLANCE ET DE LUTTE CONTRE LA TRICHERIE
      </h4>
      <p>
        6.1 Serai-je surveillé par le GFMemories lors de l'utilisation
        des services du GFMemories ?
        (Oui, voir l'avis de confidentialité pour plus d'informations). </p>
      <p>
        Nous pouvons surveiller activement l'utilisation des services
        GFMemories.
        (bien que nous ne soyons pas obligés de le faire), à la fois dans
        notre propre pays et dans le monde entier.
        ainsi que sur votre ordinateur, à des fins diverses, y compris
        l'utilisation de l'ordinateur de l'utilisateur.
        la prévention de la tricherie, l'élimination ou la réduction du
        comportement
        les acteurs perturbateurs et l'amélioration des services du
        GFMemories.
      </p>
      <p>
        6.2.
        <u>
          La GFMemories exécute-t-elle des programmes sur mon appareil
          lorsque je ne l'utilise pas ?
          Services GFMemories ?
        </u>
        (Nous pouvons le faire à des fins limitées de lutte contre la
        tricherie).
      </p>
      <p>
        Pour éviter la tricherie et le piratage, nous pouvons vous
        demander d'installer
        un logiciel anti-triche. Ce logiciel peut fonctionner en arrièreplan dans votre
        dispositif.
      </p>
      <h4>
        7. MISES À JOUR ET MODIFICATIONS
      </h4>
      <p>
        7.1. <u>Les présentes conditions seront-ellesmodifiées à
          l'avenir?</u> (Oui).
      </p>
      <p>
        Nous pouvons (et nous le ferons probablement) créer autant de
        versions améliorées de la
        les présentes conditions à l'avenir, ainsi que les services
        GFMemories et les
        la législation l'exige. Dans ce cas, nous vous informerons des
        éléments suivants
        nouvel accord qui annulera et remplacera les présentes
        conditions. Vous recevrez le
        l'occasion d'examiner tous les nouveaux accords que nous vous
        présentons, et vous aurez
        vous pouvez décider d'accepter ou non les conditions révisées. Si
        vous acceptez les nouvelles
        vous pouvez continuer à utiliser les services GFMemories.
        nouvel accord, vous ne pourrez plus utiliser les services
        GFMemories.
      </p>
      <p>
        7.2 Les services du GFMemories seront-ils maintenus ?
        <u>
          inchangée ?
        </u>
        (Non. Les classes évolueront).
      </p>
      <p>
        Afin d'améliorer constamment les services du GFMemories, le
        GFMemories fait
        font évoluer nos jeux et veillent à ce qu'ils soient sûrs,
        amusants et sécurisés.
        GFMemories, vous acceptez que nous puissions changer, modifier,
        mettre à jour,
        suspendre, désactiver ou restreindre l'accès à l'une quelconque
        des fonctions ou caractéristiques des
        des parties des services du FMM, y compris les biens virtuels
        (par exemple, en
        Par exemple, nous pouvons modifier certaines fonctions des biens
        virtuels de la manière suivante
        des raisons réglementaires ou juridiques, ou pour améliorer
        l'expérience de jeu),
        et qu'il peut être nécessaire pour vous de télécharger et
        d'installer des logiciels et des
        les mises à jour de tout logiciel requis par les services
        GFMemories, à tout moment, sans responsabilité envers vous. En
        outre
        vous comprenez et acceptez que de telles modifications ou mises à
        jour des services peuvent être apportées au site web de l
        GFMemories peut modifier les spécifications du système
        nécessaires pour
        jouer à nos jeux et, dans ce cas, c'est vous, et non GFMemories,
        qui jouerez.
        est responsable de l'acquisition de tout logiciel ou logiciel
        supplémentaire.
        afin d'accéder à notre jeu et d'y jouer. Il comprend et
        accepte que nous puissions utiliser des correctifs en arrièreplan pour mettre à jour les systèmes
        d'information de l'UE.
        automatiquement le jeu et le logiciel avec ou sans préavis.
      </p>
      <h4>
        8. L'ABSENCE DE GARANTIES
      </h4>
      <p>
        8.1.GFMemories fournit-elleune quelconque garantie en ce qui
        concerne la
        Services du GFMemories ?
      </p>
      <p>
        <strong>L'Union européenne
          <u>Assume l'entière responsabilité de l'installation et de
            l'utilisation
            des services</u>.
        </strong>
        GFMemories
        <strong>L'Union européenne
          <u>, ainsi que des résultats obtenus (quel que soit l'endroit
            où l'on se trouve).
            est trouvé)</u>.
        </strong>
      </p>
      <p>
        En outre, si vous êtes en
        <strong>L'Union européenne
          Amérique du Nord, centrale ou du Sud
        </strong>
        :
      </p>
      <p>
        <strong>L'Union européenne
          Les services du GFMemories sont fournis "en l'état" et "sous
          réserve" . disponibilité" sans aucune garantie, déclaration ou assurance de quelque nature que ce soit, qu'il
          s'agisse
          ou non d'une garantie d'accès. explicite ou implicite. Vous assumez toute responsabilité en ce qui
          concerne l'utilisation des services.
        </strong>
        <strong><u>GFMememories</u></strong><strong><u>. Dans la mesure
            permise par la
            législation
            en
            vigueur,</u></strong><strong><u>GFMemories</u></strong>
        <strong><strong></strong><strong><u>GFMemories</u></strong></strong>
        <strong>
          <u>GFMemories</u></strong><strong><u>n'est
            pas
            n'assume aucune garantie, expresse ou implicite, qui pourrait
            s'appliquer à la

            Services</u></strong><strong><u>GFMemories</u></strong><strong><u>inclus,
            y compris, mais sans s'y limiter, les garanties implicites de
            titre, d'absence de contrefaçon, d'adéquation, de qualité marchande,
            d'adéquation à un usage particulier et d'absence de contrefaçon.
            pour le commerce, l'adéquation à un usage particulier et
            toute autre
            les garanties qui peuvent résulter de la pratique des
            affaires, de l'exécution ou du commerce, ainsi que
            de garantie quant à l'exactitude, la fiabilité ou la qualité
            de tout produit ou service.
            le contenu ou les informations inclus dans les
            services</u></strong>.
        <strong><u>GFMememories</u></strong>.
      </p>
      <h4>
        9. DIVERS
      </h4>
      <p>
        9.1. <u>Que puis-je faire si j'ai des questions sur les services
          ?
          de</u>GFMemories ? .
      </p>
      <p>
        Si vous avez des questions sur les services GFMemories, ou si
        vous souhaitez prendre contact avec nous, veuillez nous contacter.
        pour toute autre raison, veuillez nous écrire à l'adresse
        suivante
        gfmemorieswork@gmail.com.
      </p>
      <p>
        9.2. <u>L'une ou l'autre des parties peut-elle céder les
          présentes conditions</u> ?
        nous).
      </p>
      <p>
        GFMemories peut céder ses droits en vertu des présentes
        conditions, à tout moment et sans préavis.
        en tout ou en partie, à toute personne physique ou morale, sous
        quelque forme que ce soit, en toute
        à tout moment, avec ou sans votre consentement. Vous ne pouvez
        pas transférer votre
        droits en vertu des présentes conditions sans l'accord écrit
        préalable des parties.
        par écrit de la part de GFMemories. Tout transfert non autorisé
        est nul et non avenu.
      </p>
      <p>
        9.3. Le présent document constitue-t-il l'intégralité de l'accord entre les parties? (Oui,
        y compris les autres politiques importantes que nous avons
        mentionnées
        précédemment).
      </p>
      <p>
        Ces conditions représentent l'intégralité de l'accord entre vous
        et GFMemories avec
        en ce qui concerne les services du GFMemories, et remplacent tout
        autre
        les accords antérieurs ou concomitants entre vous et GFMemories.
        Les présentes conditions
        coexistera avec toute autre politique du GFMemories qui est faite
        pour
        Les conditions d'utilisation de l'Internet ne sont pas
        mentionnées dans les présentes conditions et ne les remplacent pas. Vous
        et
        Le GFMemories convient qu'il ne s'est pas appuyé sur et qu'il n'a
        pas de recours auprès de
        en ce qui concerne l'un quelconque des termes, conditions,
        représentations, garanties, représentations, garanties ou
        des termes similaires qui ne sont pas expressément énoncés dans
        les présentes conditions.
      </p>
      <p>
        9.4. <u>Que se passe-t-il si une catastrophe naturelle perturbe
          le jeu</u> ?
        (GFMemories n'est pas responsable).
      </p>
      <p>
        GFMemories n'a aucune responsabilité en cas de retard ou d'échec
        des opérations suivantes
        l'exécution, y compris les jugements d'exécution en vertu des
        présentes conditions
        en raison de circonstances imprévisibles ou indépendantes de la
        volonté de GFMemories,
        (a) le piratage informatique, les cyber-attaques, les dommages ou
        la perte de données (lorsque nous avons
        a pris les mesures qui seraient raisonnablement prises par des
        entreprises telles que la
        (a) la survenance d'un tel événement) ; ou (b) des événements
        naturels,
        guerre, terrorisme, alertes à la bombe, émeutes, embargos, actes
        de terrorisme, alertes à la bombe, émeutes, embargos, actes de
        terrorisme, alertes à la bombe, émeutes, embargos, actes de terrorisme
        les autorités civiles ou militaires, les incendies, les
        inondations, les accidents,
        grèves, problèmes de transport, pénuries de carburant, pénuries
        d'énergie, pénuries de main-d'œuvre, et
        des travaux ou des matériaux.
      </p>
      <p>
        9.5. <u>Les présentes conditions signifient-elles que nous sommes
          un partenaire</u> (Non, juridiquement
        non).
      </p>
      <p>
        Vous acceptez qu'il n'y ait aucune relation de coentreprise, de
        partenariat, de coentreprise, de partenariat, de coentreprise, de
        partenariat, de coentreprise, de partenariat, de coentreprise.
        d'emploi ou de représentation entre vous et GFMemories à la suite
        des présentes.
        ou votre utilisation des services du GFMemories
      </p>
      <p>
        9.6. Sije viole les présentes conditions et que GFMemories<u>ne fait rien par une
          sur une longue période, cela signifie-t-il que</u>GFMememories
        <u>
          ne me réclamera plus ?
        </u>
        (Non. Nous nous réservons tous les droits).
      </p>
      <p>
        L'incapacité de GFMemories à mettre en œuvre toute disposition
        des
        les présentes conditions ne peuvent en aucun cas être
        interprétées comme une renonciation à l'une des dispositions précédentes.
        actuelle ou future d'une telle stipulation, ni n'affecte en
        aucune façon le droit de l'intéressé de se prévaloir de ses droits.
        d'une partie à faire valoir l'un ou l'autre de ces droits.
        par la suite. La renonciation par GFMemories à
        toute disposition, condition ou exigence des présentes conditions
        ne sera pas applicable.
        constitue une renonciation à toute obligation future de se
        conformer à une telle
        disposition, condition ou exigence.
      </p>
      <p>
        9.7 Quelles sont les ressources à la disposition du GFMemories ?
        <u>
          en cas de violation des présentes conditions ?
        </u>
      </p>
      <p>
        Vous acceptez que GFMemories subisse un préjudice irréparable si
        vous ne vous conformez pas à ce qui suit
        spécifiquement certaines des conditions (telles que la clause
        3.2), et que, par conséquent, les dommages
        ne serait pas une ressource
        adapté aux mémoires du GFM Nous pouvons nous appuyer sur cette
        clause pour demander une
        a jugé qu'il y avait lieu d'accorder une injonction ou une
        exécution spécifique.
      </p>
      <p>
        9.8. <u>Quelle est la durée de cet accord?</u> (Pendant la durée
        de l'accord)
        vous utilisez les services du GFMemories ou jusqu'à ce que votre
        compte soit résilié).
      </p>
      <p>
        Les présentes conditions entrent en vigueur à la date de la
        première acceptation et sont
        valable aussi longtemps que vous utilisez les services GFMemories
        ou aussi longtemps que vous
        tenir un compte de ces derniers. <strong>Sans</strong>.
        <strong>L'Union européenne
          Toutefois, les clauses correspondant à la réception des
          marchandises et à l'utilisation de l'argent de l'État membre d'accueil ne
          s'appliquent pas.
          Paiements effectués pour l'obtention des éléments
          correspondants
          et sa politique de non-remboursement sera permanente.
        </strong>
      </p>
      <p>
        <strong>L'Union européenne
          VOUS RECONNAISSEZ ET ACCEPTEZ QUE VOUS AVEZ LU ET COMPRIS CES
          CONDITIONS, ET QU'EN
          SÉLECTIONNER LE BOUTON "ACCEPTER" CI-DESSOUS, OU LORS DE
          L'UTILISATION OU DE L'ACCÈS AU SITE WEB DE LA
          GFMemories SERVICES, s'engage à se soumettre aux conditions
          générales d'utilisation de
          CONDITIONS ÉNONCÉES DANS LES PRÉSENTES CONDITIONS.
        </strong>
      </p>
    </div>
    <div v-if="$t('lang') == 'br'" class="w-7 pt-7">
      <p><b>Finalidade e natureza do servidor</b></p>
      <p><strong>GFMemories é um servidor privado Grand Fantasia independente e
          não oficial. Queremos esclarecer que não somos afiliados, endossados ou
          associados de forma alguma à empresa oficial que desenvolve ou gerencia
          Grand Fantasia/X-Legends.</strong></p>

      <p>Nosso servidor foi criado com o objetivo de oferecer uma experiência
        de serviço única e personalizada aos nossos usuários. Entendemos que somos
        uma entidade separada e privada e não pretendemos personificar ou usurpar
        a identidade da empresa que criou o serviço.</p>
      <p>
        As presentes condições de serviço (a seguir designadas por
        "<strong>Termos</strong>")
        estabelecer os termos e condições segundo os quais a GFMemories
        oferece ao utilizador
        acesso à utilização e usufruto do nosso jogo, sítios Web e outros
        serviços
        (doravante designados por "Serviços da
        <strong>GFMemories</strong>").
        Estas condições constituem um contrato entre o utilizador e esta
        entidade.
      </p>
      <p>
        Ao acessar e utilizar os serviços GFMemories, você concorda e reconhece que se
        trata de um servidor privado não oficial. Encorajamos você a revisar cuidadosamente
        estes termos e condições antes de continuar a usar nossos serviços:
      </p>
      <ul>
        <li>
          <strong>Conteúdo virtual</strong>. Ao clicar para comprar,
          ganhar ou
          receber o Conteúdo Virtual como oferta, apenas obtém uma
          licença para
          aceder ao Conteúdo Virtual. Não tem qualquer propriedade sobre
          os
          Conteúdo virtual que desbloqueia e que não pode ser transferido
          para mais ninguém.
          O conteúdo virtual não tem um valor monetário, é geralmente
          específico
          e não pode ser trocado por qualquer tipo de dinheiro "no jogo".
          real".
        </li>
        <li>
          <strong>Política de reembolso</strong>. O utilizador perde o
          direito de alterar a sua
          encomenda, cancelar a encomenda e obter um reembolso se obtiver
          acesso
          conteúdo imediatamente para conteúdo digital ou descarregá-lo.
        </li>
      </ul>
      <ul type="disc">
        <li>
          <strong>Regras do Utilizador</strong>. O utilizador concorda em
          seguir as nossas Regras do utilizador
          utilizador.
          Estas regras regem tanto o comportamento no jogo como certas
          comportamento fora do jogo ao utilizar os serviços GFMemories
        </li>
      </ul>
      <h4>1 . A SUA CONTA</h4>
      <p>
        1.1. Posso criar uma conta e utilizar os
        <strong>Serviços</strong>?
        GFMemories <strong><u>?</u></strong>.
        <strong>
          (É necessário ser maior de idade ou ter a autorização dos pais
          ou do tutor.
          legal).
        </strong>
      </p>
      <p>
        É necessária uma conta GFMemories para aceder a muitas das nossas
        serviços. Para criar uma conta e utilizar os serviços GFMemories,
        tem de
        (i) ser maior de idade; (ii) ser um menor emancipado; ou (iii)
        ser um menor de idade.
        consentimento válido dos seus pais ou do seu tutor legal para
        ficar vinculado de acordo com
        estes Termos. Se o utilizador não for maior de idade, for um
        menor emancipado ou não compreender estes Termos.
        Se é pai ou tutor legal, fale com os seus pais ou tutor legal e
        peça ajuda. Se fores o pai ou a mãe,
        os pais ou o tutor legal de um menor que crie uma conta connosco,
        o utilizador e o tutor da criança
        O menor aceita e concorda em ficar vinculado a estas condições. É
        também
        responsável por toda a utilização da conta e pelo cumprimento das
        presentes Condições
        pela criança, incluindo todas as compras efectuadas na conta. Não
        o utilizador não pode criar ou utilizar uma conta, ou utilizar
        qualquer um dos Serviços ou qualquer um dos
        GFMemories em nome de qualquer outra pessoa, ou de qualquer outra
        pessoa
        legal, nem para fins comerciais.
      </p>
      <p>
        <strong>
          1.2. Como é que posso criar uma conta (no nosso sítio Web)?
        </strong>
      </p>
      <p>
        Pode criar uma conta com credenciais de início de sessão únicas
        em
        os nossos sítios Web.
      </p>
      <p>
        <strong>
          1.3. <u>Tenho de fornecer o meu nome verdadeiro e outros
            dados?</u> (Sim, tenho).
          Não incluir nomes por brincadeira).
        </strong>
      </p>
      <p>
        Deve fornecer sempre informações exactas e completas, incluindo o
        seu nome
        real.
      </p>
      <p>
        <strong>
          1.4. ¿
          <u>
            Posso partilhar ou vender a minha conta ou as minhas
            informações de início de sessão?
            sessão?
          </u>
          (Não).
        </strong>
      </p>
      <p>
        Ao criar uma conta, ser-lhe-á pedido que seleccione um nome de
        utilizador e uma palavra-passe.
        palavra-passe única (coletivamente, a "<strong>Informação de
          Início de Sessão</strong>").
        Concorda com isso:
      </p>
      <ul>
        <li>
          Não pode partilhar a sua conta e informações de início de
          sessão com
          ninguém.
        </li>
        <li>
          O utilizador não pode vender ou transferir a sua conta ou as
          suas Informações de início de sessão da conta para outra pessoa.
          sessão, nem permitir que mais ninguém lhes aceda. Também não
          pode
          oferecer-se para o fazer.
        </li>
        <li>
          Tem de manter em segredo as suas informações de início de
          sessão.
        </li>
      </ul>
      <p>
        O utilizador deve notificar-nos imediatamente se tiver
        conhecimento de qualquer uma das seguintes situações
        qualquer violação da segurança, incluindo o acesso não autorizado
        à
        conta, ou a perda, roubo ou divulgação não autorizada da sua
        Informações de início de sessão ou informações de pagamento, para
        que o utilizador possa
        podemos tomar as medidas adequadas. O utilizador é responsável
        por todas as
        perdas (incluindo perda ou utilização de Conteúdo Virtual) na
        conta do utilizador
        quando o utilizador tiver partilhado as suas informações de
        início de sessão ou não tiver partilhado as suas
        manteve a conta ou as informações de início de sessão protegidas.
      </p>
      <h4>
        2. ANULAÇÃO DA CONTA
      </h4>
      <p>
        <strong>
          2.1 Como é que a minha conta pode ser suspensa ou cancelada?
        </strong>
      </p>
      <p>
        2.1.1. <strong>Você</strong>. O utilizador pode cancelar ou
        suspender a sua conta em
        em qualquer altura. Para o fazer, contacte-nos através de
        a partir do endereço eletrónico gfmemorieswork@gmail.com.
      </p>
      <p>
        2.1.2 <strong>Nós</strong>. Podemos encerrar ou suspender a sua
        conta sem
        aviso prévio se, actuando de forma razoável, concluirmos que:
      </p>
      <ol>
        <li>
          O utilizador violou qualquer parte das presentes Condições
          (incluindo as Regras) ao
          do utilizador).
        </li>
        <li>
          Actuamos no interesse da nossa comunidade, da GFMemories ou da
          GFMemories ou é
          necessário para efeitos de proteção dos direitos de terceiros.
        </li>
        <li>
          Deixámos de oferecer o GFMemories na sua região (embora
          normalmente
          publicarmos um aviso prévio no nosso sítio Web, aplicação ou
          jogo se tencionamos deixar de oferecer uma caraterística
          fundamental de um
          ou todas as GFMemories da sua região).
        </li>
        <li>
          Não paga qualquer comissão que nos seja devida (exceto em caso
          de negligência)
          falta grave ou premeditada) ou temos de reembolsar
          alguém devido à utilização não autorizada de um pagamento
          efectuado a partir da sua conta.
        </li>
        <li>
          Fez (ou temos motivos razoáveis para suspeitar que fez)
          utilização não autorizada das informações de pagamento de outra
          pessoa.
        </li>
      </ol>
      <p>
        Podemos chegar a essas conclusões com a ajuda de sistemas
        automatizados ou
        ferramentas de aprendizagem automática, ou por outros métodos que
        que considerarmos adequados. Se considerar que cometemos um erro,
        contacte-nos através de
        contacte-nos com os detalhes e nós analisaremos o seu caso,
        embora possamos
        suspender a sua conta durante a análise. Pode refutar a nossa
        conclusões.
      </p>
      <h4>
        3. BENS VIRTUAIS, MOEDA DO JOGO E COMPRAS
      </h4>
      <p>
        <strong>
          3.1 <u>O que é conteúdo virtual?</u> (O conteúdo virtual inclui
          coisas como a moeda do jogo e bens virtuais, como os campeões,
          aspectos, emoticons, acessórios, etc.).
        </strong>
      </p>
      <p>
        Ao utilizar a GFMemories, poderemos dar-lhe a oportunidade de
        comprar um
        licença limitada para aceder a bens virtuais, como campeões,
        aspectos,
        emoticons, etc., ("<strong>Bens Virtuais</strong>") e moeda do
        jogo
        ("<strong>Moeda do jogo</strong>"), associada à sua conta (na sua
        em conjunto, o "<strong>Conteúdo Virtual</strong>").
      </p>
      <p>
        A Moeda do Jogo só pode ser utilizada para o jogo específico para
        o qual
        foi comprado, exceto se indicarmos o contrário no momento da
        compra.
        compra.
      </p>
      <p>
        <strong>
          3.2. ¿
          <u>
            Como posso obter uma "licença" para aceder aos Conteúdos
            Virtuais?
          </u>
          (Compre-o, ganhe-o ou receba-o).
        </strong>
      </p>
      <p>
        Podemos oferecer-lhe diferentes oportunidades para adquirir uma
        licença
        acesso limitado à moeda do jogo ou a conteúdos virtuais, entre
        outras coisas.
        outros:
      </p>
      <ol>
        <li>
          comprar (por exemplo, com um cartão de crédito);
        </li>
        <li>
          ganhando-o (por exemplo, completando uma missão ou tarefas no
          jogo);
        </li>
        <li>
          recebê-la (por exemplo, de outros jogadores como prenda).
        </li>
      </ol>
      <p>
        <strong>
          3.3. <u>Sou "dono" do Conteúdo virtual que desbloqueei?</u> .
        </strong>
      </p>
      <p>
        Não é proprietária nem tem quaisquer outros direitos de
        propriedade sobre os
        Conteúdo virtual que desbloqueia, independentemente de ter
        comprado o
        acesso ao mesmo. Os conteúdos virtuais não têm valor monetário.
        Não pode
        transferir ou trocar Conteúdo Virtual por qualquer tipo de
        dinheiro "mundial".
        real".
        <strong>
          Não é possível obter qualquer reembolso pela compra de uma
          licença para
          aceder aos Conteúdos Virtuais, exceto se tivermos indicado que
          o fizemos.
          especificamente
        </strong>
        .
      </p>
      <p>
        <strong>
          3.4. <u>Então eu não sou o proprietário do meu Conteúdo
            virtual?</u> ).
        </strong>
      </p>
      <p>
        Quando obtém Conteúdos Virtuais, o que lhe damos na realidade é
        um direito.
        e licença pessoal, não exclusiva, intransferível e não
        sublicenciável,
        revogável e limitada à utilização desse Conteúdo virtual apenas
        em ligação com
        com a sua correspondente utilização de GFMemories.
      </p>
      <p>
        <strong>
          Não obstante qualquer disposição em contrário nas presentes
          Condições, o utilizador
          reconhece e aceita que não terá quaisquer direitos de
          propriedade ou quaisquer outros direitos sobre os
          outros direitos de propriedade sobre a sua conta, e que todos
          os direitos sobre a sua conta permanecerão com o utilizador.
          a sua conta são e serão sempre propriedade de e para benefício
          de
        </strong>
        <strong>GFMemories</strong><strong>. O utilizador
          reconhece e concorda ainda que não
          tem qualquer título, propriedade ou outro interesse de
          propriedade na
          Conteúdo virtual, independentemente de qualquer contrapartida
          oferecida
          ou pagos em contrapartida. Além disso, exceto em caso de falta
          intencional
          ou negligência grave, ou na medida em que estas Condições
          indiquem
          caso contrário, a
        </strong>GFMemories<strong>não será responsabilizada
          de qualquer modo, pela destruição, remoção, modificação,
          alteração, destruição, alteração, modificação, alteração, alteração,
          alteração, alteração, alteração, alteração, alteração, alteração ou
          deterioração, pirataria ou qualquer outro dano ou perda de
          qualquer tipo
          causados ao Conteúdo Virtual, incluindo a remoção do Conteúdo
          Virtual
          após o cancelamento ou expiração da sua conta ou das nossas
          alterações
          justificado para o</strong><strong>GFMemórias</strong>.
      </p>
      <p>
        <strong>
          3.5 Os meus Conteúdos Virtuais estarão sempre disponíveis?
          necessariamente).
        </strong>
      </p>
      <p>
        Num esforço para melhorar constantemente o GFMemories e evoluir
        os nossos jogos e manter o GFMemories seguro, divertido e
        protegido,
        temos o direito de apagar, alterar, deslocar, remover, reagrupar,
        revalorizar ou transferir qualquer Conteúdo de Jogo, incluindo
        Conteúdo de Jogo
        em qualquer altura, no todo ou em parte, com ou sem motivo, ou
        sem aviso prévio e sem qualquer responsabilidade para o
        utilizador. Por
        Por exemplo, os seus bens virtuais podem evoluir (e é provável
        que o façam).
        ao longo do tempo, a fim de melhorar o GFMemories ou para fins
        regulamentares ou
        legal. Se decidirmos remover completamente certos bens virtuais
        que
        Se já o tiver comprado anteriormente, fornecer-lhe-emos uma
        substituição. Não nos desfazemos de
        a sua moeda de jogo sem aviso prévio (quer através de publicações
        em
        o nosso sítio Web, aplicação ou jogo), a menos que terminemos a
        sua conta por
        um motivo válido ou se o fizer ao abrigo da cláusula 2.1.1
        . Ocasionalmente, podemos alterar o poder de compra do
        jogo (por exemplo, podemos aumentar a quantidade de Moeda do jogo
        necessário para adquirir Bens Virtuais, por exemplo, aspectos).
        Em geral,
        só o faremos gradualmente, mas informá-lo-emos (ou por
        publicações no nosso sítio Web, aplicação ou jogo) se estivermos
        a planear
        fazer alterações que afectem significativamente o seu jogo Moeda
        do
        de uma forma negativa. Não damos quaisquer garantias e excluímos
        todas e quaisquer garantias de qualquer tipo.
        responsabilidade em relação a quaisquer valores mobiliários,
        dinheiro ou outros,
        atribuídas às informações contidas nos servidores que utilizamos
        ou
        que controlamos, incluindo qualquer Conteúdo do Jogo ou Conteúdo
        Virtual
        atribuídos à sua conta. Se permitirmos a venda ou transferência
        do seu direito
        para aceder a determinados Conteúdos de Jogo ou Conteúdos
        Virtuais, só será possível
        através de serviços aprovados ou fornecidos por nós,
        se for o caso.
      </p>
      <h4>
        4. TAXAS E IMPOSTOS
      </h4>
      <p>
        4.1 Tudo o que está relacionado com os serviços GFMemories é
        gratuito?
      </p>
      <p>
        Algumas edições dos serviços GFMemories podem exigir o pagamento
        de
        uma taxa, e o utilizador concorda em fornecer-nos a nós ou aos
        fornecedores terceiros
        serviços de pagamento que utilizamos informações de pagamento
        exactas e completas.
        Além disso, aceita pagar todas as taxas e impostos aplicáveis em
        que incorre
        a sua conta. Podemos verificar os preços de qualquer parte da
        GFMemories
        em qualquer altura. Esta situação pode afetar o poder de compra
        da moeda.
        do jogo, embora, em geral, só o façamos gradualmente. Todos
        as taxas e impostos aplicáveis devem ser pagos em conformidade
        com o
        condições de pagamento em vigor no momento do vencimento.
        Reservamos,
        em tempo útil, o direito de alterar, corrigir ou completar as
        taxas e
        métodos de faturação. Essas alterações produzirão efeitos
        imediatamente após a sua
        publicação nas presentes Condições ou noutros locais dos nossos
        sítios Web,
        aplicações ou jogos. Exceto em casos de negligência grave ou má
        conduta
        inadequado premeditado, se o utilizador não pagar qualquer taxa
        devida ou se tivermos de
        reembolsar alguém devido à utilização não autorizada de um
        pagamento efectuado a partir da sua conta
        a sua conta, podemos suspender ou encerrar a sua conta. Para
        obter mais informações
        informações, consultar a Cláusula 2
        (Anulação da conta).
      </p>
      <p>
        <strong>
          Sujeito à legislação em vigor, às taxas aplicáveis e a
          outros encargos
          para serviços tarifados (incluindo a moeda de jogo) são pagos
          por
          As taxas não são reembolsáveis antecipadamente, no todo ou em
          parte,
          exceto conforme expressamente indicado nas presentes
          Condições. Exceto
          em caso de negligência grave ou de dolo por parte de
          parte de
        </strong>
        GFMemórias
        <strong>
          <u>, ou na medida em que estes Termos indiquem o contrário, o
            utilizador
            é responsável por todos os encargos da sua conta, incluindo
            os encargos que não
            autorizado.</u>
        </strong>
      </p>
      <p>
        4.2. ¿
        <u>
          Quais são as minhas responsabilidades em relação à moeda do
          jogo?
        </u>
        (Pagar os impostos correspondentes; verificar se as transacções
        connosco estão correctas, etc.).
      </p>
      <p>
        O utilizador é o único responsável pelo pagamento dos impostos
        devidos pela
        aquisição, utilização ou acesso à Moeda do Jogo. Podemos vender
        ou emitir
        a moeda do jogo em pacotes, e o preço pode variar consoante a
        a quantidade a adquirir e o local onde a compra é efectuada. Para
        o nosso
        se considerarmos que é necessário (ou se for exigido por lei),
        podemos limitar a quantidade total de moeda do jogo que pode ser
        comprada a
        ao mesmo tempo para qualquer jogo individual ou que pode ser
        acumulado no total no seu
        conta. Para além disso, o preço e a disponibilidade da Moeda do
        Jogo e da
        Os produtos virtuais estão sujeitos a alterações sem aviso
        prévio. Nós podemos
        restringir a quantidade de Moeda do Jogo que pode comprar ou
        utilizar no seu jogo.
        localização, uma vez que existem diferentes abordagens em função
        das diferentes
        locais.
      </p>
      <p>
        Deve-se verificar se a quantidade adequada de
        Moeda do jogo na sua conta durante qualquer transação. Informenos
        imediatamente se acreditar que existe um erro no saldo da sua
        Moeda do Jogo.
        A GFMemories investigará a sua queixa e, ao fazê-lo, poderá
        pedir-lhe que
        informações adicionais para verificação.
      </p>
      <h4>
        5) REGRAS DO UTILIZADOR
      </h4>
      <p>
        5.1. ¿
        <u>
          Posso provocar, atacar, ameaçar ou assediar pessoas enquanto
          utilizo
          os serviços GFMemories?
        </u>
        (Não. Se o fizer, poderemos tomar medidas, como a suspensão da
        sua conta).
      </p>
      <p>
        Ao utilizar os Serviços GFMemories, o utilizador deve cumprir
        todas as leis,
        regras e regulamentos aplicáveis no seu local de residência. Além
        disso, o candidato deve
        cumprir as políticas sobre utilizações e comportamentos
        aceitáveis que
        que publicamos atempadamente nos nossos sítios Web, aplicações e
        jogos, e
        as regras de comportamento a seguir indicadas (no seu conjunto),
        as "<strong>Regras do Utilizador</strong>"). As Regras do
        Utilizador
        publicados nos nossos sítios Web, ou indicados na presente
        cláusula, não
        pretendem ser exaustivos e reservamo-nos o direito de os
        modificar em qualquer altura.
        em qualquer altura, bem como a tomar as medidas disciplinares
        adequadas,
        que incluem o bloqueio temporário, a suspensão ou o cancelamento,
        e
        eliminação da conta, a fim de proteger a integridade e o espírito
        do
        GFMemories, independentemente do facto de um determinado
        comportamento estar ou não incluído.
        que não constem das Regras do Utilizador como inadequados.
      </p>
      <p>
        Seguem-se exemplos de comportamentos que desencadearão a adoção
        de
        medidas disciplinares: <br /> <br />
        <br />
        - Insultar a equipa do servidor será sancionado.
      </p>
      <p>
        - A representação de um membro do pessoal será objeto de sanções.
      </p>
      <p>
        - É proibida a compra e venda de artigos de jogo e de ouro a
        troco de dinheiro.
        real
      </p>
      <p>
        - A compra e venda de contas, independentemente do meio de
        pagamento, será sancionada.
        pagamento (as contas que foram roubadas por vendas não serão
        recuperadas)
      </p>
      <p>
        - A utilização de linguagem inapropriada, tanto no jogo como
        dentro do jogo, será sancionada.
        como nas redes de servidores
      </p>
      <p>
        - A utilização de nomes de jogadores ou guildas que sejam
        ofensivo
      </p>
      <p>
        - A utilização de qualquer linguagem inadequada será sancionada
        nos seguintes casos
        este servidor não aceitará qualquer discriminação em razão da
        raça, cor, sexo, religião, nacionalidade, idade ou orientação sexual.
        crenças religiosas, idade, género, orientação sexual,
        deficiência, raça ou etnia.
        origem étnica
      </p>
      <p>
        - O assédio a outros jogadores será sancionado, independentemente
        da sua identidade.
        a forma e o motivo pelo qual é efectuada
      </p>
      <p>
        - A perturbação de um evento programado pelo pessoal será objeto
        de sanções.
      </p>
      <p>
        - Abusar de bugs ou causar intencionalmente bugs para
        obter lucros
      </p>
      <p>
        - A adulteração de ficheiros do jogo que não estejam no jogo será
        sancionada.
        obter uma vantagem no jogo
      </p>
      <p>
        - Será motivo de sanção e/ou interdição permanente para as
        pessoas que utilizem
        contas múltiplas para missões, dungs e ouro.
      </p>
      <p>
        - Anti-jogo nas arenas (Não ajudar os seus próprios
        equipa para encontrar o seu grupo, o seu amigo ou o seu parceiro
        do outro lado)
      </p>
      <p>
        - Será uma sanção apostar em curas, mortes ou na utilização de
        qualquer método para obter acesso a posições mais elevadas na
        classificação ou para
        beneficiar da obtenção dos conjuntos PVP/ GW em primeiro lugar
      </p>
      <p>
        - Será uma sanção abandonar voluntariamente as instalações várias
        vezes.
        areias
      </p>
      <p>
        - A utilização de hacks ou batoteiros que se destinam a eliminar
        uma vantagem no jogo contra a comunidade
      </p>
      <p>
        É permitido matar tantas vezes quantas as que se quiser para
        matar uma pessoa dentro de casa.
        do canal PVP, mas será sancionado se for demonstrado que existe
        um
        prosseguir o seu trabalho nos diferentes canais NÃO PVP
      </p>
      <h4>
        6. SISTEMAS DE CONTROLO E DE LUTA CONTRA A FRAUDE
      </h4>
      <p>
        6.1 Serei monitorizado pela GFMemories enquanto estiver a
        utilizar os serviços GFMemories?
        <u>?</u> (Sim. Consulte o Aviso de Privacidade para obter mais
        informações).
      </p>
      <p>
        Podemos monitorizar ativamente a utilização dos Serviços
        GFMemories
        (embora não tenhamos qualquer obrigação de o fazer), tanto na
        nossa própria
        servidores, bem como no seu computador, para uma variedade de
        fins, incluindo
        prevenção da batota, eliminação ou redução do comportamento
        actores disruptivos e a melhoria dos serviços GFMemories.
      </p>
      <p>
        6.2.
        <u>
          O GFMemories executa programas no meu dispositivo quando não
          estou a utilizar o
          Serviços GFMemories?
        </u>
        (Podemos fazê-lo para fins limitados de luta contra a batota).
      </p>
      <p>
        Para evitar a batota e a pirataria, podemos pedir-lhe que instale
        software anti-trapaça. Este software pode ser executado em
        segundo plano no seu
        dispositivo.
      </p>
      <h4>
        7. ACTUALIZAÇÕES E MODIFICAÇÕES
      </h4>
      <p>
        7.1. <u>Estes Termos serãoalterados no futuro?</u> (Sim).
      </p>
      <p>
        Podemos (e provavelmente iremos) criar tantas versões
        actualizadas do
        estes Termos no futuro, bem como os Serviços GFMemories e os
        legislação o exija. Quando o fizermos, informá-lo-emos do
        seguinte
        novo acordo que substituirá e prevalecerá sobre estes Termos.
        Ser-lhe-á dado o
        oportunidade de rever quaisquer novos acordos que lhe
        apresentemos, e terá
        o utilizador poderá decidir se pretende aceitar as condições
        revistas. Se o utilizador aceitar as novas
        o utilizador pode continuar a utilizar os serviços da GFMemories.
        novo acordo, o utilizador deixará de poder utilizar os Serviços
        GFMemories.
      </p>
      <p>
        7.2 Os serviços GFMemories serão mantidos?
        <u>
          inalterado?
        </u>
        (Não. As aulas evoluirão).
      </p>
      <p>
        Para melhorar constantemente os serviços da GFMemories, a
        GFMemories faz
        evoluir os nossos jogos e manter os jogos seguros, divertidos e
        protegidos.
        serviços GFMemories, o utilizador concorda que podemos alterar,
        modificar, atualizar,
        suspender, desativar ou restringir o acesso a qualquer uma das
        funções ou características do
        partes dos Serviços GFMemories, incluindo os Bens Virtuais (por
        exemplo, através de
        Por exemplo, podemos alterar algumas funções dos Bens Virtuais
        razões regulamentares ou legais, ou para melhorar a experiência
        de jogo),
        e que poderá ser necessário descarregar e instalar software e
        actualizações de qualquer software exigido pelos Serviços
        GFMemories, em qualquer altura, sem qualquer responsabilidade
        para o utilizador. Também
        o utilizador compreende e aceita que tais alterações ou
        actualizações dos Serviços podem ser feitas na
        A GFMemories pode modificar as especificações do sistema
        necessárias para
        jogar os nossos jogos e, nesse caso, será o utilizador, e não a
        GFMemories, que terá de
        será responsável pela aquisição de qualquer software adicional ou
        hardware para aceder e jogar o nosso jogo. Também compreende e
        aceita que possamos utilizar patches em segundo plano para
        atualizar
        automaticamente o jogo e o software com ou sem aviso prévio.
      </p>
      <h4>
        8. AUSÊNCIA DE GARANTIAS
      </h4>
      <p>
        8.1. A GFMemories não dá qualquer garantia em relação ao
        Serviços GFMemories?
      </p>
      <p>
        <strong>
          <u>Assume total responsabilidade pela instalação e utilização
            dos Serviços</u>.
        </strong>
        GFMemórias
        <strong>
          <u>, bem como dos resultados obtidos (independentemente do
            local
            é encontrado)</u>.
        </strong>
      </p>
      <p>
        Além disso, se estiver em
        <strong>
          América do Norte, Central ou do Sul
        </strong>
        :
      </p>
      <p>
        <strong>
          <u>Os Serviços GFMemories são fornecidos "como estão" e
            "sujeitos a
            disponibilidade" sem quaisquer garantias, representações ou
            garantias de qualquer tipo, quer sejam ou não
            expressos ou implícitos. O utilizador assume toda a
            responsabilidade no que diz respeito à utilização
            dos Serviços</u>.
        </strong>
        <strong><u>GFMemórias</u></strong><strong><u>. Na medida do
            permitido pelo
            legislação
            em
            vigor,</u></strong><strong><u>GFMemories</u></strong>
        <strong><u>GFMemories</u></strong><strong>
        </strong><strong><u>GFMemórias</u></strong><u>não
          não assume quaisquer garantias, expressas ou implícitas, que
          possam aplicar-se ao
          Serviços</u><strong><u>GFMemories</u></strong><strong><u>incluíd
            os,
            incluindo, mas não se limitando a, garantias implícitas de
            título, não infração, adequação, comercialização, adequação a um
            determinado fim e não infração.
            para o comércio, adequação a um determinado fim e qualquer
            outra
            garantias que possam surgir no decurso de uma atividade
            comercial, de execução ou de comércio, bem como
            como quaisquer garantias quanto à exatidão, fiabilidade ou
            qualidade de qualquer
            conteúdo ou informações incluídas nos Serviços</u></strong>.
        <strong><u>GFMemórias</u></strong>.
      </p>
      <h4>
        9. DIVERSOS
      </h4>
      <p>
        9.1. <u>O que posso fazer se tiver dúvidas sobre os Serviços?
          de</u>GFMemórias? .
      </p>
      <p>
        Se tiver alguma questão sobre os Serviços GFMemories, ou se
        quiser contactar a GFMemories, por favor contacte-nos.
        por qualquer outro motivo, escreva-nos para
        gfmemorieswork@gmail.com.
      </p>
      <p>
        9.2. <u>Pode uma das partes ceder as presentes Condições</u>?
        (Não. Apenas
        nós).
      </p>
      <p>
        A GFMemories pode ceder os seus direitos ao abrigo destes Termos,
        em qualquer altura e sem aviso prévio.
        no todo ou em parte, a qualquer pessoa singular ou colectiva, sob
        qualquer forma, em qualquer
        em qualquer altura, com ou sem o seu consentimento. O utilizador
        não pode transferir o seu
        direitos ao abrigo destes Termos sem o consentimento prévio por
        escrito das partes.
        por escrito da GFMemories. Qualquer transferência não autorizada
        será nula e sem efeito.
      </p>
      <p>
        9.3. <u>O presente documento constitui o acordo integral entre as
          partes</u>? (Sim,
        incluindo as outras políticas importantes que mencionámos
        anteriormente).
      </p>
      <p>
        Estes Termos representam a totalidade do acordo entre o
        utilizador e a GFMemories com
        em relação aos Serviços GFMemories, e substitui qualquer outro
        acordos anteriores ou simultâneos entre o utilizador e a
        GFMemories. Estes Termos
        coexistirá com qualquer outra política da GFMemories que seja
        feita para
        referência nestes Termos, e não o substitui. O utilizador e a
        A GFMemories concorda que não confiou e não tem qualquer recurso
        contra
        relativamente a qualquer dos termos, condições, declarações,
        garantias, declarações, garantias ou
        termos semelhantes que não estejam expressamente definidos nas
        presentes Condições.
      </p>
      <p>
        9.4. <u>O que acontece se uma catástrofe natural interferir com o
          jogo</u>?
        (A GFMemories não será responsável).
      </p>
      <p>
        A GFMemories não terá qualquer responsabilidade por qualquer
        atraso ou falha de
        execução, incluindo as decisões de execução ao abrigo das
        presentes condições
        devido a circunstâncias imprevisíveis ou fora do controlo da
        GFMemories,
        (a) pirataria informática, ciberataques, danos ou perda de dados
        (quando tivermos
        tomou as medidas que seriam razoavelmente adoptadas por empresas
        como a
        (a) os nossos próprios esforços para evitar a ocorrência de tal
        evento); ou (b) eventos naturais,
        guerra, terrorismo, ameaças de bomba, motins, embargos, actos de
        terrorismo, actos de terrorismo, ameaças de bomba, motins, embargos,
        actos de
        autoridades civis ou militares, incêndios, inundações, acidentes,
        greves, problemas de transporte, escassez de combustível,
        escassez de energia, escassez de mão de obra e
        obras ou materiais.
      </p>
      <p>
        9.5. <u>As presentes Condições significam que somos um
          parceiro</u> (Não, legalmente
        não).
      </p>
      <p>
        O utilizador concorda que não existe uma relação de joint
        venture, parceria, joint venture, joint venture, parceria, parceria
        de emprego ou representação entre o utilizador e a GFMemories
        como resultado destas
        Termos ou a sua utilização dos Serviços GFMemories
      </p>
      <p>
        9.6. Seeu violar estes Termos e a GFMemories<u>não fizer
          nada através de um
          longo período de tempo, isso significa que</u>GFMemórias
        <u>
          já não me vai reclamar?
        </u>
        (Não. Reservamo-nos todos os direitos).
      </p>
      <p>
        O incumprimento por parte da GFMemories de qualquer disposição do
        estes Termos não devem, de forma alguma, ser interpretados como
        uma renúncia a qualquer um dos anteriores.
        presente ou futura de tal estipulação, nem afectará de modo algum
        o direito
        de qualquer parte para fazer cumprir todas e quaisquer
        disposições posteriores. A renúncia da GFMemories a
        qualquer disposição, condição ou requisito das presentes
        Condições não será aplicável.
        constituirá uma renúncia a qualquer obrigação futura de cumprir
        essa
        disposição, condição ou requisito.
      </p>
      <p>
        9.7 Que recursos estão disponíveis para a GFMemories?
        <u>
          em caso de violação das presentes Condições?
        </u>
      </p>
      <p>
        O utilizador concorda que a GFMemories sofrerá danos irreparáveis
        se não cumprir o seguinte
        especificamente algumas das Condições (como a Cláusula 3.2), e
        que, consequentemente, os danos
        não seria um recurso
        adequado para a GFMemories Podemos basear-nos nesta Cláusula para
        solicitar uma
        julgou medidas cautelares ou de execução específica.
      </p>
      <p>
        9.8. <u>Qual é a duração do presente acordo?</u> (Durante o tempo
        que
        o utilizador utilizar os Serviços GFMemories ou até a sua conta
        ser encerrada).
      </p>
      <p>
        As presentes condições entram em vigor na data da primeira
        aceitação e serão
        válido enquanto o utilizador utilizar os serviços GFMemories ou
        enquanto o utilizador
        manter uma conta sobre estes. <strong>Sem</strong>.
        <strong>
          No entanto, as cláusulas correspondentes à Entrada de
          Mercadorias e à
          Pagamentos efectuados para obter os itens correspondentes
          e a sua política anti-reembolso será permanente.
        </strong>
      </p>
      <p>
        <strong>
          O UTILIZADOR RECONHECE E CONCORDA QUE LEU E COMPREENDEU ESTES
          TERMOS E QUE, AO
          SELECCIONAR O BOTÃO "ACEITAR" ABAIXO, OU QUANDO UTILIZAR OU
          ACEDER AO
          GFMemories SERVICES, COMPROMETE-SE A CUMPRIR OS TERMOS E
          CONDIÇÕES DE
          CONDIÇÕES ESTABELECIDAS NAS PRESENTES CONDIÇÕES.
        </strong>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
export default {
  name: 'TermsView',
  components: {
    BannerTitle
  },
  setup (){
    const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
   return {}
  }
}
</script>
<style scoped>
.termsPage {
  background-color: rgb(255, 255, 255, 0.6);
}
</style>