<template>
  <BannerTitle :msg="$t('profile')"></BannerTitle>
  <div class="flex justify-content-center w-full">
    <div class="w-10">
      <ul class="nav nav-tabs navigatorProfile">
        <li class="nav-item">
          <a class="nav-link" @click="updateActive(2)" :class="{ active: active == 2 }" aria-current="page">{{ $t('security') }}</a>
        </li>
      </ul>
      <ChangePassword></ChangePassword>
    </div>
  </div>
</template>
  
<script>
import { ref } from "vue";
import BannerTitle from '@/components/banner-title/BannerTitle'
import ChangePassword from '@/components/forms/profile/changePassword.vue'
export default {
  name: 'ProfileView',
  components: {
    BannerTitle,
    ChangePassword
  },
  setup() {
    const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
    let active = ref(2);
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    };
    const updateActive = (val) => {
      active.value = val;
    };
    topPage()
    return {
      updateActive,
      active
    }
  },
}

</script>
  
<style scoped>
.navigatorProfile li {
  cursor: pointer;
}
</style>