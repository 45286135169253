<template>
  <div class="container-card-award header-title-text">
    <div class="progress border border-white" id="progress-scale-award" role="progressbar" aria-label="Basic example"
      aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
      <div id="progres-bar-scale-run" class="progress-bar" :style="style">
        <div class="font-medium" style="width: 100%">{{ progressAP }} MP</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'

export default {
  name: 'progress-bar-main',
  props: {
    msg: String,
    image: String,
    progressAP: Number,
    limit: Number,
  },
  setup(props) {
    let bgImageFoo = ref(bgImageFooter)
    let style = ref('null')
    let ap = ref(0)
    let progressBarCalculate = ref('')
    const calculateAPProgress = () => {
      progressBarCalculate.value = (parseInt(props.progressAP) * 100) / (props.limit || 50000)
    }
    calculateAPProgress()
    style = `width: ${progressBarCalculate.value}%`
    return {
      bgImageFoo,
      style,
      ap
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

.container-card-award {
  width: 196px;
}

.card-scale {
  width: 100%;
  height: 246px;
}

.image-card-scale {
  width: 100%;
  height: 100%;
}

.header-title-text span {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #ffffff;
}


#progress-scale-award.progress {
  background: transparent;
}

#progres-bar-scale-run.progress-bar {
  background: #e9952e
}</style>