<template>
  <div class="modal-overlays">
    <div class="modals">
      <div class="modal-content" style="padding:none;">
        <div class="modal-header border-bottom " :style="style" style="border-radius: 8px;padding:12px">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{ title }}</h1>
          <button type="button" class="btn-close" @click="closeModal(redirect, status)" aria-label="Close"></button>
        </div>
        <div class="modal-body flex justify-content-left" style="padding:12px">
          <span style="color:black">
            <h5>{{ content }}</h5>
          </span>
        </div>
        <div class="flex justify-content-end m-2">
          <button type="button" :style="style" @click="closeModal(redirect, status)"
            class="btn btn-secondary">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ref } from "vue";
export default {
  name: 'modal-main',
  props: {
    title: String,
    content: String,
    redirect: String,
    status: String
  },
  methods: {
    async closeModal(redirect, status) {
      this.$emit("close");
      if (status !== 'failed') {
        const scale = this.$router.currentRoute.value.query.scale;
        if (scale) {
          await this.$router.push({ name: `${redirect}`, query: {scale}, });
          this.$router.go();
        } else {
          await this.$router.push({ name: `${redirect}` });
          this.$router.go();
        }
      }
    },
  },
  setup() {
    let style = ref(null)
    //style.value = ` background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`;
    style = `background : linear-gradient(to right, #212121, #141414);boder: 3px solid #212121`
    return {
      style
    }
  }
};
</script>
  
<style scoped>
.modal-overlays {
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(209, 204, 204, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modals {
  width: 469px;
  background-color: white;
  color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

button {
  margin-top: 10px;
}
</style>
  