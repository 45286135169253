<template>
  <div class="canvas-container" ref="canvasContainer">
    <!-- El modelo .glb se renderizará en este contenedor -->
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  name: 'GltfViewer',
  props: {
    idMonster: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.loadMonsterModel();
  },
  methods: {
    async loadMonsterModel() {
      const modelUrl = await this.loadModel(this.idMonster);
      if (modelUrl) {
        this.initThreeJS(modelUrl);
      }
    },
    async loadModel(idMonster) {
      return `https://gfmemories.com/3d/${idMonster.toLowerCase()}.glb`;
    },
    initThreeJS(modelUrl) {
      const container = this.$refs.canvasContainer;

      // Escena
      const scene = new THREE.Scene();

      // Cámara
      const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
      camera.position.set(0, 0.25, 0.25); // Ajusta esta posición para acercar la cámara
      // Renderizador
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(renderer.domElement);

      // Controles de órbita
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 1;

      // Luz
      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(5, 10, 7.5);
      scene.add(directionalLight);

      // Carga del modelo .glb
      const loader = new GLTFLoader();
      loader.load(
        modelUrl,
        (gltf) => {
          scene.add(gltf.scene);
          this.animate(renderer, scene, camera, controls);
        },
        undefined,
        (error) => {
          console.error('An error happened while loading the glb model', error);
        }
      );

      // Ajustar el renderizado al redimensionar la ventana
      window.addEventListener('resize', () => {
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(container.clientWidth, container.clientHeight);
      });
    },
    animate(renderer, scene, camera, controls) {
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    }
  }
};
</script>

<style scoped>
.canvas-container {
  width: 300px;
  /* Tamaño fijo para asegurar que el canvas no se extienda más */
  height: 300px;
  margin: 0 auto;
  /* Centrar el canvas horizontalmente */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>