<template>
  <div class="card-container-patch cursor-pointer" @click="route(id)">
    <div class="card-header-patch">
      <div class="img-avatar-patch"> <img class="language-image-patch rounded-circle" :src="logo" alt=""></div>
      <div><span class="textDate">{{ dateFinal }}</span></div>
      <div class="text-chat-patch"></div>
    </div>
    <div class="card-body-patch">
      <div class="messages-container-patch">
        <p class="card-title-patch">GRAND FANTASIA MEMORIES</p>
        <div class="message-box-patch">
          <p class="title-card-new">{{ $t('lastMoficationsTextPatch') }}</p>
          <p class="text-card-bdy truncate">{{ descriptionParsed ? descriptionParsed :
            $t('lastMoficationsTextPatchDescription') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { ref } from 'vue';
import { calculateElapsedTime } from '@/mixins/utilities.js'
import logoBanner from '@/assets/images/logoexe.png'
export default {
  name: 'card-news',
  props: {
    id: String,
    title: String,
    descriptionHtml: String,
    createDate: String,
  },
  methods: {
    async route(id) {
      await this.$router.push({ name: 'newsForm', params: { id } });
    },
  },
  setup(props) {
    let logo = ref(logoBanner);
    const estimatedTime = ref('');
    const estimatedTimeText = ref('');
    let dateString = ref('')
    let dateFinal = ref('')
    let descriptionParsed = ref('');

    const lines = props.descriptionHtml.split('\n');

    // Remover la primera línea
    lines.shift();
    descriptionParsed.value = lines.join('\n')
    const time = calculateElapsedTime(props.createDate);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    dateString.value = new Date(props.createDate)
    dateFinal.value = dateString.value.toLocaleDateString(`${localStorage.getItem('language').toUpperCase()}-${localStorage.getItem('language').toUpperCase()}`, options)
    estimatedTime.value = time[0]
    estimatedTimeText.value = time[1]
    return {
      estimatedTime,
      estimatedTimeText,
      logo,
      dateString,
      dateFinal,
      descriptionParsed
    };
  },
};
</script>
<style>
@import './styles.css';

.truncate {
  display: -webkit-box;
  /* Usar un contenedor flexible */
  -webkit-box-orient: vertical;
  /* Orientar las cajas verticalmente */
  overflow: hidden;
  /* Ocultar el texto que se desborda */
  text-overflow: ellipsis;
  /* Mostrar el ellipsis al final */
  -webkit-line-clamp: 3;
  /* Limitar el contenido a 3 líneas */
  line-height: 1.5em;
  /* Definir la altura de la línea */
  max-height: 4.5em;
  /* Altura máxima permitida (3 líneas * altura de línea) */
}
</style>