<template>
  <div class="container-movil">
    <div class="hero">
      <img src="https://www.gfmemories.com/images/logo20x20.png" alt="App Preview">
      <h1>Grand Fantasia Memories</h1>
      <p>Get ready to experience the future app Memories GF</p>
    </div>

    <div class="content">
      <h2>Coming Soon to iOS & Android</h2>
      <p>We are thrilled to announce that our iOS and Android app is under development, and we can't wait to share it with you!</p>

      <p>While we are putting the finishing touches on our app, we kindly request that you access it from a computer for the best experience.</p>

      <p>In the meantime, stay tuned for updates and be among the first to know when our app launches!</p>
	<p>
Please, we appreciate that you enter from a desktop device.</p>

    </div>
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'BuildApp',
  components: {
  },
  setup() {
   
    return {
    }
  },
}
</script>
<style>
.container-movil {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.hero img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.hero h1 {
  font-size: 40px;
  margin-bottom: 10px;
  color: #333;
}

.hero p {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
}

.content {
  text-align: left;
}

.content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.content p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.cta {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.cta a {
  display: inline-block;
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta a:hover {
  background-color: #0056b3;
}

.cta button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
</style>
