<template>
    
    <form onsubmit="return false" id="register-form" class="container">
        <div class="container">
            <div class="row g-0">
                <div class="message-error-send-form mb-2">{{ messageErrorSendForm }}</div>
                <div class="form-floating mb-3 md:w-12">
                    <input type="text" autocomplete="off" class="form-control" v-on:focusout="validateUsername" v-model="serviceForm.account.mid" :class="(validUser == 'true') ? ' is-valid': (validUser == 'false')?' is-invalid': ' '" id="floatingInputNickName" placeholder="name@example.com">
                    <label for="floatingInputNickName">Usuario</label>
                    <div :class="(validUser == 'true') ? ' valid-feedback': (validUser == 'false')?' invalid-feedback': ' '">
                        {{ validationsMessagesForm.account.mid.message }}
                    </div>
                </div>
                <div class="form-floating mb-3 md:w-12">
                    <input type="email" autocomplete="off" class="form-control" v-on:focusout="validateEmail" v-model="serviceForm.email" :class="(validEmail == 'true') ? ' is-valid': (validEmail == 'false')?' is-invalid': ' '" id="floatingInputEmail" placeholder="name@example.com">
                    <label for="floatingInputEmail">Email address</label>
                    <div  :class="(validEmail == 'true') ? ' valid-feedback': (validEmail == 'false')?' invalid-feedback': ' '">
                        {{ validationsMessagesForm.email.message }}
                    </div>
                </div>
                <div class="form-floating pe-2 md:w-6 sm:w-12 lg:w-6">
                    <input type="password" autocomplete="off" class="form-control" v-on:focusout="validatePassword" v-model="serviceForm.account.password" :class="(validPassword == 'true') ? ' is-valid': (validPassword == 'false')?' is-invalid': ' '" id="floatingPassword" :placeholder="$t('password')">
                    <label for="floatingPassword">{{ $t('password') }}</label>
                    <div :class="(validPassword == 'true') ? ' valid-feedback': (validPassword == 'false')?' invalid-feedback': ' '">
                        {{ validationsMessagesForm.account.password.message }}
                    </div>
                </div>
                <div class="form-floating md:w-6 sm:w-12 lg:w-6">
                    <input type="password" autocomplete="off" class="form-control"  v-on:focusout="validatePwd" v-model="serviceForm.account.pwd" :class="(validPwd == 'true') ? ' is-valid': (validPwd == 'false')?' is-invalid': ' '" id="floatingPwd" :placeholder="$t('password')">
                    <label for="floatingPwd">Confirm password</label>
                    <div :class="(validPwd == 'true') ? ' valid-feedback': (validPwd == 'false')?' invalid-feedback': ' '">
                        {{ validationsMessagesForm.account.pwd.message }}
                    </div>
                </div>
            </div>
        </div>
        <ButtonCustomGF id="buttonRegister" class="ms-3 mt-4" :class="!(validEmail&&validPassword&&validUser&&validPwd)?' disabled':' '" @click="sendContactForm" msg="Crear Cuenta" > </ButtonCustomGF>
    </form>
</template>

<script>
// @ is an alias to /src

import { ref } from "vue";
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import service from '@/mixins/service.js'
export default {
name: 'change-name-main',
mixins: [service],
components:{
    ButtonCustomGF
},
setup() {
    let messageErrorSendForm = ref('')
    let validUser = ref('')
    let validEmail = ref('')
    let validPassword = ref('')
    let validPwd = ref('')
    let validationsMessagesForm = ref({
        account : {
            mid: {
                required: 'usuario es requerido',
                exist: 'El usuario ya existe',
                message:'',
            },  
            password : {
                required: 'contraseña requerida',
                lengthMin: 'es necesario 8 caracteres minimo',
                upperCase: 'debe contener almenos una mayuscula.',
                message:'',
            },
            pwd : {
                required: 'contraseña requerida',
                match : 'la contraseña no coincide',
                message:'',
            },
        },
        email: {
            required: 'email es requerido',
            valid: 'email no valido',
            validDomain: 'dominio del correo electronico no valido (solo aceptamos gmail.com - outlook.com - hotmail.com - yahoo.com)',
            validPlusSymbol: 'el email no puede contener un simbolo "+"',
            existe: 'email ya ha sido utilizado',
            message:'',
        } 
    })
    let serviceForm = ref({
    account: {
        mid: '',
        password : '',
        pwd: '',
        pvalues:0,
        status :0
    },
    email: '',
})
  const createContactForm = async () => {
  const data = {
    path: '/services',
    data: serviceForm,
    method: 'POST',
  }
  const resp = await service.methods.callServiceFormDataServices(data)
  if (resp.name) {
    alert('Cuenta creada correcatmente')
    document.getElementById('register-form').reset()
  }
}
const validateUsername = () => {
   if(serviceForm.value.account.mid.length ) {
    validUser.value = 'true'
    validationsMessagesForm.value.account.mid.message = ''
   }else{
    validUser.value = 'false'
    validationsMessagesForm.value.account.mid.message = validationsMessagesForm.value.account.mid.required
   }
}
const validatePassword = () => {
   if(serviceForm.value.account.password.length ) {
    if(serviceForm.value.account.password.length >= 8){
        let regexPass = new RegExp("(?=.*?[A-Z])")
        if(regexPass.test(serviceForm.value.account.password)){
        validPassword.value = 'true'
        validationsMessagesForm.value.account.password.message = ''
        }else{
            validPassword.value = 'false'
            validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.upperCase
        }
  
    }else{
        validPassword.value = 'false'
        validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.lengthMin
    }
   }else{
    validPassword.value = 'false'
    validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.mid.required
   }
}
const validatePwd = () => {
    if(serviceForm.value.account.pwd.length){
        if(serviceForm.value.account.pwd==serviceForm.value.account.password){
            validPwd.value = 'true'
            validationsMessagesForm.value.account.pwd.message = ''
        }else{
            validPwd.value = 'false'
            validationsMessagesForm.value.account.pwd.message = validationsMessagesForm.value.account.pwd.match
        }
    }else{
        validPwd.value = 'false'
        validationsMessagesForm.value.account.pwd.message = validationsMessagesForm.value.account.pwd.required
    }
}
const validateEmail = () => { 
   if(serviceForm.value.email.length ) {
    let regexEmail = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$") //eslint-disable-line
    if(regexEmail.test(serviceForm.value.email)){
        let arrayEmail = serviceForm.value.email.split('@')
        if((arrayEmail[1] == 'gmail.com' || arrayEmail[1] == 'gmail.es') || (arrayEmail[1] == 'outlook.com' || arrayEmail[1] == 'outlook.es')
        || (arrayEmail[1] == 'msn.com' || arrayEmail[1] == 'msn.es') || (arrayEmail[1] == 'yahoo.com' || arrayEmail[1] == 'yahoo.es') 
        || (arrayEmail[1] == 'hotmail.com' || arrayEmail[1] == 'hotmail.es') 
        ){
            if(serviceForm.value.email.includes("+")){
                validEmail.value = 'false'
                validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validPlusSymbol
            }else{
                validEmail.value = 'true'
                validationsMessagesForm.value.email.message = ''
            }
        }else {
            validEmail.value = 'false'
            validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.validDomain
        }
        
    }else{
        validEmail.value = 'false'
        validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.valid
    }

   }else{
    validEmail.value = 'false'
    validationsMessagesForm.value.email.message = validationsMessagesForm.value.email.required
   }
}
const validateAllForm = () => {
   if (validEmail.value=='true'&&validPwd.value=='true'&&validPassword.value=='true'&&validUser.value=='true') return true; else return false
}
const sendContactForm = () => {
    if (validateAllForm()){
        createContactForm()
    }else{
        messageErrorSendForm.value = "El formulario que desea enviar no es valido! verifique porfavor que cumpla con los requisitos establecidos"
    }
  
}
  const topPage = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0
  }
  topPage()
  return {
    serviceForm,
    sendContactForm,
    validationsMessagesForm,
    validateUsername,
    validateEmail,
    validUser,
    validEmail,
    validPassword,
    validPwd,
    validatePassword,
    validatePwd,
    messageErrorSendForm,
  }
},
}
</script>
<style scoped>
.img-bg{
  padding-left:15%;
  width:85%;
  height:80%;
}

.cards-updates-double{
  display:grid;
  grid-template-columns: repeat(2,1fr);
  margin:5%;
  gap:10%;
}
.message-error-send-form {
    font-family : Montserrat Regular;
    font-size : 14px;
    color : #FFFFFF;
    color : rgb(255, 255, 255);
}
#buttonRegister.disabled {
    opacity: 0.4;
}
</style>
