<template>
    <div class="item-view-main">
        <div class="flex justify-content-center">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//import BannerTitle from '@/components/banner-title/BannerTitle'
export default {
    name: 'ItemView',
    components: {
        //  BannerTitle,
    },
    setup() {
        const isMobile = () => {
            return (
                (navigator.userAgent.match(/Android/i)) ||
                (navigator.userAgent.match(/webOS/i)) ||
                (navigator.userAgent.match(/iPhone/i)) ||
                (navigator.userAgent.match(/iPod/i)) ||
                (navigator.userAgent.match(/iPad/i)) ||
                (navigator.userAgent.match(/BlackBerry/i))
            );
        }
        const redirectWebBuildMovil = () => {
             
          //  let navegador = navigator.userAgent;
            let validator = isMobile()
            if (validator !== null) {
                 
                window.location.href = "/#/processing"
            }
        }
        redirectWebBuildMovil()
        return {
        }
    },
}
</script>

<style scoped>
.item-view-main {
    margin-left: 300px;
    height: 100%;
    color: floralwhite;
}
</style>