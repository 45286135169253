<template>
  <div :class="footerClass" class="footer container-footer flex p-2 justify-content-between">
    <img class="h-4rem" src="@/assets/images/logo_text.png" style="width: 500px;height: 400px;" alt="">
    <div class="flex flex-wrap justify-content-end">
      <span class="footer-text">{{ $t('copyright', [date]) }}</span>
      <a :href="'/#/terms'" class="w-full footer-text">{{ $t('terms') }}</a>
      <div class="w-full">
        <span>
          {{ $t('followFrom') }}
        </span>
        <a :href="youtubeUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-youtube.png" alt="Youtube">
        </a>
        <a :href="twitchUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-twitch.png" alt="Twitch">
        </a>
        <a :href="facebookUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-facebook.png" alt="Facebook">
        </a>
        <a :href="instagramUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-instagram.png" alt="Instagram">
        </a>
        <a :href="discordUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-discord.png" alt="Discord">
        </a>
        <a :href="tiktokUrl" target="_blank">
          <img class="h-2rem w-2rem mx-1" src="@/assets/images/1x/redes/icon-tiktok.png" alt="TikTok">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from 'vue-router';
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png';
import iconTwitch from '@/assets/images/1x/redes/icon-twitch.png';

export default {
  name: 'footer-main',
  props: {
    msg: String,
  },
  setup() {
    const route = useRoute();
    const date = ref(new Date().getFullYear());
    const bgImageFoo = ref(bgImageFooter);
    const twitchIcon = ref(iconTwitch);
    const twitchUrl = ref(process.env.VUE_APP_URL_TWITCH);
    const youtubeUrl = ref(process.env.VUE_APP_URL_YOUTUBE);
    const discordUrl = ref(process.env.VUE_APP_URL_DISCORD);
    const tiktokUrl = ref(process.env.VUE_APP_URL_TIKTOK);
    const facebookUrl = ref(process.env.VUE_APP_URL_FACEBOOK);
    const instagramUrl = ref(process.env.VUE_APP_URL_INSTAGRAM);
    const footerClass = computed(() => {
      return route.path.includes('/database/') ? 'footer item-data' : 'footer';
    });

    return {
      date,
      bgImageFoo,
      twitchIcon,
      youtubeUrl,
      discordUrl,
      tiktokUrl,
      twitchUrl,
      facebookUrl,
      instagramUrl,

      footerClass,
    };
  },
};
</script>

<style scoped>
.footer {
  background: black;
  border: 1px solid #212121;
}

.container-footer {
  font-family: Montserrat Regular;
  text-align: right;
  font-size: 16px;
  color: #ffffff;
}

.item-data {
  padding-left: 280px !important;
}
</style>
