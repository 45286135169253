<template>
    <div>
        <!-- Botón para abrir el modal -->
        <button type="button" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#filterModal">
            {{ $t('filter') }}
        </button>

        <!-- Modal -->
        <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="filterModalLabel">{{ $t('filter') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Dropdown de calidad -->
                        <div class="form-group mb-3">
                            <label for="quality">{{ $t('labelQuality') }}</label>
                            <select v-model="selectedQuality" id="quality" class="form-select"
                                :class="selectedQualityClass">
                                <option v-for="(label, value) in qualities" :key="value" :value="value"
                                    :class="qualityClass(value)">
                                    {{ $t(label) }}
                                </option>
                            </select>
                        </div>

                        <!-- Dropdown de clase -->
                        <div class="form-group mb-3">
                            <label for="class">{{ $t('labelClass') }}</label>
                            <select v-model="selectedClass" id="class" class="form-select">
                                <option v-for="(label, value) in classOptions" :key="value" :value="value">
                                    {{ $t(label) }}
                                </option>
                            </select>
                        </div>

                        <!-- Input de nivel máximo -->
                        <div class="form-group mb-3">
                            <label for="maxLevel">{{ $t('labelMaxLevel') }}</label>
                            <input v-model="maxLevel" type="number" id="maxLevel" class="form-control" min="0"
                                max="100" />
                        </div>

                        <!-- Input de nivel mínimo -->
                        <div class="form-group mb-3">
                            <label for="minLevel">{{ $t('labelMinLevel') }}</label>
                            <input v-model="minLevel" type="number" id="minLevel" class="form-control" min="0"
                                max="100" />
                        </div>

                        <div class="form-check">
                            <input v-model="filterUnusedContent" class="form-check-input" type="checkbox" value=""
                                id="filterUnusedContent" checked>
                            <label class="form-check-label" for="filterUnusedContent">
                                {{ $t('filterUnusedContent') }}
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="clearFilters">
                            {{ $t('clearFilters') }}
                        </button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="emitFilters">
                            {{ $t('applyFilters') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedQuality: '',
            selectedClass: '',
            maxLevel: 100,
            minLevel: 0,
            filterUnusedContent: true,
            qualities: {
                "1": 'qualityGray',
                "0": 'qualityWhite',
                "2": 'qualityGreen',
                "3": 'qualityBlue',
                "4": 'qualityOrange',
                "5": 'qualityYellow',
                "6": 'qualityPurple',
                "7": 'qualityRed',
            },
            classOptions: {
                0: 'titleNovato',
                1: 'titleLuchador',
                2: 'titleGuerrero',
                3: 'titleBerserker',
                4: 'titlePaladin',
                5: 'titleCazador',
                6: 'titleArquero',
                7: 'titleRanger',
                8: 'titleAsesino',
                9: 'titleAcolito',
                10: 'titleSacerdote',
                11: 'titleClerigo',
                12: 'titleSabio',
                13: 'titleHechicero',
                14: 'titleMago',
                15: 'titleMagoBrujo',
                16: 'titleNigromante',
                17: 'titleDW',
                18: 'titleHk',
                19: 'titleTh',
                20: 'titleAo',
                21: 'titleProfeta',
                22: 'titleMistico',
                23: 'titleAM',
                24: 'titleDM',
                25: 'titleMecanico',
                26: 'titleIngeniero',
                27: 'titleArtificiero',
                28: 'titleArtillero',
                29: 'titlePrime',
                30: 'titleOptimus',
                32: 'titleDK',
                33: 'titleCaballeroSagrado',
                34: 'titleBallestero',
                35: 'titleNinja',
                36: 'titleSanto',
                37: 'titleChaman',
                38: 'titleAvatar',
                39: 'titleAmoAlmas',
                48: 'titleMegatron',
                49: 'titleGalvatron',
                52: 'titleViajero',
                53: 'titleNomada',
                54: 'titleDuelista',
                55: 'titleRelojero',
                56: 'titleSamurai',
                57: 'titleMaestroTiempo',
                58: 'titleDimensionalista',
                59: 'titleOraculo',
            }
        };
    },
    computed: {
        selectedQualityClass() {
            return this.qualityClass(this.selectedQuality);
        }
    },
    methods: {
        emitFilters() {
            this.$emit('apply-filters', {
                quality: this.selectedQuality,
                class: this.selectedClass,
                minLevel: this.minLevel,
                maxLevel: this.maxLevel,
                filterUnusedContent: this.filterUnusedContent,
            });
        },
        clearFilters() {
            this.selectedQuality = '';
            this.selectedClass = '';
            this.maxLevel = 100;
            this.minLevel = 0;
            this.filterUnusedContent = true;
            this.$emit('clear-filters');
        },
        qualityClass(quality) {
            switch (quality) {
                case "1":
                    return 'quality-gray';
                case "0":
                    return 'quality-white';
                case "2":
                    return 'quality-green';
                case "3":
                    return 'quality-blue';
                case "4":
                    return 'quality-orange';
                case "5":
                    return 'quality-yellow';
                case "6":
                    return 'quality-purple';
                case "7":
                    return 'quality-red';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.modal-content {
    background-color: #090909;
}

.quality-gray {
    color: #1a1a1a !important;
}

.quality-white {
    color: #6c757d !important;
}

.quality-green {
    color: #218838 !important;
}

.quality-blue {
    color: #0056b3 !important;
}

.quality-orange {
    color: #e8590c !important;
}

.quality-yellow {
    color: #d39e00 !important;
}

.quality-purple {
    color: #5a3e91 !important;
}

.quality-red {
    color: #c82333 !important;
}

button {
    background-color: #e9952e !important;
}
</style>
