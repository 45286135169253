<template>
  <img @load="handleImageLoad" :src="backgroundImage" alt="" class="backgroundImage" :class="{ '': isLoaded }">
  <div class="flex justify-content-end align-items-center">
    <div class="relative md:absolute inset-0 flex justify-center items-center mr-0 w-full">
      <div :style="style2" style="width:100%; height: 600px" class="">
        <div style="margin-top: 3%;" class="relative md:absolute inset-0 flex justify-center items-center ml-8">
          <div id="carouselExample" class="carousel slide containerVideo">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <iframe class="border-round containerVideo1 ml-8" frameborder="0"
                  src="https://www.youtube.com/embed/0srgxkxJW3M">
                </iframe>
              </div>
              <div class="carousel-item">
                <iframe class="border-round containerVideo1 ml-8 " frameborder="0"
                  src="https://www.youtube.com/embed/Wj2k2PocXW4">
                </iframe>
              </div>
              <div class="carousel-item">
                <iframe class="border-round containerVideo1 ml-8 " frameborder="0"
                  src="https://www.youtube.com/embed/_0YoJfTmFOM" title="#GFMemories // FIRST BEAST TOWER, SEASON 2">
                </iframe>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import logoBanner from '@/assets/images/1x/logo-home-2.png'
import backgroundImage from '@/assets/images/background.png'
import backgroundImageCenter from '@/assets/images/banner-home.png'
import service from "@/mixins/service";

export default {
  name: 'banner-main',
  mixins: [service],
  components: {
  },
  mounted() {

  },
  data() {
    return {
      isLoaded: false,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    handleImageLoad() {
      this.isLoaded = true;
    },
  },
  setup() {
    let logo = ref(logoBanner);
    let banners = ref([]);
    let style = ref("null");
    let style2 = ref("null");

    const getBanners = async () => {
      const data = {
        path: '/config?param=banner',
        method: 'GET',
      }
      const listBanners = await service.methods.callService(data);
      if (listBanners[0]) {
        listBanners[0].active = true;
      }
      banners.value = listBanners;
    }
    //style = ` background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`;
    style = ` background : url(${backgroundImage}); background-repeat: no-repeat;
  background-size: cover;`;
    style2 = ` background : url(${backgroundImageCenter}); background-repeat: no-repeat;
  background-size: cover;`;
    getBanners();
    return {
      banners,
      backgroundImage,
      backgroundImageCenter,
      style,
      style2,
      logo
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.containerVideo {
  width: 700px;
  height: 350px;
}

.relative {
  top: 150px;
}

.containerVideo1 {
  width: 540px;
  height: 350px;
}

.containerVideoMovile1 {
  min-width: 50%;
}

.containerVideoMovile {
  min-width: 100%;
}

.backgroundImage {
  min-width: 100%;
}

.loaded {
  transform: scale(0.98);
}

.loading-image {
  transition: transform 15s ease-out;
}

.image-banner-center {}

.titleB {
  color: #967EAC;
  font-size: 24px;
  text-align: center;
}
</style>
