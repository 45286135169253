<template>
    <div class="dark table-responsive">
        <div class="table-container">
            <div v-if="loading" class="overlay">
                <Spinner v-if="loading" :isLoading="loading" class="spinner"></Spinner>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="col-8">Name</th>
                        <th class="col-1">Level</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="item.id">
                        <td :class="checkExclusivity(item.id) ? 'exclusive' : ''">
                            <a :href="getItemUrl(item.id)" class="media item-link">
                                <div class="item-icon mr-2 tooltip1">
                                    <img :src="`https://gfmemories.com/images/itemicon/${item.icon?.toUpperCase()}.png`"
                                        alt="Item Icon" class="img-fluid" />
                                    <TooltipItemData
                                        :class="checkExclusivity(item.id) ? 'tooltip1-top tooltip2 exclusive' : 'tooltip1-top tooltip2'"
                                        style="border: 1px solid white;" :itemData="itemsTooltips[index]">
                                    </TooltipItemData>
                                </div>
                                <div class="item-name align-self-center">
                                    <span :class="`quality-${item.quality}`">
                                        {{ item.name }}
                                    </span>
                                </div>
                            </a>
                        </td>
                        <td :class="checkExclusivity(item.id) ? 'exclusive' : ''">{{ item.level || '∞' }}</td>
                    </tr>
                    <tr v-if="items.length === 0 && !loading">
                        <td colspan="2" class="text-center">No items found</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-controls">
                <label for="itemsPerPage">Items per page:</label>
                <select v-model="localItemsPerPage" @change="changeItemsPerPage" id="itemsPerPage">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                </select>

                <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/spinner/Spinner'
import TooltipItemData from '@/components/tooltip/TooltipItem.vue'

export default {
    name: 'ItemTable',
    components: { Spinner, TooltipItemData },
    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        itemsTooltips: {
            type: Array,
            required: true,
            default: () => [],
        },
        totalItems: {
            type: Number,
            required: true,
            default: 0,
        },
        currentPage: {
            type: Number,
            required: true,
            default: 1,
        },
        loading: {
            type: Boolean,
            required: true,
            default: true,
        }
    },
    data() {
        return {
            localItemsPerPage: 10, // Initial value for items per page
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.localItemsPerPage);
        },
    },
    methods: {
        checkExclusivity(id) {
            return (id >= 5200 && id <= 7000 || id >= 54336 && id <= 60000);
        },
        getItemUrl(id) {
            return this.$router.resolve({ name: 'itemSingleView', params: { id_item: id } }).href;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('changePage', this.currentPage + 1);
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.$emit('changePage', this.currentPage - 1);
            }
        },
        changeItemsPerPage() {
            this.$emit('changeItemsPerPage', this.localItemsPerPage);
        }
    }
};
</script>

<style scoped>
.table-container {
    position: relative;
}

.table {
    width: 100%;
    min-width: 650px;
    margin-bottom: 1rem;
    color: #212529;
}

.media {
    display: flex;
    align-items: flex-start;
}

.table tr {
    cursor: pointer;
}

.table th,
.table td {
    vertical-align: middle;
    background-color: #464646;
    color: #FFFFFF;
}

.img-fluid {
    height: 32px;
    width: 32px;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.pagination-controls label {
    margin-right: 10px;
}

.pagination-controls select {
    margin-right: 20px;
}

.pagination-controls button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #e9952e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination-controls button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.pagination-controls span {
    font-size: 14px;
}

/* Overlay styling */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(12, 12, 12, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.exclusive {
    animation: glow 2s infinite alternate;
}

@keyframes glow {
    0% {
        box-shadow: inset 0 0 5px yellow;
    }

    50% {
        box-shadow: inset 0 0 10px yellow;
    }

    100% {
        box-shadow: inset 0 0 5px yellow;
    }
}

/* Tooltip Styling */
.tooltip1 {
    color: blue;
    text-decoration: none;
    position: relative;
    height: 100%;
}

.tooltip1 .tooltip2 {
    background: black;
    color: white;
    padding: 5px 8px;
    border-radius: 5px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.tooltip1:hover .tooltip2 {
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
}

.tooltip1:hover .tooltip1-top {
    bottom: 130%;
    z-index: 99999;
}

.tooltip1-top {
    left: 0;
    bottom: 0;
    right: 90%;
}

.tooltip1-top::after {
    content: "";
    display: block;
    background: black;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: absolute;
}

.tooltip1-bottom {
    left: 0;
    top: 130%;
}

.tooltip1-left {
    right: 110%;
    top: 0;
}

.tooltip1-right {
    left: 110%;
    top: 0;
}

/* CON JS */

.tooltip1-box {
    font-weight: bold;
    color: red;
    text-decoration: none;
    position: relative;
}

.tooltip1-info {
    background: black;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    position: fixed;
    visibility: hidden;
}

.tooltip1-box:hover .tooltip1-info {
    visibility: visible;
}

/* Link styling for hover */
.item-link {
    text-decoration: none;
}

.item-link:hover {
    text-decoration: underline;
}
</style>
