<template>
    <div class="col py-3">
        <BannerTitle msg="NPC Data"></BannerTitle>
        <MonsterData :id_monster="$route.params.id_monster"></MonsterData>
    </div>

</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
import MonsterData from '@/components/database/monsterdata/MonsterData.vue';

export default {
    name: 'ItemSingle',
    components: {
        BannerTitle,
        MonsterData
    },
    setup() {
        const isMobile = () => {
            return (
                (navigator.userAgent.match(/Android/i)) ||
                (navigator.userAgent.match(/webOS/i)) ||
                (navigator.userAgent.match(/iPhone/i)) ||
                (navigator.userAgent.match(/iPod/i)) ||
                (navigator.userAgent.match(/iPad/i)) ||
                (navigator.userAgent.match(/BlackBerry/i))
            );
        }

        const redirectWebBuildMovil = () => {
             
            let navegador = navigator.userAgent;
            console.log(navegador);
            let validator = isMobile()
            console.log(validator)
            if (validator !== null) {
                 
                window.location.href = "/#/processing"
            }
        }
        redirectWebBuildMovil()
        return {
        }
    },
}
</script>