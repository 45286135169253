<template>
  <BannerTitle :msg="$t('store')"></BannerTitle>
  <!-- <img class="img-bg" :src="bgImagebod" />-->
  <div class="cards-updates-double row flex justify-content-center m-0 mt-5">
    <div class="row flex justify-content-center m-0 w-9 flex-wrap">
      <div class="content_checkbox justify-content-center" style="display: flex; align-items: center;">
        <div class="checkbox-wrapper-12" >
        <div class="cbx">
          <input  v-model="terms_box" id="cbx-12"  type="checkbox"/>
          <label for="cbx-12"></label>
          <svg width="15" height="14" viewbox="0 0 15 14" fill="none">
            <path d="M2 8.36364L6.23077 12L13 2"></path>
          </svg>
        </div>
        <!-- Gooey-->
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo-12">
              <!-- <fegaussianblur in="SourceGraphic" stddeviation="4" result="blur"></fegaussianblur>
              <fecolormatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" result="goo-12"></fecolormatrix>
              <feblend in="SourceGraphic" in2="goo-12"></feblend> -->
            </filter>
          </defs>
        </svg>
       
      </div>
       <span class="text_checkbox ml-2 text-white">{{$t('checkedTermsBuy')}}</span>
      </div>
      <!-- <div class="flex w-full justify-content-center">
        <div class="m-5 alert alert-primary col-10 md:col-5 text-center" role="alert">
          <span class="fa fa-info-circle"></span> {{ $t('bonusApplied') }}
        </div>
      </div>-->
      
      <CardPlanBuy class="tab1Pay" :plan="prices.plans[0].name" :checkValid="terms_box" :bonus="prices.plans[0].bonus" :price="prices.plans[0].price" :mpPoints="prices.plans[0].mpPoints"
        :image="imgPay1" />
      <CardPlanBuy   class="tab1Pay" :plan="prices.plans[1].name" :checkValid="terms_box" :bonus="prices.plans[1].bonus" :price="prices.plans[1].price" :mpPoints="prices.plans[1].mpPoints"
        :image="imgPay2" />
      <CardPlanBuy   class="tab1Pay" :plan="prices.plans[2].name" :checkValid="terms_box" :bonus="prices.plans[2].bonus" :price="prices.plans[2].price" :mpPoints="prices.plans[2].mpPoints"
        :image="imgPay3" />
      <CardPlanBuy  class="tab1Pay" :plan="prices.plans[3].name" :checkValid="terms_box" :bonus="prices.plans[3].bonus" :price="prices.plans[3].price" :mpPoints="prices.plans[3].mpPoints"
        :image="imgPay4" />
      <CardPlanBuy  class="tab1Pay"  :plan="prices.plans[4].name" :checkValid="terms_box" :bonus="prices.plans[4].bonus" :price="prices.plans[4].price" :mpPoints="prices.plans[4].mpPoints"
        :image="imgPay5" />
      <CardPlanBuy  class="tab1Pay" :plan="prices.plans[5].name" :checkValid="terms_box" :bonus="prices.plans[5].bonus" :price="prices.plans[5].price" :mpPoints="prices.plans[5].mpPoints"
        :image="imgPay6" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import BannerTitle from '@/components/banner-title/BannerTitle'
import CardPlanBuy from '@/components/forms/store/CardPlanBuy'
import { ref } from "vue";
import plan1 from '@/assets/images/1x/1.png'
import plan2 from '@/assets/images/1x/2.png'
import plan3 from '@/assets/images/1x/3.png'
import plan4 from '@/assets/images/1x/4.png'
import plan5 from '@/assets/images/1x/5.png'
import plan6 from '@/assets/images/1x/6.png'
export default {
  name: 'PlansPayMPView',
  components: {
    CardPlanBuy,
    BannerTitle,
  },
  setup() {
    const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
    let prices = ref({
      plans: [
        {
          name:'plan1',
          mpPoints: 5000,
          bonus: 0,
          price: 10
        },
        {
          name:'plan2',
          mpPoints: 10000,
          bonus: 0,
          price: 20
        },
        {
          name:'plan3',
          mpPoints: 15000,
          bonus: 0,
          price: 30
        },
        {
          name:'plan4',
          mpPoints: 20000,
          bonus: 0,
          price: 40
        },
        {
          name:'plan5',
          mpPoints: 25000,
          bonus: 0,
          price: 50
        },
        {
          name:'plan6',
          mpPoints: 30000,
          bonus: 0,
          price: 60
        },
      ]
    })
    let imgPay1 = ref(plan1)
    let imgPay2 = ref(plan2)
    let imgPay3 = ref(plan3)
    let imgPay4 = ref(plan4)
    let imgPay5 = ref(plan5)
    let imgPay6 = ref(plan6)
    let terms_box = ref(false)
    prices.value.plans.forEach(element => {
      element.bonus = element.mpPoints * 0.10
    })
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      imgPay1,
      imgPay2,
      imgPay3,
      imgPay4,
      imgPay5,
      imgPay6,
      prices,
      terms_box
    }
  },
}
</script>
<style scoped>
.content_checkbox {
  display: flex;

}
@media (min-width: 560px) {
  .content_checkbox {
    margin-left: 10%;
}
  .text_checkbox {
    font-size: 14px;
  }
}

  .checkbox-wrapper-12 {
    vertical-align: middle;
    position: relative;
  }
  .checkbox-wrapper-12 > svg {
    position: absolute;
    top: -130%;
    left: -170%;
    width: 110px;
    pointer-events: none;
  }
  .checkbox-wrapper-12 * {
    box-sizing: border-box;
  }
  .checkbox-wrapper-12 input[type="checkbox"] {
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    margin: 0;
  }
  .checkbox-wrapper-12 input[type="checkbox"]:focus {
    outline: 0;
  }
  .checkbox-wrapper-12 .cbx {
    width: 24px;
    height: 24px;
    top: calc(50vh - 12px);
    left: calc(50vw - 12px);
  }
  .checkbox-wrapper-12 .cbx input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: 2px solid #bfbfc0;
    border-radius: 50%;
  }
  .checkbox-wrapper-12 .cbx label {
    width: 24px;
    height: 24px;
    background: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-filter: url("#goo-12");
    filter: url("#goo-12");
    transform: trasnlate3d(0, 0, 0);
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg {
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 1;
    pointer-events: none;
  }
  .checkbox-wrapper-12 .cbx svg path {
    stroke: #fff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 19;
    stroke-dashoffset: 19;
    transition: stroke-dashoffset 0.3s ease;
    transition-delay: 0.2s;
  }
  .checkbox-wrapper-12 .cbx input:checked + label {
    animation: splash-12 0.6s ease forwards;
  }
  .checkbox-wrapper-12 .cbx input:checked + label + svg path {
    stroke-dashoffset: 0;
  }
  @-moz-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-webkit-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @-o-keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
  @keyframes splash-12 {
    40% {
      background: #866efb;
      box-shadow: 0 -18px 0 -8px #866efb, 16px -8px 0 -8px #866efb, 16px 8px 0 -8px #866efb, 0 18px 0 -8px #866efb, -16px 8px 0 -8px #866efb, -16px -8px 0 -8px #866efb;
    }
    100% {
      background: #866efb;
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
    }
  }
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}
.tab1Pay{
  transition: transform .2s; /* Animation */
}
.tab1Pay:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.cards-updates-double {
  margin-left: 8%;
  margin-top: 3%;
}</style>
