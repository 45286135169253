<template>
  <banner-title :msg="$t('titlePatch')" :src="newDetails.image"></banner-title>
  <div class="flex justify-content-center">
    <div class="flex flex-wrap justify-content-center w-10 background-news border-round-bottom">
      <img class="block border-round py-3" style="height: 15rem;" :alt="newDetails.description" />
      <div class="w-full pb-5 px-7 news-description border-round-bottom">
        <div class="flex py-2 justify-content-end font-medium">{{ $t(estimatedTimeText, [estimatedTime]) }}</div>
        <div class="marked-content" v-html="formatMessage(text)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerTitle from '@/components/banner-patch/BannerTitle';
import service from "@/mixins/service";
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import { calculateElapsedTime } from '@/mixins/utilities';
import { marked } from 'marked';

marked.setOptions({
  breaks: true, // Esto permite que los saltos de línea simples se conviertan en <br>
});

export default {
  name: 'NewsForm',
  mixins: [service],
  components: {
    'banner-title': BannerTitle,
  },
  methods: {
    removeCustomEmojis(text) {
      // Eliminar custom emojis del tipo <:emoji_name:emoji_id> o <a:emoji_name:emoji_id>
      text = text.replace(/<a?:\w+:\d+>/g, '');

      // Eliminar las menciones del tipo <@userID> o <@!userID>
      text = text.replace(/<@!?[\d]+>/g, '');

      // Eliminar menciones a roles del tipo <@&roleID>
      text = text.replace(/<@&[\d]+>/g, '');

      // Eliminar menciones como @everyone o @here
      text = text.replace(/@(?:everyone|here)/g, '');

      return text;
    },

    formatMessage(content) {
      const cleanedContent = this.removeCustomEmojis(content);
      return marked(cleanedContent);
    }
  },
  setup() {
    let newDetails = ref({});
    let text = ref('');
    let estimatedTime = ref('');
    let estimatedTimeText = ref('');
    const route = useRoute();
    const getNews = async (id) => {
      const data = {
        path: `/patchnotes/findById/${id}`,
        method: 'GET',
      }
      const currentNew = await service.methods.callService(data);
      newDetails.value = currentNew;
      const time = calculateElapsedTime(currentNew.date);
      estimatedTime.value = time[0];
      estimatedTimeText.value = time[1];
      text.value = currentNew.content;
      return currentNew
    }
    if (route.params.id) {
      getNews(route.params.id)
    }
    return {
      newDetails,
      estimatedTime,
      estimatedTimeText,
      text
    }
  },
}

</script>
<style>
@import './styles.css';

.marked-content {
  white-space: pre-wrap;
  /* Mantiene los saltos de línea y los espacios */
}
</style>