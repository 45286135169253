<template>
  <div :data-tooltip="msg" class="button buttonBuy disabled">
<div class="button-wrapper">
  <div class="text">{{$t('buyText',[msg])}}</div>
    <span class="icon">
      <svg viewBox="0 0 16 16" class="bi bi-cart2" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
</svg>
    </span>
  </div>
</div>
</template>
<script>
import { ref } from "vue";
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'

export default {
  name: 'button-pay-main',
  props: {
    msg: String,
  },
  setup() {
    let bgImageFoo = ref(bgImageFooter)
    let style = ref('null')
    //style = ` background : linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `background : linear-gradient(to right, #212121, #141414);`
    return {
      bgImageFoo,
      style,
    };
  },
};
</script>
<style>

.buttonBuy {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: #222;
  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  font-family: "Arial";
  transition: background 0.3s;
  cursor:pointer
}

.buttonBuy::before {
  position: absolute;
  content: attr(data-tooltip);
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  background-color: #555;
  font-size: 0.9rem;
  color: #fff;
  border-radius: .25em;
  line-height: var(--tooltip-height);
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
  left: calc(50% - var(--tooltip-width) / 2);
}

.buttonBuy::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #555;
  left: calc(50% - 10px);
  bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.buttonBuy::after,.buttonBuy::before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-wrapper,.text,.icon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.text {
  top: 0
}

.text,.icon {
  transition: top 0.5s;
}

.icon {
  color: #fff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  width: 24px;
  height: 24px;
}

.buttonBuy:hover {
  background: #222;
}

.buttonBuy:hover .text {
  top: -100%;
}

.buttonBuy:hover .icon {
  top: 0;
}

.buttonBuy:hover:before,.buttonBuy:hover:after {
  opacity: 1;
  visibility: visible;
}

.buttonBuy:hover:after {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.buttonBuy:hover:before {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}

</style>