import fetchIntercept from 'fetch-intercept';

fetchIntercept.register({
  request: function (url, config) {
    let finalConfig = config;
    // Modify the url or config here
    if (!finalConfig) {
      finalConfig = {
        headers: {}
      }
    }
    const authToken = localStorage.getItem('access_token');
    if (authToken) {
      finalConfig.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return [url, finalConfig];
  },
  response: async (response) => {
    if (response.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('userCreate');
      localStorage.removeItem('access_token');
      localStorage.setItem('validateLogin', 'false');
      location.reload();
    }
    return response;
  }
});

export default {
  methods: {
    async callService(api) {
      const { method, data, path } = api;
      let { headers } = api;
      if (!headers) {
        headers = { 'Content-type': 'application/json; charset=UTF-8' };
      }
      let options = {
        method,
        headers,
      }
      switch (method) {
        case 'POST':
          options.body = JSON.stringify(data)
          break
      }
      try {
        const resp = await fetch(process.env.VUE_APP_HOST_BACKEND + path, options);
        return resp.json();
      } catch {
        return []
      }
    },
  },
}
