<template>
  <button type="button" class="btn rounded-pill" :style="style">
    <span class=" button-custom-gf">{{ msg }}</span>
  </button>
</template>
<script>
import { ref } from "vue";
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'

export default {
  name: 'button-main',
  props: {
    msg: String,
  },
  setup() {
    let bgImageFoo = ref(bgImageFooter)
    let style = ref('null')
    //style = ` background : linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    style = `background : linear-gradient(to right, #212121, #141414);`
    return {
      bgImageFoo,
      style,
    };
  },
};
</script>
<style>
.button-custom-gf-b{
  font-size : 18px;
  color : #FFFFFF;
}
</style>