<template>
  <Spinner v-if="isLoading" :isLoading="isLoading" />
  <div v-for="(currentNew) in news.slice(0, 3)" :key="currentNew.id">
    <card-news :id="currentNew.messageId" :title="'PATCH' + moment(currentNew.date)" :createDate="currentNew.date"
      :descriptionHtml="currentNew.content"></card-news>
  </div>
</template>

<script>
import { ref } from "vue";
import CardNews from '@/components/cards/news/card-news.vue';
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import iconTwitch from '@/assets/images/1x/redes/icon-twitch.png'
import { calculateElapsedTime } from '@/mixins/utilities';
import moment from 'moment';
import service from "@/mixins/service";
import Spinner from "../spinner/Spinner.vue";
export default {
  name: 'patch-section',
  props: {
    msg: String,
  },
  components: {
    'card-news': CardNews,
    'Spinner': Spinner
  },
  methods: {
    async route(id) {
      await this.$router.push({ name: 'newsForm', params: { id } });
    },
  },
  setup() {
    let news = ref([]);
    let estimatedTime = ref('');
    let estimatedTimeText = ref('');
    let isLoading = ref(false);
    const getNews = async () => {
      const lang = localStorage.getItem('language');
      isLoading.value = true;
      const data = {
        path: `/patchnotes/${lang === 'br' ? 'pt' : lang}`,
        method: 'GET',
      }
      const listNews = await service.methods.callService(data);

      news.value = await listNews;
      if (news?.value && news.value.length > 0) {
        const timeFirst = calculateElapsedTime(news.value[0].created_at);
        const timeSecond = calculateElapsedTime(news.value[1].created_at);
        const timeThird = calculateElapsedTime(news.value[2].created_at);
        news.value[0].estimatedTime = timeFirst[0];
        news.value[0].estimatedTimeText = timeFirst[1];
        news.value[1].estimatedTime = timeSecond[0];
        news.value[1].estimatedTimeText = timeSecond[1];
        news.value[2].estimatedTime = timeThird[0];
        news.value[2].estimatedTimeText = timeThird[1];
      }
      isLoading.value = false;
    }
    getNews()
    let bgImageFoo = ref(bgImageFooter);
    let twitchIcon = ref(iconTwitch);
    let youtubeUrl = ref(process.env.VUE_APP_URL_YOUTUBE);
    let discordUrl = ref(process.env.VUE_APP_URL_DISCORD);
    let tiktokUrl = ref(process.env.VUE_APP_URL_TIKTOK);
    let arrayPatch = ref(null)

    arrayPatch.value = [
      {
        id: 1,
      }
    ]
    return {
      getNews,
      bgImageFoo,
      twitchIcon,
      youtubeUrl,
      discordUrl,
      tiktokUrl,
      estimatedTime,
      estimatedTimeText,
      news,
      moment,
      isLoading
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only 
<style scoped>

.footer {
  background: linear-gradient(90deg, rgb(22, 17, 63) 0%, rgb(69, 0, 106) 42.86%, rgb(96, 0, 106) 63.8%, rgb(209, 28, 36) 100%);
}

.container-footer {
  font-family: Montserrat Regular;
  text-align: right;
  font-size: 16px;
  color: #ffffff;
}
</style>
-->
<style scoped></style>