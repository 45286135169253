<template>
<BannerTitle msg="Tickets"></BannerTitle>
<Tickets></Tickets>
</template>

<script>
import BannerTitle from '@/components/banner-title/BannerTitle'
import Tickets from '@/components/forms/tickets/Tickets'
export default {
   name: 'TicketsView' ,
   components: {
    BannerTitle,
    Tickets
},
setup() {
      const topPage = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0
      }
      const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
      topPage()
      return {
      }
    },
}

</script>

<style scoped>



</style>