<template>
    <BannerTitle :msg="$t('createAccountT')"></BannerTitle>
    <!-- <img class="img-bg" :src="bgImagebod" />-->
    <Register></Register>
  
  </template>
  
  <script>
  // @ is an alias to /src
  
  import Register from '@/components/forms/register/Register'
  import BannerTitle from '@/components/banner-title/BannerTitle'
  export default {
    name: 'RegisterView',
    components: {
      BannerTitle,
      Register,
    },
    setup() {
      const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
      const topPage = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0
      }
     
      topPage()
      return {
      }
    },
  }
  </script>
  <style scoped>
    .img-bg{
      padding-left:15%;
      width:85%;
      height:80%;
    }
    .cards-updates-double{
      display:grid;
      grid-template-columns: repeat(2,1fr);
      margin:5%;
      gap:10%;
    }
  </style>
  