<template>
  <nav class="navbar sticky-sm-top sticky-lg-top sticky-md-top navbar-expand-lg bg-body-tertiary shadow-lg">
    <div class="container-fluid" :style="style">
      <a class="navbar-brand hidden md:block"> <img class="header-text-share h-5rem" :src="logonavbar" alt=""></a>
      <a class="navbar-brand block md:hidden" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" href="#"> <img
          class="header-text-share h-5rem" :src="logonavbar" alt=""></a>
      <div class="collapse navbar-collapse px-4 md:px-0" id="navbarSupportedContent">
        <ul class="navbar-nav mt-3 justify-content-end w-full">
          <li class="nav-item header-title-text mx-2">
            <a class="nav-link header-menu-web" aria-current="page" href="#">{{ $t('home') }}</a>
          </li>
          <li class="nav-item header-title-text mx-2">
            <router-link to="/news" class="nav-link header-menu-web" aria-current="page" href="#">{{ $t('news')
              }}</router-link>
          </li>
          <li class="nav-item header-title-text mx-2">
            <router-link to="/database" class="nav-link header-menu-web" aria-current="page"
              href="#">Database</router-link>
          </li>
          <li class="nav-item header-title-text mx-2">
            <router-link to="/elo-tier" class="nav-link header-menu-web" aria-current="page"
              href="#">Rankeds</router-link>
          </li>
          <li class="nav-item dropdown dropbottom header-title-text mx-2">
            <a class="nav-link header-menu-web dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
              data-bs-auto-close="true" aria-expanded="false">{{ $t('download') }}</a>
            <ul class="dropdown-menu p-0 absolute" :style="style">
              <li class="header-title-text">
                <span> {{ $t('textDownloadComplete') }}</span>
              </li>
              <li class="header-title-text-download"><a class="dropdown-item py-2" :href="urlDownloadGDrive"
                  target="_blank"><span>Google Drive</span></a></li>
              <li class="header-title-text">
                <hr class="dropdown-divider">
              </li>
              <li class="header-title-text-download"><a class="dropdown-item py-2" target="_blank"
                  :href="urlDownloadMediafire"><span style="vertical-align: middle;">Mediafire</span></a></li>
              <li class="header-title-text py-2">
                <span> {{ $t('textDownloadGVG') }}</span>
              </li>
              <li class="header-title-text-download"><a class="dropdown-item py-2" target="_blank"
                  :href="urlDownloadGDriveGVG"><span>Google Drive </span></a></li>
              <li class="header-title-text">
                <hr class="dropdown-divider">
              </li>
              <li class="header-title-text-download"><a class="dropdown-item py-2" target="_blank"
                  :href="urlDownloadMediafireGVG"><span style="vertical-align: middle;">Mediafire</span></a></li>
            </ul>
          </li>
          <li v-if="validateLogin == 'true'" class="nav-item header-title-text mx-2">
            <router-link to="/store" class="nav-link header-menu-web" aria-current="page" href="#">{{ $t('store')
              }}</router-link>
          </li>
          <li v-if="validateLogin == 'true'" class="nav-item header-title-text mx-2 ">
            <router-link to="/scales" class="nav-link header-menu-web" aria-current="page" href="#">{{ $t('awards')
              }}</router-link>
          </li>
          <!-- <li class="nav-item header-title-text mx-2 ">
            <router-link to="/news" class="nav-link" aria-current="page" href="#">{{ $t('news') }}</router-link>
          </li> -->
          <!-- <li class="nav-item header-title-text mx-2">
            <router-link to="/" class="nav-link" aria-current="page" href="#">FORUM</router-link>
          </li> -->
          <!-- Helped by DesFid -->
          <li class="nav-item header-title-text mx-2">
            <router-link to="blank" target='_blank' class="nav-link header-menu-web" aria-current="page"
              :href="urlDiscord">{{
                $t('discord') }}</router-link>
          </li>
          <li class="nav-item header-title-text mx-2">
            <span class="nav-link header-menu-web" v-if="validateLogin == 'true'" aria-current="page">{{ account_name
              }}</span>
          </li>
          <li class="nav-item header-title-text mx-2"><span class="nav-link header-menu-web align-middle"
              v-if="validateLogin == 'true'" aria-current="page"><img class="mx-2" style="width: 30px;height: 20px;"
                :src="imgPay2" />{{ `${pvalues}` }}</span></li>
        </ul>
        <div class="flex" style="margin-right: 5%;">

          <ul class="navbar-nav w-full">

            <div class="w-full flex justify-content-end align-items-center">

              <li class="nav-item dropdown dropstart header-title-text mx-2">
                <a class="nav-link dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown"
                  data-bs-auto-close="true" aria-expanded="false">
                  <img class="language-image rounded-circle" :src="profileIcon" alt="">
                </a>
                <ul class="dropdown-menu p-0 absolute" :style="style" v-if="validateLogin == 'false'">
                  <li class="header-title-text"><router-link to="/register" class="dropdown-item py-2" href="#"><span>{{
                    $t('register') }}</span></router-link></li>
                  <li class="header-title-text">
                    <hr class="dropdown-divider">
                  </li>
                  <li class="header-title-text"><router-link to="/login" class="dropdown-item py-2" href="#"><span
                        style="vertical-align: middle;">{{ $t('login') }}</span></router-link></li>
                </ul>
                <ul class="dropdown-menu absolute" :style="style" v-if="validateLogin == 'true'">
                  <li class="header-title-text"><a href="/#/profile" class="dropdown-item py-2"><span
                        style="vertical-align: middle;">{{ $t('profile') }}</span></a></li>
                  <li class="header-title-text"><a href="#" @click="logOut()" class="dropdown-item py-2"><span
                        style="vertical-align: middle;">{{ $t('logout') }}</span></a></li>
                </ul>
              </li>
              <li class="nav-item dropdown header-title-text dropstart mx-2">
                <a class="nav-link dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown"
                  data-bs-auto-close="true" aria-expanded="false">
                  <img class="language-image" :src="languageImage" alt="">
                </a>
                <ul class="dropdown-menu p-0 disabled absolute" :style="style">
                  <li class="header-title-text"><a class="dropdown-item" href="javascript:void(0);"
                      @click="changeLanguage('es')"><img class="img-lang" :src="spainIcon" alt=""><span
                        style="vertical-align: middle;">Spanish</span></a>
                  </li>
                  <li class="header-title-text">
                    <hr class="dropdown-divider">
                  </li>
                  <li class="header-title-text"><a class="dropdown-item py-2" href="javascript:void(0);"
                      @click="changeLanguage('br')"><img class="img-lang" :src="brIcon" alt=""><span
                        style="vertical-align: middle;">Portuguese</span></a>
                  </li>
                  <li class="header-title-text">
                    <hr class="dropdown-divider">
                  </li>
                  <li class="header-title-text"><a class="dropdown-item" href="javascript:void(0);"
                      @click="changeLanguage('en')"><img class="img-lang" :src="usaIcon" alt=""><span
                        style="vertical-align: middle;">English</span></a>
                  </li>
                  <li class="header-title-text">
                    <hr class="dropdown-divider">
                  </li>
                  <li class="header-title-text"><a class="dropdown-item" href="javascript:void(0);"
                      @click="changeLanguage('fr')"><img class="img-lang" :src="frenchIcon" alt="usaIcon"><span
                        style="vertical-align: middle;">French</span></a></li>
                </ul>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <SideBar></SideBar>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t('loginSuccess')" @close="showModal = false"></modalMessage>
</template>
<script>
import iconBR from "@/assets/images/1x/br-icon.png";
import iconUSA from "@/assets/images/1x/en-icon.png";
import iconSpain from "@/assets/images/1x/es-icon.png";
import iconFrench from "@/assets/images/1x/fr-icon.png";
import logoNavbar from '@/assets/images/logo_navbar.png';
import bgImageFooter from "@/assets/images/1x/nav-bar-background.png";
import iconProfile from "@/assets/images/1x/perfil.png";
import plan2 from '@/assets/images/1x/icon-coin.png'
import modalMessage from '@/components/modal/modalMessage';
import { ref } from "vue";
import SideBar from "@/components/database/sidebar/SideBar.vue";
import service from '@/mixins/service.js'
export default {
  name: "header-main",
  components: {
    modalMessage,
    SideBar
  },
  mixins: [service],
  props: {
    msg: String,
    disconectSocket: Function
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
      this.language = lang;
      const url = window.location.href;
      const isHomePage = window.location.pathname === '/' && !window.location.hash;
      this.updateImage();

      const isHashHomePage = window.location.hash === '#/' || window.location.hash === '';
      if (isHomePage || isHashHomePage) {
        window.location.reload();
        return;
      }
      if (url.includes('/database/') || url.includes('/news')) {
        if (url.includes('/news/')) {
          window.location.href = '/#/news';

          return;
        }
        window.location.reload();
      }
    },
  },
  setup(props) {
    let language = ref(localStorage.getItem('language') || 'en');
    let languageImage = ref(iconSpain);
    const updateImage = () => {
      let image = iconSpain;
      const lang = language.value;
      switch (lang) {
        case 'es':
          image = iconSpain;
          break;
        case 'en':
          image = iconUSA;
          break;
        case 'br':
          image = iconBR;
          break;
        case 'fr':
          image = iconFrench;
          break;
      }
      languageImage.value = image;
    };
    updateImage();
    let imgPay2 = ref(plan2)
    let validateLogin = ref('');
    let urlRedirect = ref('')
    let status = ref('')
    let showModal = ref()
    let spainIcon = ref(iconSpain);
    let profileIcon = ref(iconProfile);
    let usaIcon = ref(iconUSA);
    let brIcon = ref(iconBR);
    let frenchIcon = ref(iconFrench);
    let bgImageFoo = ref(bgImageFooter);
    let logonavbar = ref(logoNavbar);
    let style = ref("null");
    let account_name = ref({})
    let validRequestDisable = ref(true)
    let urlDiscord = ref(process.env.VUE_APP_URL_DISCORD);
    let urlDownloadGDrive = ref(process.env.VUE_APP_URL_DOWNLOAD_GDRIVE);
    let urlDownloadMediafire = ref(process.env.VUE_APP_URL_DOWNLOAD_MEDIAFIRE);
    let urlDownloadGDriveGVG = ref(process.env.VUE_APP_URL_DOWNLOAD_GDRIVE_GVG);
    let urlDownloadMediafireGVG = ref(process.env.VUE_APP_URL_DOWNLOAD_MEDIAFIRE_GVG);
    let pvalues = ref({})
    const logOut = async () => {
      await props.disconectSocket()
      await localStorage.removeItem('account_name')
      await localStorage.removeItem('userCreate')
      await localStorage.removeItem('access_token')
      await localStorage.removeItem('pvalues')
      localStorage.setItem('validateLogin', 'false')
      validRequestDisable.value = false
      showModal.value = true
      urlRedirect.value = `login`
      status.value = 'success'
    }
    const getAp = async () => {
      const data = {
        path: '/users/getPValues',
        data: { account_name: account_name.value },
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      }
      const resp = await service.methods.callService(data)
      if (resp) {
        await localStorage.setItem('pvalues', await resp.pvalues)
        pvalues.value = await localStorage.getItem('pvalues')
      }
    }

    if (!localStorage.getItem('validateLogin')) {
      localStorage.setItem('validateLogin', 'false')
    }
    validateLogin.value = localStorage.getItem('validateLogin')
    if (validateLogin.value === 'true') {
      account_name.value = localStorage.getItem('account_name');
      getAp()


    }


    //style = ` background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`;
    style = ` background : black; border: 1px solid #212121`;
    return {
      urlDiscord,
      urlDownloadGDrive,
      urlDownloadMediafire,
      urlDownloadMediafireGVG,
      urlDownloadGDriveGVG,
      bgImageFoo,
      logonavbar,
      style,
      spainIcon,
      brIcon,
      frenchIcon,
      usaIcon,
      profileIcon,
      pvalues,
      account_name,
      logOut,
      showModal,
      urlRedirect,
      status,
      validateLogin,
      language,
      languageImage,
      imgPay2,
      updateImage
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './styles.css';

nav {
  background-color: #212121;
}
</style>
