<template>
  <div class="justify-content-center flex flex-wrap" >
    <CardItem :itemData="itemData" class="md:w-4" :image="scaleImagen" title="3 talents of choice"
      description='Choose 3 talents lvl 1-5 by discord'>
    </CardItem>
    <div class="flex justify-content-center w-full">
      <ButtonCustomGF v-if="!scaleVal || (scaleVal && !claimed)" id="buttonClaim2"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3"
        :class="scaleVal || (scaleVal && !claimed) ? '' : 'disabled '" @click="claimScale()" :msg="$t('claimAward')">
      </ButtonCustomGF>
      <ButtonCustomGF v-if="scaleVal && claimed" id="buttonClaim2"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3 disabled" :msg="$t('claimed')"> </ButtonCustomGF>
    </div>
  </div>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
  <Spinner class="mt-8" v-if="validRequestDisableSacale2"></Spinner>
</template>

<script>
import { ref } from "vue";
import modalMessage from '@/components/modal/modalMessage'
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import CardItem from '@/components/cards/card-item/cardItem'
import imageScale2 from '@/assets/images/items/firstScale/scale2Icon.png'
import { useLoading } from 'vue-loading-overlay'
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import service from '@/mixins/service.js'
export default {
  components: {
    CardItem,
    ButtonCustomGF,
    
    modalMessage
  },
  mixins: [service],
  name: 'scale3-main',
  props: {
    msg: String,
    scaleVal: Boolean,
    claimed: Boolean
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('')
    let showModal = ref()
    let scaleImagen = ref(imageScale2)
    let bgImageFoo = ref(bgImageFooter)
    let itemData = ref();
    const getItemData = async () => {
      const data = {
        path: '/items/5229',
        method: 'GET',
      }
      itemData.value = await service.methods.callService(data);
    }
    getItemData();
    let selectedOptionLegacy = ref(0)
    const claimScale = async () => {
      const $loading = useLoading();
      const loader = $loading.show();
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: 'scale1Second' },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp && resp.statusCode == 200) {
          loader.hide();
          modalContent.value = 'successSendItem';
          showModal.value = true
          urlRedirect.value = `scales`;
          status.value = "success"
        } else {
          loader.hide();
          modalContent.value = "errorOccurred";
          showModal.value = true
          status.value = 'failed'
        }
      } catch (error) {
        loader.hide();
        modalContent.value = "errorOccurred";
        showModal.value = true
        status.value = 'failed'
      }
    }
    return {
      bgImageFoo,
      scaleImagen,
      claimScale,
      selectedOptionLegacy,
      modalContent,
      urlRedirect,
      status,
      showModal,
      itemData
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}
</style>