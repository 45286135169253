const state = {
  services: null,
}

const getters = {}

const actions = {}

const mutations = {
  SET_SERVICES(state, services) {
    state.services = services
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
