<template>
    <div class="header-banner-title background-banner">
      <div class="logo-container align-items-center flex justify-content-center" >
        <span class="text-title">{{ msg }}</span>
      </div>
    </div>
</template>

<script>
import { ref } from "vue";
import logoBanner from '@/assets/images/1x/logo-home-final.png'

export default {
  name: 'banner-title-main',
  props: {
    msg: String,
  },
  setup() {
    let date = ref(null);
    let logo = ref(logoBanner)
    let style = ref('null')
    style = ` background : linear-gradient(90deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    return {
      date,
      style,
      logo
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background-banner {
  background: url('@/assets/images/1x/img-mask-recorted.png');
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}
.header-banner-title {
  margin: 0;
  /*display: grid;
  grid-template-columns: 5% 95%;*/
  height: 150px;
  }
  .logo-container {
    width: 100%;
    height: 100%;
  }
  .text-title{
    font-family : Montserrat Regular;
    font-size : 35px;
    color : #FFFFFF;
    color : rgb(255, 255, 255);
  }
</style>
