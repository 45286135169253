<template>
  <!-- <img class="img-bg" :src="bgImagebod" />-->
  <div>

    <!-- Fondo gris deshabilitado -->
     <div class="container pt-5 pb-5 pl-8 pr-8">
        <div class="flex justify-content-center row" >
          <img src="@/assets/images/logoexed.png" class="col-md-12" style="width: 350px;height: 350px;" alt="">
          <div style="color: white;text-align: center;">
            <span >
              <h1>
               {{$t('textThankCancelPurchasedH1')}}
              </h1>
              <h2>
                {{$t('textThankCancelPurchasedH2')}}
              </h2>
              
            </span>
          </div>
          <div style="color: red;text-align: center;padding: 2%">
            <font-awesome-icon :icon="['fas', 'ban']" beat size="7x" />
            <!--<font-awesome-icon :icon="['fas', 'ban']" beat size="6x" /> -->
        </div>
        </div>
     </div>
    <!-- Spinner
    <div v-if="isLoading" style="margin:17%" class="flex justify-content-center align-items-center">
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow color-spriner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->

    <!-- Contenido de la página -->
  </div>
</template>
  
<script>
// @ is an alias to /src
export default {
  name: 'PaymentCancelView',
  components: {
  },
  
  setup() {
    const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()

    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }  
    topPage()
    return {
    }
  },
}
</script>
<style scoped>
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}

.cards-updates-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5%;
  gap: 10%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(235, 220, 220, 0.5);
  /* Fondo gris semitransparente */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.color-spriner {
  border: 6px solid rgba(177, 9, 9, 0.3);
  color: rgb(27, 2, 34);
  background-color: rgb(27, 2, 34)
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
  