import '@/assets/css/bootstrap.min.css';
import '@/assets/css/style.css';
import '@/assets/css/styles.css';
import br from '@/assets/i18n/br.json';
import en from '@/assets/i18n/en.json';
import es from '@/assets/i18n/es.json';
import fr from '@/assets/i18n/fr.json';
import '@/assets/js/bootstrap.bundle.min.js';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import 'primeflex/primeflex.css';
import { createApp } from "vue";
import { createI18n } from 'vue-i18n';
import App from "./App.vue";
import router from './router';
import store from './store';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret,faBan, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret,faBan,faCircleCheck)

import Vue3Toastify from 'vue3-toastify';

const lang = localStorage.getItem('language');
if (!lang) {
  localStorage.setItem('language', 'en');
}
const i18n = new createI18n({
  legacy: false,
  locale: lang || 'en',
  messages: {
    es,
    en,
    br,
    fr,
  }
})
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(LoadingPlugin);
app.use(store);
app.use(router);
app.use(i18n);
app.use(Vue3Toastify);
app.mount("#app");

