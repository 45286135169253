<template>
  <form v-if="!validRequestDisable" onsubmit="return false" class="container w-full md:w-3 justify-content-center">
    <div class="container row g-0">
      <div class="message-error-send-form mb-2">{{ messageErrorSendForm }}</div>
      <div class="form-floating mb-3 md:w-12">
        <input type="text" autocomplete="off" class="form-control" v-on:keyup="validateUsername"
          v-model="serviceForm.account_name"
          :class="(validUser == 'true') ? ' is-valid' : (validUser == 'false') ? ' is-invalid' : ' '"
          id="floatingInputNickName" placeholder="name@example.com">
        <label for="floatingInputNickName">{{ $t('userName') }}</label>
        <div :class="(validUser == 'true') ? ' valid-feedback' : (validUser == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.account.account_name.message }}
        </div>
      </div>
      <div class="form-floating  md:w-12 sm:w-12 lg:w-12">
        <input type="password" autocomplete="off" class="form-control" v-on:keyup="validatePassword"
          v-model="serviceForm.password"
          :class="(validPassword == 'true') ? ' is-valid' : (validPassword == 'false') ? ' is-invalid' : ' '"
          id="floatingPassword" :placeholder="$t('password')">
        <label for="floatingPassword">{{ $t('password') }}</label>
        <div
          :class="(validPassword == 'true') ? ' valid-feedback' : (validPassword == 'false') ? ' invalid-feedback' : ' '">
          {{ validationsMessagesForm.account.password.message }}
        </div>
      </div>
    </div>
    <div class="flex w-full justify-content-center">
      <ButtonCustomGF id="buttonRegister" class="ms-3 mt-4"
        :class="validPassword == 'true' && validUser == 'true' ? ' ' : ' disabled'" @click="sendContactForm" :msg="$t('loginB')">
      </ButtonCustomGF>
    </div>
  </form>
  <Spinner class="mt-8" :isLoading="validRequestDisable"></Spinner>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
</template>

<script>
// @ is an alias to /src

import { ref } from "vue";
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import Spinner from '@/components/spinner/Spinner'
import service from '@/mixins/service.js'
import modalMessage from '@/components/modal/modalMessage'
export default {
  name: 'login-main',
  mixins: [service],
  components: {
    ButtonCustomGF,
    Spinner,
    modalMessage
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('')
    let showModal = ref()
    let validRequestDisable = ref(false)
    let messageErrorSendForm = ref('')
    let validUser = ref('')
    let validEmail = ref('')
    let validPassword = ref('')
    let validPwd = ref('')
    let validationsMessagesForm = ref({
      account: {
        account_name: {
          required: 'user is required',
          exist: 'the user already exist',
          notUpperCaseLetter: 'Can`t use uppercase or white space or double quotation mark or single quotation mark',
          message: '',
        },
        password: {
          required: 'password',
          lengthMin: 'Is necesary 8 characters min',
          upperCase: 'Use necesary uppercase.',
          message: '',
        }
      }
    })
    let serviceForm = ref({
      account_name: '',
      password: ''
    })
    const createContactForm = async () => {
      try {
        validRequestDisable.value = true
        const data = {
          path: '/auth/login',
          data: serviceForm.value,
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp.token) {
          validRequestDisable.value = false
          localStorage.setItem('account_name', resp.account_name);
          localStorage.setItem('pvalues', resp.pvalues);
          localStorage.setItem('validateLogin', 'true');
          localStorage.setItem('access_token', resp.token);
          localStorage.setItem('email', resp.email);
          modalContent.value = resp.message
          showModal.value = true
          urlRedirect.value = 'home'
          status.value = "success"
        } else {
          validRequestDisable.value = false
          modalContent.value = resp.message
          showModal.value = true
          status.value = "failed"
        }
      } catch (error) {
        validRequestDisable.value = false
        modalContent.value = 'Has ocurred error on web services, try again later!'
        showModal.value = true
        status.value = "failed"
      }

    }
    const validateUsername = () => {
      if (serviceForm.value.account_name.length) {
        validUser.value = 'true'
        validationsMessagesForm.value.account.account_name.message = ''
        let regexUser = new RegExp(`^[a-z0-9\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+$`)//eslint-disable-line
        if (regexUser.test(serviceForm.value.account_name)) {
          validUser.value = 'true'
          validationsMessagesForm.value.account.account_name.message = ''
        } else {
          validUser.value = 'false'
          validationsMessagesForm.value.account.account_name.message = validationsMessagesForm.value.account.account_name.notUpperCaseLetter
        }
      } else {
        validUser.value = 'false'
        validationsMessagesForm.value.account.account_name.message = validationsMessagesForm.value.account.account_name.required
      }
    }
    const validatePassword = () => {
      if (serviceForm.value.password.length) {
        if (serviceForm.value.password.length >= 8) {
          let regexPass = new RegExp("(?=.*?[A-Z])")
          if (regexPass.test(serviceForm.value.password)) {
            validPassword.value = 'true'
            validationsMessagesForm.value.account.password.message = ''
          } else {
            validPassword.value = 'false'
            validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.upperCase
          }

        } else {
          validPassword.value = 'false'
          validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.lengthMin
        }
      } else {
        validPassword.value = 'false'
        validationsMessagesForm.value.account.password.message = validationsMessagesForm.value.account.password.required
      }
    }

    const validateAllForm = () => {
      if (validPassword.value == 'true' && validUser.value == 'true') return true; else return false
    }
    const sendContactForm = () => {
      if (validateAllForm()) {
        createContactForm()
      } else {
        messageErrorSendForm.value = "This forma is no valid for send!! review the all fields."
      }

    }
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      serviceForm,
      sendContactForm,
      validationsMessagesForm,
      validateUsername,
      validUser,
      validEmail,
      validPassword,
      validPwd,
      validatePassword,
      messageErrorSendForm,
      validRequestDisable,
      showModal,
      modalContent,
      urlRedirect,
      status
    }
  },
}
</script>
<style scoped>
.img-bg {
  padding-left: 15%;
  width: 85%;
  height: 80%;
}

.cards-updates-double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 5%;
  gap: 10%;
}

.message-error-send-form {
  font-family: Montserrat Regular;
  font-size: 14px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}

#buttonRegister.disabled {
  opacity: 0.4;
}
</style>
