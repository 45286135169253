<template>
    <form v-if="!validRequestDisable" onsubmit="return false" id="tickets-form" class="container mt-3"
        enctype="multipart/form-data" accept-charset="utf-8">
        <div class="container">
            <div class="row g-0">
                <div class="form-floating mb-3">
                    <input type="text" v-model="servicesForm.user" :disabled="accountNameEditable" class="form-control" id="floatingInput"
                        placeholder="Usuario">
                    <label for="floatingInput">{{$t('userName')}}</label>
                </div>
                <div class="form-floating mb-3">
                    <input type="email" v-model="servicesForm.email" :disabled="emailEditable" class="form-control" id="floatingInput"
                        placeholder="Usuario">
                    <label for="floatingInput">{{$t('emailAddress')}}</label>
                </div>
                <div class="form-floating mb-3">
                    <select v-model="servicesForm.category" id="floatingSelect"
                        class="form-control form-select form-select-lg">
                        <option value="0">Choose one category</option>
                        <option v-for="item in categories" v-bind:key="item" :value="item.id"> {{ item.description }}
                        </option>
                    </select>
                </div>
                <div class="form-floating mb-3">
                    <textarea class="form-control" v-model="servicesForm.coment" placeholder="Leave a comment here"
                        id="floatingTextarea2" style="height: 100px"></textarea>
                    <label for="floatingTextarea2">Comments</label>
                </div>

                <div class="w-full text-center flex justify-content-center">
                    <div class="btn-container w-full md:w-6">
                        <!--the three icons: default, ok file (img), error file (not an img)-->
                        <h1 class="imgupload"><i class="fa fa-file-image-o"></i></h1>
                        <h1 class="imgupload ok"><i class="fa fa-check"></i></h1>
                        <h1 class="imgupload stop"><i class="fa fa-times"></i></h1>
                        <!--this field changes dinamically displaying the filename we are trying to upload-->
                        <p id="namefile">Only pics allowed! (jpg,jpeg,bmp,png)</p>
                        <!--our custom btn which which stays under the actual one-->
                        <label for="fileup" id="fakebtn" class="subir btn btn-primary">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            Upload image
                        </label>
                        <input type="file" accept="image/jpeg" id="fileup" name="fileup" @change="uploadImage"
                            style="display: none" />
                    </div>
                </div>
                <!--additional fields-->
            </div>
        </div>
    </form>
</template>
<script>
import { ref } from "vue";
import service from '@/mixins/service.js';
export default {
    name: 'tickets-main',
    mixins: [service],
    setup() {
        let categories = ref([])
        let validRequestDisable = ref(false);
        let email = ref(localStorage.getItem('email'));
        let emailEditable =  ref(true);
        let accountName = ref(localStorage.getItem('account_name'));
        let accountNameEditable =  ref(true);
        if (!email.value) {
            email.value = '';
            emailEditable.value = false;
        }
        if (!accountName.value) {
            accountName.value = '';
            accountNameEditable.value = false;
        }
        let servicesForm = ref({
            user: accountName,
            email,
            category: 0,
            coment: ''
        })
        const topPage = () => {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
        }
        const getCategories = async () => {
            const data = {
                path: '/categories',
                method: 'GET',
            }
            categories.value = await service.methods.callService(data);
        }
        const uploadImage = () => {

            // Aquí tomamos la extensión del archivo y definimos un array de extensiones válidas
            let res = document.getElementById('fileup').value;
            let arr = res.split("\\");
            let filename = arr.slice(-1)[0];
            let filextension = filename.split(".");
            let filext = "." + filextension.slice(-1)[0];
            let valid = [".jpg", ".png", ".jpeg", ".bmp"];

            // Si el archivo no es válido, mostramos el icono de error, la alerta en rojo y ocultamos el botón de envío
            if (valid.indexOf(filext.toLowerCase()) === -1) {
                document.querySelector(".imgupload").style.display = "none";
                document.querySelector(".imgupload.ok").style.display = "none";
                document.querySelector(".imgupload.stop").style.display = "block";

                let namefile = document.getElementById('namefile');
                namefile.style.color = "red";
                namefile.style.fontWeight = 700;
                namefile.innerHTML = "Archivo " + filename + " no es una imagen!";

                document.getElementById("submitbtn").style.display = "none";
                document.getElementById("fakebtn").style.display = "block";
            } else {
                // Si el archivo es válido, mostramos la alerta en verde y el botón de envío válido
                document.querySelector(".imgupload").style.display = "none";
                document.querySelector(".imgupload.stop").style.display = "none";
                document.querySelector(".imgupload.ok").style.display = "block";

                let namefile = document.getElementById('namefile');
                namefile.style.color = "green";
                namefile.style.fontWeight = 700;
                namefile.innerHTML = filename;

                document.getElementById("fakebtn").style.display = "none";
            }

        }
        getCategories()
        topPage()
        return {
            validRequestDisable,
            emailEditable,
            servicesForm,
            categories,
            uploadImage,
            accountNameEditable
        }
    },
}
</script>
<style scoped>
#top {
    margin-top: 20px;
}

.btn-container {
    background: #fff;
    border-radius: 5px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.white {
    color: white;
}

.imgupload {
    color: #1E2832;
    padding-top: 40px;
    font-size: 7em;
}

#namefile {
    color: black;
}

h4>strong {
    color: #ff3f3f
}

.btn-primary {
    border-color: #ff3f3f !important;
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ff3f3f !important;
    border-color: #ff3f3f !important;
}

/*these two are set to not display at start*/
.imgupload.ok {
    display: none;
    color: green;
}

.imgupload.stop {
    display: none;
    color: red;
}


/*this sets the actual file input to overlay our button*/
#fileup {
    opacity: 0;
    -moz-opacity: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    width: 200px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    height: 50px;
}
</style>