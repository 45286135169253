<template>

  <div class="col py-3">
    <BannerTitle msg="Item Data"></BannerTitle>
    <ItemData :item_id="$route.params.id_item"></ItemData>
  </div>

</template>

<script>
// @ is an alias to /src
import BannerTitle from '@/components/banner-title/BannerTitle'
import ItemData from '@/components/database/itemdata/ItemData.vue'
export default {
  name: 'ItemSingle',
  components: {
    BannerTitle,
    ItemData
  },
  setup() {
    const isMobile = () => {
      return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
      );
    }

    const redirectWebBuildMovil = () => {

      // let navegador = navigator.userAgent;
      // console.log(navegador);
      let validator = isMobile()
      //console.log(validator)
      if (validator !== null) {

        window.location.href = "/#/processing"
      }
    }
    redirectWebBuildMovil()
    return {
    }
  },
}
</script>
