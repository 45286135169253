<template>
    <BannerTitle msg="Send Verify"></BannerTitle>
    <!-- <img class="img-bg" :src="bgImagebod" />-->
    <div class="container" style="width: 469px;height: 150px;">
      <VerifyCode ></VerifyCode>
    </div>
  </template>
  <script>
  // @ is an alias to /src
  
  import VerifyCode from '@/components/modal/codeVerify'
  import BannerTitle from '@/components/banner-title/BannerTitle'
  export default {
    name: 'CodeVerifyView',
    components: {
      BannerTitle,
      VerifyCode,
    },
    setup() {
      const isMobile = () =>{
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}
    const redirectWebBuildMovil = () => {
       
         
        let validator = isMobile()
        console.log(validator)
        if(validator!== null ){
           
          window.location.href = "/#/processing"
        } 
    }
   redirectWebBuildMovil()
      const topPage = () => {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0
      }
      topPage()
      return {
      }
    },
  }
  </script>
  <style scoped>
  </style>
  