<template>
  <ButtonCustomGF id="buttonPay" msg="Send Code" data-bs-toggle="modal" data-bs-target="#codeVerifyModal" class="flex header-title-text justify-content-center" style="margin-left: 20%;
    height:50%;width: 100%;"></ButtonCustomGF>
    <div class="modal fade" id="codeVerifyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div  v-if="!validRequestVerify"  class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Verify Codigo</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <span>Is necessary verify code for can play on Desktop Game</span>
          <div class="mb-3">
            <label for="codeVerify" class="col-form-label">Code:</label>
            <input type="text" v-model="serviceForm.code" class="form-control" id="codeVerify">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" @click="verifyCode()" class="btn btn-primary">Send Code</button>
      </div>
    </div>
  </div>
</div>
<Spinner class="mt-8" v-if="validRequestVerify" />
</template>

<script>
import { ref } from "vue";
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png'
import imageScale1 from '@/assets/images/1x/confuso.png'
import ButtonCustomGF from '@/components/button-custom/ButtonCustom'
import service from '@/mixins/service.js'
import Spinner from '@/components/spinner/Spinner'
export default {
    components:{
        ButtonCustomGF,
        Spinner
    },
  name: 'code-main',
  props: {
    msg: String,
    image: String,
    scaleVal:String,
  },
  setup() {
    let scaleImagen = ref(imageScale1)
    let bgImageFoo = ref(bgImageFooter)
    let validRequestVerify= ref(false)
    let user = ref({})
    user.value = localStorage.getItem('userCreate')
    let style = ref('null')
    let serviceForm = ref({
      code:'',
    })
    style = `   background : linear-gradient(135deg, rgba(35, 17, 63, 1) 0%, rgba(81, 0, 106, 1) 42.86%, rgba(96, 0, 106, 1) 63.8%, rgba(237, 28, 36, 1) 100%);`
    let verifyCode = async () => {
      validRequestVerify.value = true
      const data = {
    path: '/auth/verify-code',
    data: serviceForm.value,
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8' }
  }
  const resp = await service.methods.callService(data)
  if (resp) {
    validRequestVerify.value = false
    alert('Cuenta verificada con exito') 
    localStorage.removeItem('userCreate')
    window.location.href = 'https://www.gfmemories.com/#/'
  }
  else {
    validRequestVerify.value = false
    alert('Send Code failed')
  }
    }
    
    return {
      bgImageFoo,
      style,
      scaleImagen,
      verifyCode,
      serviceForm,
      validRequestVerify
    };
  },
};
</script>

<style>
.button-custom-gf{
  font-family : Montserrat Regular;
  font-size : 30px;
  color : #FFFFFF;
  color : rgb(255, 255, 255);
}

</style>