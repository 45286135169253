<template>
  <div v-if="!loading || showModal">
    <div class="head p-4">
      <span>{{ $t('changePassword') }}</span>
    </div>
    <form class="flex justify-content-center flex-wrap">
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('oldPassword') }}</span>
        <div class="w-full md:w-6">
          <input id="currentPassword" @input="validateForm" type="password" autocomplete="off" class="form-control"
            :class="submitted ? !form.currentPassword.error ? 'is-valid' : 'is-invalid' : ''"
            v-model="form.currentPassword.value" :placeholder="$t('insertTextField')">
          <div v-if="submitted && form.currentPassword.error" class="invalid-field">
            {{ form.currentPassword.error }}
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('newPassword') }}</span>
        <div class="w-full md:w-6">
          <input id="password" @input="validateForm" type="password" autocomplete="off" class="form-control"
            :class="submitted ? !form.newPassword.error ? 'is-valid' : 'is-invalid' : ''" v-model="form.newPassword.value"
            :placeholder="$t('insertTextField')">
          <div v-if="submitted && form.newPassword.error" class="invalid-field">
            {{ form.newPassword.error }}
          </div>
        </div>
      </div>
      <div class="form-floating mb-3 pr-0 md:pr-2 w-12 md:w-8 containerSettings flex justify-content-around flex-wrap">
        <span class="w-full md:w-4">{{ $t('confirmNewPassword') }}</span>
        <div class="w-full md:w-6">
          <input :disabled="form.newPassword.error" id="confirmPassword" @input="validateForm" type="password"
            autocomplete="off" class="form-control"
            :class="submitted ? !form.confirmPassword.error ? 'is-valid' : 'is-invalid' : ''"
            v-model="form.confirmPassword.value" :placeholder="$t('insertTextField')">
          <div v-if="submitted && form.confirmPassword.error" class="invalid-field">
            {{ form.confirmPassword.error }}
          </div>
        </div>
      </div>
      <div class="w-full flex justify-content-center">
        <ButtonCustomGF :class="submitted && !validForm ? 'disabled' : ''" type="button" @click="changePassword" id="buttonRegister"
          class="ms-3 mt-4" :msg="$t('save')">
        </ButtonCustomGF>
      </div>
    </form>
  </div>
  <Spinner class="mt-8" :isLoading="loading"></Spinner>
  <modalMessage class="" v-if="showModal" :title="messageModal.title" :status="messageModal.status" redirect="home"
        :content="messageModal.content" @close="showModal = false"></modalMessage>
</template>
  
<script>
import { ref } from "vue";
import service from '@/mixins/service.js';
import ButtonCustomGF from '@/components/button-custom/ButtonCustom';
import Spinner from '@/components/spinner/Spinner';
import modalMessage from '@/components/modal/modalMessage'

export default {
  name: 'ChangePassword',
  components: {
    ButtonCustomGF,
    Spinner,
    modalMessage
  },
  setup() {
    let submitted = ref(false);
    let validForm = ref(false);
    let loading = ref(false);
    let showModal = ref(false);
    let messageModal = ref({
      content: '',
      title: '',
      status: ''
    });
    let initialForm = {
      currentPassword: {
        value: '',
        error: null
      },
      newPassword: {
        value: '',
        error: null
      },
      confirmPassword: {
        value: '',
        error: null
      }
    };
    let form = ref(initialForm);
    const { currentPassword, newPassword, confirmPassword } = form.value;
    let validateForm = () => {
      if (!submitted.value) return;
      // Current password
      if (currentPassword.value == '') {
        currentPassword.error = 'Current password required.';
      } else {
        currentPassword.error = null;
      }
      // New password
      if (newPassword.value !== '') {
        if (newPassword.value.length >= 8) {
          let regexPass = new RegExp("(?=.*?[A-Z])");
          if (regexPass.test(newPassword.value)) {
            newPassword.error = null;
            if (newPassword.value === currentPassword.value) {
              newPassword.error = 'Password should be differents.';
            }
          } else {
            newPassword.error = 'Use necesary uppercase.';
          }
        } else {
          newPassword.error = 'Is necesary 8 characters min.';
        }
      } else {
        newPassword.error = 'Password required.';
      }
      // Confirm password
      if (confirmPassword.value !== newPassword.value) {
        confirmPassword.error = 'Password not match';
      } else {
        confirmPassword.error = newPassword.value == '' ? 'Confirm password required.' : null;
        if (!confirmPassword.error && newPassword.error) {
          confirmPassword.error = 'Solve password issues.'
        }
      }
      if (!currentPassword.error && !newPassword.error && !confirmPassword.error) {
        validForm.value = true;
      } else {
        validForm.value = false;
      }
    }

    const changePassword = async () => {
      loading.value = true;
      submitted.value = true;
      validateForm();
      if (validForm.value) {
        const data = {
          path: '/auth/changePassword',
          data: {
            currentPassword: currentPassword.value,
            newPassword: newPassword.value,
          },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data);
        if (resp.statusCode === 500) {
          messageModal.value = {
            title: 'Failed',
            status: 'failed',
            content: resp.message,
          };
          showModal.value = true;
        } else {
          messageModal.value = {
            title: 'Success',
            status: 'success',
            content: 'Password changed succesfully.',
          };
          showModal.value = true;
        }
      }
      loading.value = false;
    }
    const topPage = () => {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
    topPage()
    return {
      form,
      changePassword,
      validateForm,
      validForm,
      loading,
      submitted,
      showModal,
      messageModal
    }
  },
}

</script>
<style scoped>
.head {
  font-size: 25px;
  color: white;
}

.containerSettings {
  font-size: 20px;
  color: white;
}
</style>