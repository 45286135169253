import { createStore } from 'vuex'
import services from './modules/service.js'

export default createStore({
  state: {
    dbTitle: 'Database',
  },
  getters: {},
  mutations: {
    setDbTitle(state, title) {
      state.dbTitle = title;
    },
  },
  actions: {
    updateDbTitle({ commit }, title) {
      commit('setDbTitle', title);
    },
  },
  modules: { services },
})
