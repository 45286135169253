<template>
    <div class="container-fluid">
      <div class="row flex-nowrap">
      </div>
  </div>
  </template>
  
  <script>
  // @ is an alias to /src
  export default {
    name: 'ItemSingle',
    components: {
    },
    setup() {
      const isMobile = () => {
        return (
          (navigator.userAgent.match(/Android/i)) ||
          (navigator.userAgent.match(/webOS/i)) ||
          (navigator.userAgent.match(/iPhone/i)) ||
          (navigator.userAgent.match(/iPod/i)) ||
          (navigator.userAgent.match(/iPad/i)) ||
          (navigator.userAgent.match(/BlackBerry/i))
        );
      }
      const redirectWebBuildMovil = () => {
         
         
        let validator = isMobile()
        console.log(validator)
        if (validator !== null) {
           
          window.location.href = "/#/processing"
        }
        
      }
      redirectWebBuildMovil()
      return {
      }
    },
  }
  </script>
  